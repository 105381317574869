.settingsContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  color: white;
}

.settingsTitle {
  font-size: 2rem;
  font-weight: 600;
  color: white;
  margin-bottom: 2rem;
  border-bottom: 2px solid var(--ricepaper);
  padding-bottom: 0.5rem;
}

.settingSection {
  background-color: var(--brown);
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.settingSection:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.sectionHeader {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid var(--ricepaper);
}

.sectionIcon {
  font-size: 1.5rem;
  margin-right: 0.75rem;
  color: var(--chair-orange);
}

.sectionHeader h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  margin: 0;
}

.sectionContent {
  padding: 1.5rem;
}

.currentUsername {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.label {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 0.5rem;
}

.value {
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
}

.cooldownMessage {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.75rem 1rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  border-left: 3px solid var(--chair-orange);
}

.actionButton {
  margin-top: 0.5rem;
}

.settingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.settingLabel {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.settingLabel span {
  font-size: 1rem;
  color: white;
  font-weight: 500;
}

.settingHint {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

/* Danger section styling */
.dangerSection {
  border: 1px solid rgba(239, 68, 68, 0.3);
}

.dangerSection .sectionIcon {
  color: #ef4444;
}

.dangerText {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
  line-height: 1.5;
}

.deleteButton {
  background-color: rgba(239, 68, 68, 0.1);
}

/* Modal styling */
.modalContent {
  padding: 0.5rem 0;
}

.modalText {
  font-size: 0.95rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.25rem;
}

.modalInput {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.modalInput:focus {
  border-color: var(--chair-orange);
  outline: none;
  box-shadow: 0 0 0 2px rgba(237, 125, 49, 0.2);
}

.modalInput:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.modalInput::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

/* Delete account modal */
.deleteModalContent {
  padding: 0.5rem 0;
}

.deleteInputField {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(239, 68, 68, 0.3);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  transition: all 0.2s ease;
}

.deleteInputField:focus {
  border-color: #ef4444;
  outline: none;
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.deleteWarning {
  background-color: rgba(239, 68, 68, 0.1);
  padding: 1rem;
  border-radius: 6px;
  border-left: 3px solid #ef4444;
}

.deleteWarning p {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.deleteWarning ul {
  margin: 0;
  padding-left: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.deleteWarning li {
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .settingsTitle {
    font-size: 1.75rem;
  }

  .settingRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .settingRow .settingLabel {
    margin-bottom: 0.5rem;
  }

  .sectionHeader {
    padding: 1rem;
  }

  .sectionContent {
    padding: 1.25rem;
  }
}
