/* Base theme (light) */
.experienceCard {
  --card-bg: white;
  --card-hover-bg: #f8f9fa;
  --card-border: rgba(0, 0, 0, 0.1);
  --card-shadow: rgba(0, 0, 0, 0.08);
  --card-hover-shadow: rgba(0, 0, 0, 0.12);

  --text-primary: #2c3e50;
  --text-secondary: #4a5568;
  --text-tertiary: #718096;

  --intent-bg: #f0f2f5;
  --intent-text: #4a5568;
  --intent-icon: var(--chair-orange);

  --divider: rgba(0, 0, 0, 0.1);
  --tag-text: white;
}

/* Dark theme */
.experienceCard[data-theme="dark"] {
  --card-bg: #1a1a1a;
  --card-hover-bg: #2a2a2a;
  --card-border: rgba(255, 255, 255, 0.1);
  --card-shadow: rgba(0, 0, 0, 0.2);
  --card-hover-shadow: rgba(0, 0, 0, 0.3);

  --text-primary: #e1e1e1;
  --text-secondary: #b0b0b0;
  --text-tertiary: #888888;

  --intent-bg: #2d2d2d;
  --intent-text: #d1d1d1;
  --intent-icon: var(--chair-orange);

  --divider: rgba(255, 255, 255, 0.1);
  --tag-text: white;
}

/* Transparent theme - inherits text colors from dark theme */
.experienceCard[data-theme="transparent"] {
  --card-bg: transparent;
  --card-hover-bg: rgba(255, 255, 255, 0.05);
  --card-border: transparent;
  --card-shadow: none;
  --card-hover-shadow: none;

  /* Use dark theme text colors */
  --text-primary: #e1e1e1;
  --text-secondary: #b0b0b0;
  --text-tertiary: #888888;

  --intent-bg: rgba(45, 45, 45, 0.3); /* #2d2d2d with opacity */
  --intent-text: #d1d1d1;
  --intent-icon: var(--chair-orange);

  --divider: rgba(255, 255, 255, 0.1);
  --tag-text: white;
}

/* Component styles using theme variables */
.experienceCard {
  position: relative;

  color: var(--text-primary);

  min-width: 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--card-border);
  }
}

.inner {
  position: relative;
  padding: 20px;
  background-color: var(--card-bg);
  transition: all 0.2s ease-in-out;
  border-radius: 12px;
  box-shadow: 0 2px 8px var(--card-shadow);
  width: 100%;
  overflow: hidden;

  &:hover {
    background-color: var(--card-hover-bg);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px var(--card-hover-shadow);
  }
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.story {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-primary);
  flex: 1;
  text-align: start;
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-width: 0;
}

/* Updated intents section to be horizontally scrollable */
.intents {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  border-bottom: 1px dotted var(--card-border);
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  gap: 0.75rem;
  width: 100%;
  max-width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.intents::-webkit-scrollbar {
  display: none;
}

.intent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: var(--intent-bg);
  font-size: 0.95em;
  color: var(--intent-text);
  cursor: default;
  white-space: nowrap;
  flex-shrink: 0;
}

.intentIcon {
  color: var(--intent-icon);
}

/* Updated tags section to be horizontally scrollable */
.tags {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  max-width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tags::-webkit-scrollbar {
  display: none;
}

.tag {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.85rem;
  font-size: 0.95em;
  background: var(--intent-bg);
  border: 1px solid var(--card-border);
  color: var(--text-primary);
  cursor: default;
  white-space: nowrap;
  flex-shrink: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 0.5rem;
  border-top: 1px dotted var(--card-border);
  gap: 1rem;
}

.mobile .footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.metadata {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-tertiary);
  font-size: 0.9rem;
}

.author {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  color: var(--text-secondary);
}

.anonymousIcon {
  color: var(--chair-orange);
}

.date {
  color: var(--text-tertiary);
}

.statusIndicators {
  display: flex;
  gap: 0.5rem;
}

.statusIndicator {
  padding: 4px;
  border-radius: 50%;
  background-color: var(--intent-bg);
  color: var(--intent-icon);
  cursor: help;

  &:hover {
    background-color: var(--intent-bg);
  }
}

.actionMenu {
  flex-shrink: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .inner:hover & {
    opacity: 1;
  }
}

.reachedOutBadge,
.connectedBadge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 0.75rem;
  color: white;
}

.reachedOutBadge {
  background-color: var(--cool-blue); /* Use your defined cool blue */
}

.connectedBadge {
  background-color: var(--warm-yellow); /* Use your defined warm yellow */
}

.dialogContent {
  background-color: var(--card-bg);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 4px 8px var(--card-shadow);
  position: relative;

  .dialogHeading {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--chair-orange);
  }

  .dialogDescription {
    font-size: 1em;
    color: var(--text-tertiary);
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }

  .dialogTextArea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid var(--divider);
    border-radius: 5px;
    margin-bottom: 1rem;
    resize: vertical;
  }

  .dialogButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
}

.registrationStatus {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.statusItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.statusActive {
  color: var(--chair-orange);
}

.statusInactive {
  color: var(--text-tertiary);
}

/* Add styles for semantic score */
.semanticScore {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  padding: 4px 10px;
  background-color: var(--intent-bg);
  border-radius: 12px;
  width: fit-content;
}

.scoreLabel {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.scoreValue {
  font-weight: 600;
  color: var(--chair-orange);
}

/* Add responsive styles for small widths */
.smallWidth .story {
  font-size: 0.95rem;
}

.smallWidth .intent {
  font-size: 0.85rem;
  padding: 3px 8px;
}

.smallWidth .tag {
  font-size: 0.8rem;
  padding: 4px 8px;
}

.smallWidth .author,
.smallWidth .metadata,
.smallWidth .date {
  font-size: 0.8rem;
}

.smallWidth .semanticScore {
  font-size: 0.85rem;
  padding: 3px 8px;
}

/* Ensure mobile footer is properly styled */
.mobile .footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

/* Ensure intents and tags don't overflow on small screens */
.intents,
.tags {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.intents::-webkit-scrollbar,
.tags::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.mobile .inner {
  padding: 12px;
}

.mobile .headerRow {
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

.mobile .story {
  font-size: 1rem;
  line-height: 1.4;
}

.mobile .intents {
  padding-bottom: 0.25rem;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.mobile .intent {
  padding: 3px 8px;
  font-size: 0.85rem;
}

.mobile .tags {
  padding-bottom: 0.25rem;
  gap: 0.35rem;
  margin-bottom: 0.25rem;
}

.mobile .tag {
  padding: 3px 8px;
  font-size: 0.85rem;
}

.mobile .footer {
  padding-top: 0.25rem;
  gap: 0.25rem;
}

.mobile .metadata {
  gap: 0.5rem;
  font-size: 0.8rem;
}

.mobile .author {
  font-size: 0.85rem;
}

.mobile .semanticScore {
  margin-bottom: 0.5rem;
  padding: 3px 8px;
  font-size: 0.8rem;
}

/* Very small screens (extra optimization) */
.smallWidth.mobile .inner {
  padding: 10px;
}

.smallWidth.mobile .headerRow {
  margin-bottom: 0.35rem;
}

.smallWidth.mobile .story {
  font-size: 0.9rem;
}

.smallWidth.mobile .intent,
.smallWidth.mobile .tag {
  padding: 2px 6px;
  font-size: 0.8rem;
}

.smallWidth.mobile .footer {
  padding-top: 0.2rem;
}
