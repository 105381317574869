/* packages/client/src/pages/Login/Login.css */
.overlay {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.loginContainer {
  background: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 24px;
  width: 100%;
  max-width: 480px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.header {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: translateY(-2px);
}

.headerText {
  font-size: 32px;
  font-weight: 800;
  background: linear-gradient(135deg, var(--chair-orange), #ff8c42);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
}

.subheader {
  color: #666;
  font-size: 16px;
  margin-top: 8px;
}

.googleBtn {
  background: white;
  color: #444;
  width: 100%;
  padding: 16px 32px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 48px;
}

.googleBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.googleBtn:active {
  transform: translateY(0);
}

.googleBtn img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.googleBtn .spinner {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.divider {
  margin: 32px 0;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  border: none;
}

.signupContainer {
  font-size: 16px;
  color: #666;
}

.signupLink {
  color: var(--chair-orange);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease;
}

.signupLink:hover {
  color: var(--chair-orange-dark);
  text-decoration: underline;
}

@media (max-width: 575px) {
  .loginContainer {
    padding: 32px 24px;
    border-radius: 16px;
  }

  .headerText {
    font-size: 28px;
  }

  .googleBtn {
    padding: 14px 24px;
    font-size: 16px;
  }
}

.logoutButton {
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Added spacing between children */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logoutButton:hover {
  background-color: #1491d1;
  transform: scale(1.05);
}

.logoutButton:disabled {
  background-color: #a1c9f2;
  cursor: not-allowed;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4285f4;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
