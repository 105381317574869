:root {
  /* Form-specific colors using global semantic colors */
  --form-bg: var(--bg-brown);
  --form-text: var(--tag-default);
  --form-text-secondary: rgba(
    249,
    245,
    215,
    0.7
  ); /* Using tag-default with opacity */
  --form-text-hover: var(--tag-selected);
  --form-border: var(--ricepaper-white);
  --form-shadow: rgba(0, 0, 0, 0.24);
  --form-checkbox-bg: var(--ricepaper);
  --form-checkbox-border: var(--ricepaper-white);
  --form-primary: var(--chair-orange);
  --form-primary-hover: var(--chair-orange-dark);
  --form-disabled: var(--graphite);
}

.form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: var(--form-bg);
  border-radius: 12px;
  box-shadow: 0 8px 32px var(--form-shadow);
}

.mainSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--form-text);
  margin: 0;
  text-align: center;
}

.tagSelector {
  width: 100%;
}

.expandButton {
  width: 100%;
  padding: 0.75rem;
  background: none;
  border: none;
  color: var(--form-text-secondary);
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: color 0.2s;
}

.expandButton:hover {
  color: var(--form-primary);
}

.arrow {
  transition: transform 0.2s;
}

.arrow.up {
  transform: rotate(180deg);
}

.preferencesSection {
  overflow: hidden;
  border-top: 1px solid var(--form-border);
  padding-top: 1rem;
}

.preferenceGroup {
  margin-bottom: 1.5rem;
}

.preferenceGroup h3 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--form-text);
  margin-bottom: 0.75rem;
}

.checkboxGroup {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.75rem;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--form-text);
  cursor: pointer;
  font-size: 0.9rem;
}

.checkbox input {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: var(--form-checkbox-bg);
  border: 1px solid var(--form-checkbox-border);
}

.submitButton {
  width: 100%;
  padding: 1rem;
  background: var(--form-primary);
  color: var(--form-text);
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

.submitButton:hover:not(:disabled) {
  background: var(--form-primary-hover);
}

.submitButton:disabled {
  background: var(--form-disabled);
  cursor: not-allowed;
}

/* Loading and Error States */
.loadingContainer,
.errorContainer {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loadingMessage {
  color: var(--form-text);
  font-size: 1.1rem;
}

.loadingMessage :global(.spinner) {
  color: var(--form-primary);
}

.errorContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: var(--form-text);
}

.errorIcon {
  font-size: 2.5rem;
}

.errorContent h3 {
  font-size: 1.25rem;
  margin: 0;
  color: var(--error);
}

.errorContent p {
  color: var(--form-text-secondary);
  margin: 0;
}

.retryButton {
  margin-top: 1rem;
  padding: 0.75rem 2rem;
  background: var(--form-primary);
  border: none;
  border-radius: 8px;
  color: var(--form-text);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.retryButton:hover {
  background: var(--form-primary-hover);
  transform: translateY(-1px);
}

.retryButton:active {
  transform: translateY(0);
}

/* Update LoadingMessage component colors when used in form */
:global(.loading-message) {
  color: var(--form-text);
}

:global(.loading-message .spinner) {
  color: var(--form-primary);
}

@media (max-width: 768px) {
  .form {
    padding: 1.5rem;
  }

  .checkboxGroup {
    grid-template-columns: repeat(2, 1fr);
  }

  .loadingContainer,
  .errorContainer {
    min-height: 250px;
  }

  .errorIcon {
    font-size: 2rem;
  }

  .errorContent h3 {
    font-size: 1.1rem;
  }

  .errorContent p {
    font-size: 0.9rem;
  }

  .retryButton {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
}

.headerWithTooltip {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;

  h3 {
    margin-bottom: 0;
  }
}

.tooltipTrigger {
  color: var(--form-text-secondary);
  cursor: help;
  font-size: 0.9rem;
  transition: color 0.2s;
}

.tooltipTrigger:hover {
  color: var(--form-text);
}

/* Remove the old tooltip styles */
.preferenceHeader,
.tooltip {
  display: none;
}

/* Move TagSelector styles from mainSection to preferencesSection */
.preferencesSection .tagSelector {
  margin-bottom: 1.5rem;
}
