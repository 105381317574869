.container {
  display: inline-flex;
  position: relative;
}

.icon {
  color: var(--chair-orange);
  cursor: help;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  transition: color 0.2s ease;
}

.icon:hover {
  color: var(--chair-orange-dark);
}

.tooltip {
  background-color: white;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--graphite);

  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  border: 1px solid #e5e7eb;
}

.arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  transform: rotate(45deg);
  border: inherit;
  z-index: -1;
}
