/* Base theme (light) */
.storyCard {
  --card-bg: white;
  --card-hover-bg: #f8f9fa;
  --card-border: rgba(0, 0, 0, 0.1);
  --card-shadow: rgba(0, 0, 0, 0.08);
  --card-hover-shadow: rgba(0, 0, 0, 0.12);

  --text-primary: #2c3e50;
  --text-secondary: #4a5568;
  --text-tertiary: #718096;

  --intent-bg: #f0f2f5;
  --intent-text: #4a5568;
  --intent-icon: var(--chair-orange);

  --divider: rgba(0, 0, 0, 0.1);
  --tag-text: white;
}

/* Dark theme */
.storyCard[data-theme="dark"] {
  --card-bg: #1a1a1a;
  --card-hover-bg: #2a2a2a;
  --card-border: rgba(255, 255, 255, 0.1);
  --card-shadow: rgba(0, 0, 0, 0.2);
  --card-hover-shadow: rgba(0, 0, 0, 0.3);

  --text-primary: #e1e1e1;
  --text-secondary: #b0b0b0;
  --text-tertiary: #888888;

  --intent-bg: #2d2d2d;
  --intent-text: #d1d1d1;
  --intent-icon: var(--chair-orange);

  --divider: rgba(255, 255, 255, 0.1);
  --tag-text: white;
}

/* Transparent theme - inherits text colors from dark theme */
.storyCard[data-theme="transparent"] {
  --card-bg: transparent;
  --card-hover-bg: rgba(255, 255, 255, 0.05);
  --card-border: transparent;
  --card-shadow: none;
  --card-hover-shadow: none;

  --text-primary: #e1e1e1;
  --text-secondary: #b0b0b0;
  --text-tertiary: #888888;

  --intent-bg: rgba(45, 45, 45, 0.3);
  --intent-text: #d1d1d1;
  --intent-icon: var(--chair-orange);

  --divider: rgba(255, 255, 255, 0.1);
  --tag-text: white;
}

/* Component styles */
.storyCard {
  position: relative;
  color: var(--text-primary);
  min-width: 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--card-border);
  }
}

.inner {
  position: relative;
  padding: 20px;
  background-color: var(--card-bg);
  transition: all 0.2s ease-in-out;
  border-radius: 12px;
  box-shadow: 0 2px 8px var(--card-shadow);
  width: 100%;
  overflow: hidden;

  &:hover {
    background-color: var(--card-hover-bg);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px var(--card-hover-shadow);
  }
}

/* Mobile optimizations */
.mobile .inner {
  padding: 12px;
}

.smallWidth .inner {
  padding: 10px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.content {
  flex: 1;
  min-width: 0;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.75rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  text-align: start !important;
  &:hover {
    color: var(--chair-orange);
  }
}

.body {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: default;

  p {
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  max-width: 100%;
}

.tags::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.tag {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.95em;
  background: var(--intent-bg);
  border: 1px solid var(--card-border);
  color: var(--text-primary);
  cursor: default;
  white-space: nowrap;
  flex-shrink: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  border-top: 1px dotted var(--divider);
}

.author {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  color: var(--text-secondary);
  cursor: pointer;
}

.anonymousIcon {
  color: var(--chair-orange);
}

.metadata {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-tertiary);
  font-size: 0.9rem;
}

.statusIndicators {
  display: flex;
  gap: 0.5rem;
}

.statusIndicator {
  padding: 4px;
  border-radius: 50%;
  background-color: var(--intent-bg);
  color: var(--intent-icon);
  cursor: help;

  &:hover {
    background-color: var(--card-hover-bg);
  }
}

.statusIcon {
  width: 1.2em;
  height: 1.2em;
}

.actionMenu {
  flex-shrink: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .inner:hover & {
    opacity: 1;
  }
}

.hugSection {
  display: flex;
  justify-content: flex-start;
  padding-top: 0.75rem;
  margin-top: 0.5rem;
  border-top: 1px dotted var(--divider);
}

.hugButton {
  background: transparent;
  border: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-tertiary);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1.1rem;

  &:hover {
    color: var(--chair-orange);
  }

  &.hugged {
    color: var(--chair-orange);
  }
}

.hugCount {
  font-size: 0.9rem;
  color: var(--text-tertiary);
}

/* Add semantic score styles */
.semanticScore {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  padding: 4px 10px;
  background-color: var(--intent-bg);
  border-radius: 12px;
  width: fit-content;
}

.scoreLabel {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.scoreValue {
  font-weight: 600;
  color: var(--chair-orange);
}

/* Improve mobile footer layout */
.mobile .footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

/* Responsive text sizes for small screens */
.smallWidth .title {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.smallWidth .body {
  font-size: 0.9rem;
  -webkit-line-clamp: 4;
  line-height: 1.4;
}

.smallWidth .tag {
  font-size: 0.8rem;
  padding: 4px 8px;
}

.smallWidth .author,
.smallWidth .metadata,
.smallWidth .date {
  font-size: 0.8rem;
}

.smallWidth .semanticScore {
  font-size: 0.85rem;
  padding: 3px 8px;
  margin-bottom: 0.5rem;
}

.smallWidth .hugButton {
  font-size: 1rem;
  padding: 0.35rem;
}

.smallWidth .hugCount {
  font-size: 0.8rem;
}

.mobile .headerRow {
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

.mobile .title {
  margin-bottom: 0.5rem;
}

.mobile .body p {
  margin-bottom: 0.5rem;
}

.mobile .tags {
  padding-bottom: 0.25rem;
  gap: 0.35rem;
}

.mobile .tag {
  padding: 3px 8px;
  font-size: 0.85rem;
}

.mobile .footer {
  padding-top: 0.5rem;
}

.mobile .hugSection {
  padding-top: 0.5rem;
  margin-top: 0.35rem;
}

.mobile .hugButton {
  padding: 0.35rem;
}
