.founderStory {
  padding: 6rem 2rem;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.95),
    var(--background-dark)
  );
}

.storyContainer {
  max-width: 800px;
  margin: 0 auto;
}

.storyContent {
  padding: 3rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.storyContent h2 {
  font-size: 2.2rem;
  color: var(--chair-orange);
  margin-bottom: 1.5rem;
  text-align: center;
}

.personalStory {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
  font-size: 1.15rem;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.founderSignature {
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
  font-size: 1rem;
  margin-top: 2rem;
}

.betaCallout {
  padding: 2rem;
  background: rgba(254, 208, 48, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(254, 208, 48, 0.2);
}

.betaCallout h3 {
  font-size: 1.8rem;
  color: var(--chair-orange);
  margin-bottom: 1.5rem;
}

.betaCallout p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-size: 1.05rem;
  margin-bottom: 1.5rem;
}

.feedbackEmail {
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  text-align: center;
}

.feedbackEmail p {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.emailLink {
  color: var(--chair-orange);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

.emailLink:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.roadmap {
  margin: 2rem 0;
}

.roadmap h4 {
  font-size: 1.2rem;
  color: var(--chair-orange);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.roadmap h4:after {
  content: "🏗️";
  margin-left: 0.5rem;
}

.roadmap ul {
  list-style-type: none;
  padding: 0;
}

.roadmap li {
  color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.roadmap li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: var(--chair-orange);
}

.joinButton {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background: var(--chair-orange);
  color: #000;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.joinButton:hover {
  background: #ffd700;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(254, 208, 48, 0.3);
}

@media (max-width: 768px) {
  .founderStory {
    padding: 4rem 1.5rem;
  }

  .storyContent {
    padding: 2rem;
  }

  .storyContent h2 {
    font-size: 1.8rem;
  }

  .personalStory {
    font-size: 1rem;
  }
}
