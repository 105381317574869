/* /packages/client/src/pages/Register/StepLifeExperiences/StepLifeExperiences.module.css */
.container {
  overflow: auto;
  padding-right: 8px;
  display: grid;
  gap: 1em;
  width: 100%;
}

.container .addLifeExp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.tagCountHeader {
  display: flex;
  width: 100%;
  justify-content: center;
}

.tooltipBody {
  color: #666;
}

.tooltipBody .header {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.tooltipBody strong {
  font-weight: bold;
}

.tooltipBody ul {
  list-style-position: inside;
}
.tooltipBody li {
  margin-bottom: 0.5em;
}

.editContainer {
}

.editContainer .actionMenu {
  width: 100%;
  display: flex;
  justify-content: center;
}
/* Tag styles */
.tagEditor {
  position: relative;
  width: 100%;
  display: flex;
  width: 100%;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  min-height: 50px;
}

.tagEditor:focus-within {
  outline: none;
  border-color: #4caf50; /* Highlight color when textarea is focused */
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.25); /* Optional: Add a subtle shadow on focus */
}

.tag {
  padding: 10px 15px;
  background-color: #f9f5d7; /* light yellow */
  border-radius: 20px;
  cursor: default;
  margin: 5px;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.tagInput {
  font-size: 16px;
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  padding-left: 0.5em;
  flex-grow: 1;
}

.tagEditor .clearButton {
  position: absolute;
  bottom: 16px; /* Adjust as needed */
  right: 12px; /* Adjust as needed */
  cursor: pointer;
  color: #ccc; /* Example color */
  font-size: 16px; /* Adjust the size as needed */
}

.tagDropdown {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 100%;
}

.tagDropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

.tagDropdown li:hover {
  background-color: #f0f0f0;
}

/* Story styles */
.story {
  display: flex;
  flex-direction: column;
}

.story .textAreaContainer {
  position: relative;
}

.story .textAreaContainer .essayTextArea {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-family: inherit; /* Inherit the font from the body */
  resize: none; /* Prevent resizing if you want to keep the size consistent */
  flex-grow: 1;
  color: black;
  overflow-y: auto; /* Allow vertical scrollbar */
  height: auto; /* Start with a small height */
  max-height: 150px; /* Maximum height */
  width: 100%;
  border: 1px solid #ccc;
}

.story .textAreaContainer .essayTextArea::placeholder {
  color: #999; /* Placeholder text color */
  font-style: italic; /* Optional: makes the placeholder text italic */
  font-size: 16px; /* Adjust font size as needed */
  opacity: 1; /* Adjust the transparency of the placeholder text */
}

.story .textAreaContainer .essayTextArea:focus {
  outline: none;
  border-color: #4caf50; /* Highlight color when textarea is focused */
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.25); /* Optional: Add a subtle shadow on focus */
}

.story .textAreaContainer .essayTextArea.textAreaError {
  border-color: orange; /* Border color when word limit is reached */
}

.story .textAreaContainer .clearButton {
  position: absolute;
  bottom: 16px; /* Adjust as needed */
  right: 12px; /* Adjust as needed */
  cursor: pointer;
  color: #ccc; /* Example color */
  font-size: 16px; /* Adjust the size as needed */
}

.story .infoBar {
  display: flex;
}

.story .wordCount {
  text-align: right;
  font-size: 0.875rem;
  line-height: 1.5rem;
  flex-grow: 1;
  display: flex;
  justify-content: right;
}

.story .wordCountError {
  color: orange; /* Text color when word limit is reached */
}

.errorMessage {
  color: orange; /* Error message color */
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Read-only mode styles */
.readOnlyContainer {
  display: grid;
  grid-template-columns: 1fr auto; /* One column for the tag, one for the button */
  grid-template-rows: auto 1fr; /* One row for the header, one for the story */
  gap: 10px;
  background-color: #f3f3f3; /* Light gray background */
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  align-items: start;
}

.readOnlyTag {
  justify-self: left;
}

.readOnlyStory {
  grid-column: 1 / -1; /* Span across all columns */
  grid-row: 2;
  white-space: pre-wrap; /* Preserve line breaks and spaces */
  color: #666;
  padding: 0 20px;
}

/* .readOnlyContainer .actionMenu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
} */

.editButton {
  grid-column: 2;
  grid-row: 1;
  justify-self: end; /* Align to the end of the column */
  /* Rest of the button styles */
}

.lifeExperienceList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: var(--chair-orange);
  color: white;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 0.85rem;
}

.date {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
}

.story {
  color: white;
  line-height: 1.5;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

/* Ensure the modal appears correctly in the registration flow */
:global(.FloatingOverlay) {
  z-index: 1000;
}

/* Add styles to ensure the JoyrideFab is properly positioned with the modal */
:global(.FloatingOverlay) :global(.fab) {
  position: fixed;
  z-index: 1001; /* Ensure it's above the modal */
  bottom: 20px;
  left: 20px;
}

@media (max-width: 768px) {
  .container {
    padding-right: 0;
  }
}

.experienceCounter {
  margin-bottom: 16px;
  width: 100%;
}

.counterText {
  display: block;
  text-align: center;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

.counterBar {
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.counterFill {
  height: 100%;
  background: var(--chair-orange);
  border-radius: 3px;
  transition: width 0.3s ease;
}

.limitMessage {
  text-align: center;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.limitMessage p {
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
}

.limitHelper {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6) !important;
  margin-top: 4px !important;
}

.emptyStateRequired {
  color: var(--chair-orange);
  font-size: 0.85rem;
  margin-top: 8px;
}
