.notificationItem {
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  transition: background-color 0.2s;
  color: rgba(255, 255, 255, 0.9);

  &.unread {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .icon {
    width: 24px;
    height: 24px;
    color: rgba(255, 255, 255, 0.9);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .message {
    font-size: 0.95rem;
    line-height: 1.4;
  }

  .metadata {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.7);

    .tag {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 2px 6px;
      border-radius: 4px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 4px;

    .timestamp {
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.5);
    }

    .resourceButton {
      color: var(--chair-orange);
      font-size: 0.9rem;
      font-weight: 500;
      padding: 4px 8px;
      border-radius: 4px;
      transition: background-color 0.2s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
