/* packages/client/src/components/LifeExperience/StoryEditor.module.css */
.story {
  display: flex;
  flex-direction: column;
}

.story .textAreaContainer {
  position: relative;
}

.story .textAreaContainer .essayTextArea {
  padding: 14px 40px 14px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-family: inherit;
  resize: vertical;
  flex-grow: 1;
  overflow-y: auto;
  height: auto;
  max-height: 150px;
  width: 100%;
  /* Match SimpleCombobox light theme styling */
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  color: #333;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.story .textAreaContainer .essayTextArea::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
  font-size: 16px;
  opacity: 1;
}

.story .textAreaContainer .essayTextArea:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(255, 107, 53, 0.1);
}

.story .textAreaContainer .essayTextArea:hover:not(:focus) {
  border-color: rgba(0, 0, 0, 0.3);
}

.story .textAreaContainer .essayTextArea.textAreaError {
  border-color: #ff3b30; /* Error color */
}

.story .textAreaContainer .clear-btn {
  position: absolute;
  bottom: 16px;
  right: 12px;
  cursor: pointer;
  background: none;
  color: rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  padding: 0;
  font-size: 1.25em;
  line-height: 0.5rem;
  transition: color 0.2s ease;
}

.story .textAreaContainer .clear-btn:hover {
  color: rgba(0, 0, 0, 0.7);
}

.story .infoBar {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  display: flex;
}

.story .infoBar p {
  font-size: 0.875rem;
  color: var(--chair-orange);
}

.story .infoBar .error {
  color: var(--chair-orange);
}

.story .infoBar .warning {
  color: #f5a623; /* Warning color */
}

.story .infoBar .wordCount {
  text-align: right;
  flex-grow: 1;
  display: flex;
  justify-content: right;
}
