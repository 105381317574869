.verificationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  text-align: center;
}

.iconContainer {
  position: relative;
  margin-bottom: 24px;
}

.envelopeIcon {
  font-size: 80px;
  color: var(--chair-orange);
}

.checkIcon {
  position: absolute;
  bottom: 0;
  right: -10px;
  font-size: 32px;
  color: #4caf50;
  background: white;
  border-radius: 50%;
}

.verificationHeader {
  font-size: 2rem;
  font-weight: bold;
  color: var(--chair-orange);
  margin-bottom: 16px;
}

.verificationText {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 32px;
  line-height: 1.6;
  max-width: 600px;
}

.emailHighlight {
  display: block;
  font-weight: bold;
  color: var(--chair-orange);
  margin-top: 8px;
}

.infoBox {
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  width: 100%;
  max-width: 500px;
  text-align: left;
}

.infoBox h3 {
  color: var(--chair-orange);
  margin-bottom: 16px;
  font-size: 1.25rem;
}

.stepsList {
  padding-left: 24px;
}

.stepsList li {
  margin-bottom: 12px;
  line-height: 1.5;
  color: #555;
}

.continueButton {
  padding: 16px 32px;
  background-color: var(--chair-orange);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.continueButton:hover {
  background-color: #e06c00;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .envelopeIcon {
    font-size: 60px;
  }

  .checkIcon {
    font-size: 24px;
  }

  .verificationHeader {
    font-size: 1.75rem;
  }

  .verificationText {
    font-size: 1rem;
  }

  .infoBox {
    padding: 20px;
  }

  .continueButton {
    padding: 14px 28px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .envelopeIcon {
    font-size: 50px;
  }

  .checkIcon {
    font-size: 20px;
  }

  .verificationHeader {
    font-size: 1.5rem;
  }

  .infoBox {
    padding: 16px;
  }

  .infoBox h3 {
    font-size: 1.1rem;
  }
}
