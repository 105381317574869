/* How It Works Section */
.howItWorks {
  padding: 6rem 2rem;
  background: linear-gradient(
    to bottom,
    var(--background-dark),
    rgba(0, 0, 0, 0.95)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
}

.sectionSubtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

.stepsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.step {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.step:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.stepNumber {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 40px;
  height: 40px;
  background: var(--chair-orange);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
}

.step h3 {
  color: var(--chair-orange);
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.step p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  font-size: 1rem;
  flex-grow: 1;
}

/* Step Stats */
.stepStats {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px dotted rgba(255, 255, 255, 0.1);
  flex-wrap: wrap;
  gap: 1rem;
}

.stepStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  min-width: 80px;
}

.stepStatNumber {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--chair-orange);
  margin-bottom: 0.25rem;
  background: linear-gradient(45deg, var(--chair-orange), #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stepStatLabel {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Responsive layout using media queries */
@media (max-width: 1200px) {
  .stepsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .howItWorks {
    padding: 4rem 1rem;
  }

  .stepsGrid {
    grid-template-columns: 1fr;
    max-width: 500px;
  }

  .step {
    padding: 1.5rem;
  }

  .sectionTitle {
    font-size: 2rem;
  }

  .sectionSubtitle {
    font-size: 1.1rem;
  }

  .stepStatNumber {
    font-size: 1.5rem;
  }

  .stepStatLabel {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .stepStat {
    min-width: 70px;
    padding: 0.5rem;
  }

  .stepStatNumber {
    font-size: 1.3rem;
  }

  .stepStatLabel {
    font-size: 0.65rem;
  }
}
