/* packages/client/src/components/Button/Button.module.css */
.btnContainer {
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: filter 0.1s ease; /* Smooth transition for the filter */
  white-space: nowrap;
}

.button:hover {
  /* background-color: #45a049; */
  filter: brightness(90%);
}

.button:disabled {
  background-color: #9e9e9e; /* Grayed out background */
  cursor: not-allowed; /* Cursor to indicate the button is disabled */
  opacity: 0.7; /* Slightly transparent to indicate it's not active */
}

/* colors */
.primary {
  background-color: var(--green);
}
.secondary {
  background-color: var(--chair-orange);
}

.error {
  background-color: var(--error);
}

.disabled {
  background-color: #ccc;
}

.gray {
  background-color: #f0f0f0;
  color: #333;
}

.orange {
  background-color: var(--chair-orange);
  color: white;
}

.success {
  background-color: var(--status-active);
  color: white;
}

.warning {
  background-color: var(--status-suspended);
  color: white;
}

.danger {
  background-color: var(--error);
  color: white;
}

.danger-light {
  background-color: var(--chair-orange);
  color: white;
}

/* cursor type */
.default-cursor {
  cursor: default;
}

button.button.small {
  font-size: 14px;
  padding: 0.5rem 0.75rem;
}
button.button.medium {
  font-size: 16px;
  padding: 10px 20px;
}
button.button.large {
  font-size: 20px;
  padding: 15px 30px;
}

.rounded {
  border-radius: 20px;
}
.square {
  border-radius: 4px;
}

.buttonContent {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.spinner > svg {
  color: inherit !important;
}
