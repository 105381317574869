.toolbar {
  width: 100%;
  min-height: 40px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: stretch;
  background-color: #f0f0f0;
  position: relative;
  z-index: 1;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  transition: filter 0.1s ease;
}

.backButton svg {
  height: 25px;
  width: 25px;
  color: var(--chair-orange);
}

.username {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-right: auto;
  align-self: flex-start;
  background-color: #f0f0f0;
  color: black;
  font-weight: 600;
  padding: 0px 20px;
  height: 100%;
  align-self: center;
  cursor: pointer;
  transition: filter 0.1s ease;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.1);
}

.connectionType {
  font-size: 0.8rem;
  font-weight: normal;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: var(--chair-orange);
  color: white;
}

.buttonMenu {
  display: flex;
  justify-self: flex-end;
  right: 0;
  position: absolute;
  height: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--chair-orange);
  box-shadow: -1px 0 4px rgba(0, 0, 0, 0.1);
  width: 40px;
  cursor: pointer;
}

.button svg {
  height: 25px;
  width: 25px;
  margin: 0 0.5rem;
}
