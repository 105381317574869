:root {
  --border: 1px solid rgba(221, 221, 221, 0.5); /* Smooth right border */
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.chatWindow {
  display: grid;
  grid-template-columns: minmax(350px, 1fr) 3fr;
  grid-template-rows: 40px 1fr;
  height: 100%;
}

.tabs {
  box-shadow: var(--box-shadow);
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.tab {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: white;

  border-radius: 20px;
  transition: all 0.3s;
  background-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  margin: 5px;
}

.tab:hover {
  background-color: var(--chair-orange);
  transform: translateY(-1px);
}

.tab.active {
  background-color: var(--chair-orange);
  color: white;
}

.list {
  overflow-y: auto;
  box-shadow: var(--box-shadow);
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: flex-start;
}

.listItem {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--box-shadow);
  transition: background-color 0.3s, color 0.3s;
}

.listItem.selected {
  background-color: #007bff;
  color: white;
}

.chatInstance {
  grid-row: 1/-1;
  grid-column: 2/-1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  background: var(--dark-brown);
}

.backButton {
  display: none;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  color: #333;
  padding: 10px;
  box-shadow: var(--box-shadow);
  grid-column: 1 / -1;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.noChatsMessage {
  display: grid;
  place-items: center;
  text-align: center;
  color: #888;
  height: 100%;
  max-width: 90vw;
  margin: auto;
}

.chatCard {
  background-color: transparent;
  color: white;
  box-shadow: var(--box-shadow);
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-items: start;
  transition: background-color 0.2s ease-in-out;
}

.chatCard:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 4px;
}

.participantName {
  font-weight: bold;
  color: #ffffff;
}

.relativeTime {
  font-size: 0.8em;
  color: #999;
}

.lastMessage {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9em;
  padding: 4px 0;
}

.lastMessage.unread {
  color: white;
  font-weight: 600;
}

.lastMessage.systemMessage {
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
}

.lastMessage.pending {
  opacity: 0.7;
}

.lastMessage .content {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lastMessage .statusIndicator {
  display: flex;
  align-items: center;
}

.lastMessage .pendingDot {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.noMessages {
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  font-size: 0.9em;
  padding: 4px 0;
}

.sharedTags {
  font-size: 0.8em;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}

.tag {
  display: flex;
  align-items: center;
  background-color: var(--chair-orange);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  gap: 0.5em;
  cursor: help;
  outline: none;
  transition: transform 0.2s ease;
  border: none;
}

.chatRequestCard {
  border-left: 5px solid orange;
}

.requestIdentifier {
  font-size: 0.8em;
  color: orange;
  font-weight: bold;
}

.chatCard.selected {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

.chatRequestCard.selected {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

@media (max-width: 768px) {
  .chatWindow {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    overflow: hidden;
  }

  .chatInstance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    grid-column: 1/-1;
    z-index: 2;
    transform: translateX(100%);
  }

  .mobileInstance {
    transform: translateX(0);
  }

  .list {
    height: calc(100vh - 40px);
    overflow-y: auto;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }

  /* Update list hiding when chat is open on mobile */
  .chatWindow:has(.mobileInstance) .list {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }

  .tabs {
    box-shadow: var(--box-shadow);
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

.reachoutCard.pending {
  border-left: 5px solid var(--pending-color, #ffa500);
}

.reachoutCard.completed {
  border-left: 5px solid var(--completed-color, #4caf50);
}

.reachoutCard.expired {
  border-left: 5px solid var(--expired-color, #9e9e9e);
}

.reachoutCard.canceled {
  border-left: 5px solid var(--canceled-color, #f44336);
}

/* Add system message styling */
.systemMessage {
  text-align: center;
  padding: 8px;
  color: var(--text-secondary);
  font-size: 0.9em;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin: 8px 0;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 2rem;
  color: var(--text-secondary);
  background: rgba(0, 0, 0, 0.1);
}

.emptyStateIcon {
  font-size: 3rem;
  margin-bottom: 1rem;
  opacity: 0.5;
}

.emptyStateTitle {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.emptyStateMessage {
  font-size: 0.9rem;
  max-width: 300px;
  line-height: 1.4;
}

.noSelection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--dark-brown);
  color: var(--text-secondary);
  text-align: center;
  padding: 2rem;
}

.noSelectionIcon {
  font-size: 3rem;
  margin-bottom: 1rem;
  opacity: 0.5;
}

.noSelectionMessage {
  font-size: 1.1rem;
  max-width: 300px;
  line-height: 1.4;
}

.participantInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.directionArrow {
  color: var(--chair-orange);
  font-size: 1.2rem;
}

.resourceTooltip {
  padding: 0.5rem;
  max-width: 300px;
}

.resourceLabel {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.tooltipTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.tooltipTag {
  background: var(--chair-orange);
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
}

/* Add these alongside the existing .reachoutCard status styles */
.matchCard.pending {
  border-left: 5px solid var(--pending-color, #ffa500);
}

.matchCard.completed {
  border-left: 5px solid var(--completed-color, #4caf50);
}

.matchCard.expired {
  border-left: 5px solid var(--expired-color, #9e9e9e);
}

.matchCard.canceled {
  border-left: 5px solid var(--canceled-color, #f44336);
}
