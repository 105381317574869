.overviewCards {
  padding: 40px;
  display: flex;
  gap: 22px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

.registersCard,
.reportsCard {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;

  @media (max-width: 768px) {
    width: 240px;
  }
}

.registersCard {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
}

.reportsCard {
  background: linear-gradient(135deg, #c2b24a, #f5e05e);
}

.registersCard span,
.reportsCard span {
  font-size: 2.5rem;
  font-weight: 500;
}

.registrationsGraphContainer {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 40px;
  padding: 20px;
  border-radius: 8px;
}

.registrationsGraphContainer span {
  color: black;
}

.registrationsGraphContainer h1 {
  font-size: 1rem;
  color: white;
}

.registrationsGraph {
  margin-top: 16px;
}

.overviewContainer {
  padding: 24px;
  height: 100%;
  overflow: auto;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pageTitle {
  margin-bottom: 24px;
  font-size: 1.8rem;
  color: var(--dark-brown);
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 32px;
}

.statCard {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.statCard::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--dark-brown);
  transition: width 0.3s ease;
}

.statCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.statCard:hover::after {
  width: 100%;
}

.statCard h3 {
  margin: 0 0 12px 0;
  font-size: 1rem;
  color: #666;
}

.statValue {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--dark-brown);
}

.recentActivity {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.recentActivity h2 {
  margin: 0 0 16px 0;
  font-size: 1.2rem;
  color: var(--dark-brown);
}

.activityList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.activityItem {
  padding: 16px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.activityItem:hover {
  background-color: #f9f9f9;
}

.activityTime {
  font-size: 0.8rem;
  color: #888;
  margin-bottom: 4px;
}

.activityContent {
  font-size: 0.95rem;
}

.noActivity {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}
