.chat {
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.chatContent {
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--dark-brown);
  transition: margin 0.3s ease-in-out;
  z-index: 2;
}

/* Desktop styles */
@media (min-width: 769px) {
  .chatContent.chatContentContracted {
    margin-right: 250px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .chatContent.chatContentContracted {
    margin-right: 0; /* No margin shift on mobile */
  }
}

.toolbar {
  width: 100%;
  min-height: 40px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: stretch;
  background-color: #f0f0f0;
  position: relative;
  z-index: 1;

  .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    cursor: pointer;
    transition: filter 0.1s ease;

    svg {
      height: 25px;
      width: 25px;
      color: var(--chair-orange);
    }
  }

  .username {
    display: inline-flex;
    align-items: center;
    margin-right: auto;
    align-self: flex-start;
    background-color: #f0f0f0;
    color: black;
    font-weight: 600;
    padding: 0px 20px;
    height: 100%;
    align-self: center;
    cursor: pointer;
    transition: filter 0.1s ease; /* Smooth transition for the filter */
    box-shadow: 1px 0 4px rgba(0, 0, 0, 0.1);
  }

  .buttonMenu {
    display: flex;
    justify-self: flex-end;
    right: 0;
    position: absolute;
    height: 100%;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--chair-orange);
      box-shadow: -1px 0 4px rgba(0, 0, 0, 0.1); /* Box shadow with horizontal offset of -2px, vertical offset of 0, blur radius of 4px, and opacity of 0.1 */
      width: 40px;
      cursor: pointer;
    }
  }

  svg {
    height: 25px;
    width: 25px;
    margin: 0 0.5rem;
  }
}

/* SideMenu styles */
.sideMenu {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  width: 250px;
  background-color: #f0f0f0;
  transform: translateX(100.1%);
  transition: transform 0.3s ease-in-out;
  z-index: 3;
  overflow-y: auto;
}

.sideMenu.sideMenuVisible {
  transform: translateX(0);
}

.sideMenuSection {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
}

.sectionTitle {
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.75rem;
  color: black;
}

.profileLink {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #f0f0f0;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-weight: 600;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: block;
  text-align: center;
  text-decoration: none;
}

.profileLink:hover {
  background-color: #e8e8e8;
}

.connectionGroup {
  margin-bottom: 1rem;
}

.connectionTypeTitle {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
}

.connectionItem {
  padding: 0.75rem;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.reachoutDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.connectionInfo {
  font-size: 0.9rem;
  color: black;
}

.connectionDate {
  font-size: 0.8rem;
  color: var(--text-secondary);
  text-align: right;
}

.mobileBackButton {
  display: none;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--chair-orange);
  font-weight: 600;
  margin-bottom: 1rem;

  svg {
    height: 20px;
    width: 20px;
  }
}

.sideMenuMobile {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #f0f0f0;
}

.sideMenuMobile.sideMenuVisible {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .mobileBackButton {
    display: flex;
  }

  .sideMenu {
    width: 100%;
    height: 100%;
    transform: translateX(100%);
  }

  .chatContentContracted {
    margin-right: 0;
  }
}

.scrollToBottom {
  background-color: rgba(33, 33, 33, 0.8); /* Slightly translucent dark grey */
  color: white; /* Inner text color */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Slight dark white border */
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle box shadow */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.scrollToBottom:hover {
  background-color: rgba(33, 33, 33, 1); /* Darker grey on hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow on hover */
}

.status {
  display: grid;
  place-items: center;
  color: white;
  height: 100%;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  color: white;
  text-align: center;
}

.errorContainer button {
  padding: 8px 16px;
  background-color: var(--chair-orange);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.errorContainer button:hover {
  background-color: var(--chair-orange-dark);
}

.resourceCard {
  transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
  border-radius: 4px;
  padding: 0.5rem;
  background-color: #f0f0f0;
  margin-top: 0.5rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.resourceCard:hover {
  background-color: #e8e8e8;
}

.tagName {
  font-weight: 600;
  padding: 5px 15px;
  background-color: var(--chair-orange);
  border-radius: 4px;
  margin: 5px;
  display: inline-block;
  color: white;
  text-wrap: nowrap;
}

.createdAt {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: black;
  text-align: center;
}

.reportContainer {
  margin-top: auto;
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.reportButton {
  width: 100%;
  color: var(--chair-orange);
  background-color: transparent;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #e8e8e8;
  }
}

.chatBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  gap: 1rem;
}

.messageContainer {
  flex: 1;
  overflow-y: auto;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
}

.userInfo {
  cursor: pointer;
  padding: 1rem;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.userInfo:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.resourceContainer {
  padding: 10px;
  border-radius: 10px;
  color: white;
  margin-bottom: 10px;
  text-align: center;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

.tag {
  background-color: var(--chair-orange);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .chatContentContracted {
    margin-right: 0;
  }
}

.profileContainer {
  padding: 1rem;
}

.usernameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.username {
  cursor: pointer;
  font-weight: 600;
}

.refreshIcon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.refreshIcon.spinning {
  transform: rotate(360deg);
}

.profileDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: var(--light-gray);
}

.badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.badge.valid {
  background-color: var(--success-bg);
  color: var(--success);
}

.badge.invalid {
  background-color: var(--error-bg);
  color: var(--error);
}

.userMetadata {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
}

.metadataItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: black;
  font-size: 0.9rem;
  width: 100%;
}

/* Call-related styles */
.callStatusBar {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: var(--call-status-bg);
  border-bottom: 1px solid var(--ricepaper-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--call-text-primary);
}

.callStatus {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.callDuration {
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--call-text-secondary);
  padding: 0.25rem 0.5rem;
  background-color: var(--call-duration-bg);
  border-radius: 4px;
}

.buttonMenu {
  display: flex;
}

.button.acceptCallBtn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--call-success);
  color: var(--call-text-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--call-success-dark);
  }
}

.button.endCallBtn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--call-error);
  color: var(--call-text-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--call-error-dark);
  }
}

/* Call button in toolbar */
.toolbar .button.callButton {
  color: var(--call-success);

  &:hover {
    background-color: var(--call-button-hover);
  }

  &.inCall {
    color: var(--call-error);
  }
}

/* Loading state for call handling */
.button.isHandlingCall {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

.matchDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.storiesContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.storySection {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
  border-radius: 4px;
}

.storyLabel {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-secondary);
  margin-bottom: 0.25rem;
}

.storyText {
  font-size: 0.9rem;
  color: black;
  white-space: pre-wrap;
}

.tagsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tagsLabel {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-secondary);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.resourceLink {
  color: var(--chair-orange);
  text-decoration: none;
  transition: color 0.2s ease;
}

.resourceLink:hover {
  color: var(--chair-orange-dark);
  text-decoration: underline;
}
