/* packages/client/src/pages/Register/StepPersonalInfo.module.css */

.container {
  display: grid;
  column-gap: 1.25rem;
}

.ageRangeDisplay {
  padding: 10px;
  margin-top: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* for screens wider than 768px */
@media (min-width: 768px) {
  .container {
    grid-template-columns: auto auto;
  }
}

.personalInfoStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dateInput {
  width: 100%;
  padding: 14px 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  height: 54px;
  box-sizing: border-box;
}

.dateInput:focus {
  outline: none;
  border-color: var(--chair-orange);
  background-color: rgba(255, 255, 255, 0.15);
}

.ageRangeDisplay {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  font-size: 14px;
  color: white;
  text-align: center;
}

.ageRangeDisplay strong {
  color: var(--chair-orange);
  font-weight: 600;
}

@media (min-width: 768px) {
  .personalInfoStep {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
  }
}

@media (max-width: 480px) {
  .dateInput {
    font-size: 0.9rem;
  }

  .ageRangeDisplay {
    font-size: 13px;
  }
}
