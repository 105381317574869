.container {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px 1fr 1fr;
  gap: 0.5rem;
  padding: 1rem;
  color: white;
  min-height: 100%;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
}

.status {
  display: grid;
  place-items: center;
  color: white;
  padding: 2rem 0;
}

.toolbarContainer {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  justify-self: flex-start;
}
.detailsContainer {
  grid-column: 1;
  grid-row: 2;
  display: grid;
  justify-items: center;
  gap: 0.5rem;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.storiesContainer {
  grid-column: 2;
  grid-row: 2/-1;
}

.quotesContainer {
  grid-column: 1;
  grid-row: 3;
}

/* for screens narrower than 768px */
@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .container > * {
    padding: 0 0.5rem;
  }
  .container > * {
    grid-column: auto;
    grid-row: auto;
  }
}

h2.title {
  font-size: 1.5rem;
  display: grid;
  place-content: center;
  margin: 0.5rem;
  color: white;
}

.toolbarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detailsContainer > .card {
  background-color: white; /* Background color for the card */
  border-radius: 8px; /* Rounded corners */
  padding: 1rem; /* Padding inside the card */
}
.card .name {
  color: white; /* Changed color to white */
  background-color: var(--chair-orange);
  padding: 5px 15px;
  border-radius: 20px;
  gap: 0.5em;
  outline: none;
  cursor: default;
  display: inline-block;
}

.card .description {
  padding: 0.5rem;
  color: var(--graphite); /* Ensuring good contrast */
  border-radius: 8px;
}

.card .keywordsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.card .keywords {
  color: var(--graphite); /* Graphite color for the "Keywords:" prefix */
  padding: 0.5rem;
}

.card .keyword {
  font-style: italic;
  color: var(--graphite);
  font-size: 0.9rem;
}

.card .metadata {
  display: grid;
  gap: 0.5rem; /* Space between items */
  margin-top: 1rem; /* Space above the metadata section */
  color: #666; /* Text color for metadata */
  font-size: 0.875em; /* Smaller font size */
}

.card .metadata p {
  margin: 0; /* Remove default margin */
  background-color: #f9f9f9; /* Light background for better readability */
  padding: 0.5rem; /* Padding for the paragraphs */
  border-radius: 5px; /* Rounded corners */
}

.storiesContainer {
  padding: 0 0.5rem;
}

.storiesContainer .storyList {
  display: grid;
  margin: 0.5rem 0;
  /* gap: 0.5rem; */
}

.storiesContainer .storyCard {
  border-bottom: 1px solid #ccc;

  padding: 16px;

  color: white;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.storiesContainer .storyCard:hover {
  background-color: var(--ricepaper);
}

.storiesContainer .storyCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.storiesContainer .storyCard .header h2 {
  font-weight: 700;
}

.storiesContainer .storyCard .content {
  font-size: 16px;
  /* color: #333; */
}

.storiesContainer .storyCard .content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of lines you want to show */
  -webkit-box-orient: vertical;
}

.storiesContainer .storyCard .footer {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  /* color: #666; */
  margin-top: 12px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.pageButton {
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pageButton:hover {
  background-color: #e0e0e0;
  color: var(--chair-orange);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pageButton.active {
  background-color: var(--chair-orange);
  color: white;
  font-weight: bold;
}
