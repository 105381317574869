.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  font-size: 1.5rem; /* Adjust size as needed */
  color: var(--chair-orange); /* Use your primary color */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
