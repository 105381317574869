/* packages/client/src/pages/Profile/Profile.module.css */
.wrapper {
  padding: 20px;
  padding-bottom: 1rem;
  background-color: var(--bg-brown);
  color: white;
  min-height: calc(100vh - 60px); /* Adjust based on your navbar height */
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  background-color: var(--brown);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  gap: 1.5rem;
}

.avatar {
  margin-right: 20px;
}

.defaultAvatar {
  width: 4.375rem;
  height: 4.375rem;

  @media (min-width: 640px) {
    width: 100px;
    height: 100px;
  }
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profileMain {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1024px) {
    width: 60%; /* Adjust this value as needed */
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.username {
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--chair-orange);
}

.userMetadata {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.metadataItem {
  background-color: var(--ricepaper);
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  font-size: 0.9rem;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.infoLine {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.25rem;
}

/* Status badges */
.statusBadges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
}

.badge.valid {
  background-color: rgba(76, 175, 80, 0.1);
  color: var(--status-active);
}

.badge.invalid {
  background-color: rgba(239, 68, 68, 0.1);
  color: var(--status-banned);
}

.badge.warning {
  background-color: rgba(255, 255, 255, 0.15);
  color: #aaaaaa;
}

.badge svg {
  width: 16px;
  height: 16px;
}

/* Scores section */
.scoresSection {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 1024px) {
    width: 38%; /* Adjust to complement profileMain width */
    margin-left: auto;
  }
}

.scoreCard {
  flex: 1;
  min-width: 150px;
  background-color: var(--ricepaper);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.scoreHeader {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  margin-bottom: 0.5rem;
}

.scoreValue {
  font-size: 2rem;
  font-weight: 700;
  color: var(--chair-orange);
  display: flex;
  align-items: baseline;
}

.maxScore {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 0.25rem;
}

.scoreDescription {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.5rem;
  font-style: italic;
}

/* Badges section */
.badgesSection {
  width: 100%;
  background-color: var(--ricepaper);
  padding: 1rem;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  @media (min-width: 1024px) {
    order: 3; /* Ensure it appears after both profile info and scores */
  }
}

.badgesSection h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noBadges {
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
}

/* Badge category tabs */
.badgeTabs {
  display: flex;
  overflow-x: auto;
  margin-bottom: 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.badgeTabs::-webkit-scrollbar {
  display: none;
}

.badgeTab {
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 0.5rem;
  transition: all 0.2s ease;
}

.badgeTab:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
}

.activeBadgeTab {
  background-color: var(--chair-orange);
  color: white;
}

/* Badge grid container with scroll behavior */
.badgeGridContainer {
  position: relative;
  overflow: hidden;
}

.actionMenu {
  position: absolute;
  top: 20px; /* Adjust as needed for spacing */
  right: 20px; /* Adjust as needed for spacing */
}

.actionMenu button {
  color: white;
}

/* Improved tabs styling with better active state and connection to content */
.tabs {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  z-index: 1; /* Ensure tabs appear above tab content */
  margin-bottom: 0; /* Remove margin as we'll connect tabs directly to content */
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab {
  padding: 0.75rem 1.25rem;
  background-color: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.7);
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-right: 0.25rem;
  position: relative;
  top: 0;
}

.tab:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
}

.activeTab {
  background-color: var(--brown);
  color: var(--chair-orange);
  font-weight: 600;
  padding-bottom: 0.85rem; /* Slightly extend the active tab */
  margin-bottom: -0.1rem; /* Pull the tab down to connect with content */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.activeTab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: var(--chair-orange);
  border-radius: 3px 3px 0 0;
}

.tabContent {
  background-color: var(--brown);
  border-radius: 0 8px 8px 8px; /* Rounded corners except top-left */
  padding: 1.5rem;
  margin-bottom: 2rem;
  min-height: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 0;
}

/* Improved toolbar layout */
.toolbar {
  margin-bottom: 24px;
  width: 100%;
}

/* Toolbar row for desktop layout */
.toolbarRow {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

/* Search container */
.searchContainer {
  flex: 1;
  max-width: 500px;
  position: relative;
}

/* Sort container */
.sortContainer {
  width: 180px;
  flex-shrink: 0;
  position: relative;
}

/* New button container */
.newButtonContainer {
  margin-left: auto;
}

/* Bottom toolbar for mobile */
.toolbarBottom {
  display: flex;
  width: 100%;
}

/* Mobile layout adjustments */
@media (max-width: 639px) {
  .toolbar {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .searchContainer {
    width: 100%;
    max-width: 100%;
  }

  .sortContainer {
    width: 100%;
  }
}

/* Enhanced search input styles with animations */
.searchInput {
  width: 100%;
  height: 48px; /* Increased height */
  padding: 0 48px; /* Increased padding */
  border-radius: 8px;
  border: 2px solid var(--ricepaper-white); /* Thicker border */
  background-color: var(--dark-brown);
  color: white;
  font-size: 1rem; /* Slightly larger font */
  transition: all 0.3s ease; /* Smooth transition for all properties */
}

.searchInput:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(var(--chair-orange-rgb), 0.3);
}

.searchInput:hover:not(:focus) {
  border-color: rgba(255, 255, 255, 0.8);
}

.searchInput::placeholder {
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.3s ease;
}

.searchInput:focus::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.searchIcon {
  position: absolute;
  left: 16px; /* Increased spacing */
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  pointer-events: none;
  transition: color 0.3s ease;
  font-size: 1.25rem; /* Larger icon */
}

.searchContainer:focus-within .searchIcon {
  color: var(--chair-orange);
}

.clearButton {
  position: absolute;
  right: 16px; /* Increased spacing */
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.clearButton:hover {
  color: var(--chair-orange);
  background-color: rgba(255, 255, 255, 0.1);
}

/* Improved sort select styling */
.sortTrigger {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px; /* Match search input height */
  border-radius: 8px;
  border: 2px solid var(--ricepaper-white); /* Match search input border */
  background-color: var(--dark-brown);
  color: white;
  cursor: pointer;
  height: 48px; /* Match search input height */
  transition: all 0.3s ease;
}

.sortTrigger:hover {
  border-color: rgba(255, 255, 255, 0.8);
}

.sortIcon {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.sortContainer:focus-within .sortIcon {
  transform: rotate(180deg);
}

.sortDropdown {
  background-color: var(--dark-brown);
  border: 2px solid var(--ricepaper-white);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 10;
  margin-top: 4px;
  width: 100%;
}

.sortOption {
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sortOption:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.activeSort {
  background-color: rgba(var(--chair-orange-rgb), 0.2);
  font-weight: 500;
}

/* Mobile optimizations for search components */
@media (max-width: 639px) {
  .toolbar {
    gap: 12px;
  }

  .searchInput {
    height: 44px;
    font-size: 0.95rem;
  }

  .sortTrigger {
    height: 44px;
    padding: 10px 14px;
  }

  .toolbar .newButton {
    height: 44px;
    width: 100%;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.summaryHeader {
  margin-bottom: 0.5rem;
}

.summaryHeader h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--chair-orange);
  margin-bottom: 0.5rem;
}

.summaryDescription {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.summarySection {
  background-color: var(--ricepaper);
  padding: 1.25rem;
  border-radius: 8px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.sectionHeader h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

/* Mobile tags container - using class instead of media query */
.mobileTags {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  padding-bottom: 0.5rem;
  width: 100%;
  max-width: 100%;
}

.mobileTags::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/* Tag styling to match StoryCard and LifeExperienceCard */
.tag {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.95em;
  background: var(--intent-bg, rgba(45, 45, 45, 0.3));
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--text-primary, white);
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tag:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

/* Mobile tag styling */
.mobileTag {
  flex-shrink: 0;
  white-space: nowrap;
  padding: 4px 10px;
  font-size: 0.85em;
}

/* Extra small tag styling */
.extraSmallTag {
  padding: 3px 8px;
  font-size: 0.8em;
}

.tagName {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
}

.tagCount {
  background-color: var(--chair-orange);
  color: white;
  font-size: 0.75rem;
  padding: 0.15rem 0.4rem;
  border-radius: 12px;
  min-width: 1.5rem;
  text-align: center;
  font-weight: 600;
  margin-left: 0.5rem;
}

.emptyMessage {
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
  text-align: center;
  padding: 1rem 0;
}

.summaryFooter {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  padding-top: 0.5rem;
}

.lifeExperiences {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.experienceCard {
  background-color: var(--ricepaper);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.story {
  font-size: 16px;
  margin: 10px 0;
  color: white;
}

.date {
  font-size: 14px;
  color: white;
}

.placeholder {
  font-size: 16px;
  color: white;
}

.quote {
  font-size: 16px;
  font-style: italic;
  color: white;
  background-color: var(--ricepaper);
  padding: 20px; /* Added padding */
  width: fit-content; /* Fit content width */
  border-radius: 20px; /* Rounded borders */
}

/* New styles for life experience tags */
.tag {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: var(--chair-orange);
  display: inline-block;
  text-wrap: nowrap;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  cursor: default;
}

/* StoriesContent.jsx */

.storyCard {
  padding: 10px 0; /* Adjust padding to fit the inner div */
  background-color: var(--ricepaper);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  /* Border only if not the last child */
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Slightly thicker subtle border */
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

/* Title */
.title {
  font-weight: bold;
  color: white;
}

/* Meta Information */
.meta {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
}

/* Author */
.author {
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
}

/* Relative Time */
.relativeTime {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* Preview Text */
.previewText {
  font-size: 0.875em;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0.5rem; /* Added margin for spacing */
  color: white;

  mark {
    padding: 0 0.25rem; /* Added padding */
    background-color: var(--chair-orange); /* Orange highlight */
    color: white; /* White text */
    border-radius: 4px;
  }
}

/* Tags */
.tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.tagLabel {
  font-size: 0.875em;
  display: flex;
  align-items: center;
  background-color: var(--chair-orange); /* Ensure it's defined in your CSS */
  padding: 5px 10px;
  border-radius: 20px;
  gap: 0.5em;
  justify-self: left;
  outline: none;
  color: white;
}

/* Action Bar */
.actionBar {
  display: flex;
  width: 100%;
  margin-top: 0.5rem; /* Added margin for spacing */
}

/* Hug Button */
.hugButton {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 2rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  color: #495057;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: flex;
  gap: 0.25rem;
  align-items: center;

  &:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  &.hugged {
    background-color: var(--chair-orange);
    color: white;
    border-color: var(--chair-orange);

    &:hover {
      background-color: #e2e6ea;
      border-color: #dae0e5;
      color: #495057;
    }
  }
}

/* Add this to your Profile.module.css */
.undiscoveredMessage {
  margin-top: 20px; /* Add spacing between the stories and the message */
  padding: 15px;
  background-color: var(
    --ricepaper
  ); /* Use the same color as story cards for consistency */
  border-radius: 8px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  text-align: center;
  line-height: 1.5;

  a,
  strong {
    color: var(--chair-orange); /* Use the same color as the username */
  }
}

.modalButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 320px) {
    justify-content: center;
  }
}

/* Action button */
.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Action buttons container */
.actionButtonsContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 425px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
  }

  @media (min-width: 640px) {
    justify-content: flex-end;
  }
}

/* Reviews */
.reviewsParentContainer {
  padding: 0 16px 16px 16px;
}

.reviewsHeader {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: white;
  }

  h5 {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}

.reviewItemsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  gap: 1.5rem;
  justify-items: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1015px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1320px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  @media (min-width: 3000px) {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

.scoreRating {
  display: flex;
  align-items: center;

  .scoreLabel {
    width: 6.875rem;
    font-size: 14px;
    color: #333;
  }
}

.reviewCard {
  width: 18.75rem;
  height: 13.25rem;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 600;
    }
  }

  /* Comment */
  div + p {
    height: 3.75rem;
    margin-top: 12px;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 14px;
    color: #333;

    span {
      text-transform: capitalize;
    }
  }
}

.reviewModal {
  display: flex;
  flex-direction: column;

  .reviewModalHeader {
    display: flex;
    justify-content: space-between;

    p {
      font-weight: 600;
    }
  }

  > div + p {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  p > span {
    text-transform: capitalize;
  }
}

/* Mobile optimizations */
.stackedProfile {
  flex-direction: column;
  gap: 1rem;
}

.stackedProfile .actionMenu {
  position: static;
  align-self: flex-end;
  margin-top: -2.5rem; /* Pull up the action menu to overlap with the user info */
}

/* Compact scores for mobile */
.compactScores {
  gap: 0.5rem;
}

.compactScores .scoreCard {
  padding: 0.75rem;
  min-width: 0;
}

.compactScores .scoreHeader {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.compactScores .scoreValue {
  font-size: 1.5rem;
}

.compactScores .maxScore {
  font-size: 0.75rem;
}

.compactScores .scoreDescription {
  display: none; /* Hide descriptions on desktop to save space */
}

/* Compact badges for mobile */
.compactBadges {
  padding: 0.75rem;
}

.compactBadges h3 {
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.compactBadges .badgesList {
  gap: 0.5rem;
}

.compactBadges .badgeItem {
  padding: 0.5rem;
  min-width: 60px;
}

.compactBadges .badgeIcon {
  font-size: 1.25rem;
}

.compactBadges .badgeName {
  font-size: 0.75rem;
}

/* Responsive adjustments for the header */
@media (max-width: 575px) {
  .header {
    padding: 15px;
    gap: 1rem;
  }

  .username {
    font-size: 1.5rem;
  }

  .userMetadata {
    flex-wrap: wrap;
  }

  .metadataItem {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  }

  .statusBadges {
    margin-top: 0.25rem;
  }

  .badge {
    font-size: 0.75rem;
    padding: 0.2rem 0.4rem;
  }

  .infoLine {
    font-size: 0.8rem;
  }

  .summarySection {
    padding: 1rem;
  }

  .summaryHeader h2 {
    font-size: 1.25rem;
  }

  .sectionHeader h3 {
    font-size: 1rem;
  }

  .tag {
    padding: 0.4rem 0.6rem;
  }

  .tagName {
    font-size: 0.8rem;
  }

  .tagCount {
    font-size: 0.7rem;
    padding: 0.1rem 0.3rem;
  }

  .tab {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .activeTab {
    padding-bottom: 0.6rem;
  }

  .tabContent {
    padding: 1rem;
    border-radius: 0 8px 8px 8px;
  }
}

/* On very small screens, make badges stack vertically */
@media (max-width: 400px) {
  .statusBadges {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Make scoreCards more compact on desktop */
@media (min-width: 1024px) {
  .scoreCard {
    padding: 0.75rem;
    min-width: 0;
  }

  .scoreHeader {
    font-size: 0.9rem;
  }

  .scoreValue {
    font-size: 1.5rem;
  }

  .scoreDescription {
    display: none; /* Hide descriptions on desktop to save space */
  }
}

/* Desktop layout using grid */
.desktopLayout {
  display: grid;
  grid-template-columns: 60% 38%;
  grid-template-rows: auto auto;
  grid-gap: 1.5rem;
  grid-template-areas:
    "profile scores"
    "badges badges";
}

.desktopLayout .profileMain {
  grid-area: profile;
  width: 100%;
}

.desktopLayout .scoresSection {
  grid-area: scores;
  width: 100%;
  margin-left: 0;
}

.desktopLayout .badgesSection {
  grid-area: badges;
  width: 100%;
}

/* Remove the flex-direction changes for desktop since we're using grid */
.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  background-color: var(--brown);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  gap: 1.5rem;
}

/* Keep the existing mobile optimizations */

/* Add these new styles to your existing Profile.module.css */

.visibilityOverview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }
}

.visibilityCard {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--ricepaper);
  padding: 1.25rem;
  border-radius: 8px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.visibilityCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.visibilityIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--chair-orange);
}

.visibilityContent {
  flex: 1;
}

.visibilityContent h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: white;
}

.visibilityContent p {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

.sectionStats {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.hiddenCount {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
}

.hiddenCount::before {
  content: "🔒";
  font-size: 0.8rem;
}

.emptyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  text-align: center;
}

.emptyMessage {
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
}

.connectPrompt {
  margin-top: 1rem;
  background-color: var(--chair-orange);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.connectPrompt:hover {
  background-color: #e67e22;
}

@media (min-width: 768px) {
  .searchContainer {
    max-width: 600px; /* Wider search bar on desktop */
  }

  .searchInput {
    height: 52px; /* Even taller on desktop */
    font-size: 1.05rem;
    padding: 0 52px;
  }

  .searchIcon {
    left: 18px;
    font-size: 1.35rem;
  }

  .clearButton {
    right: 18px;
  }
}

/* New content button styling */
.newContentButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--chair-orange);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.newContentButton:hover {
  background-color: #e67e22;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.newContentButton svg {
  font-size: 1.1rem;
}

/* New content card for mobile */
.newContentCard {
  background-color: var(--ricepaper);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.newContentCard p {
  color: white;
  font-weight: 500;
}

.newContentCardButton {
  background-color: var(--chair-orange);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.newContentCardButton:hover {
  background-color: #e67e22;
  transform: scale(1.05);
}

/* Make tags section horizontally scrollable on mobile */
@media (max-width: 639px) {
  .tags {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    padding-bottom: 0.5rem;
    width: 100%;
    max-width: 100%;
  }

  .tags::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .tag {
    flex-shrink: 0;
    white-space: nowrap;
  }
}

/* Account status banners */
.suspendedBanner,
.bannedBanner,
.deletedBanner {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
}

.suspendedBanner {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}

.bannedBanner {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.deletedBanner {
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  color: #383d41;
}

.statusIcon {
  font-size: 24px;
  margin-right: 16px;
  flex-shrink: 0;
}

.statusContent {
  flex: 1;
}

.statusContent h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  font-weight: 600;
}

.statusContent p {
  margin: 0 0 8px 0;
}

.statusDetails {
  font-style: italic;
  opacity: 0.8;
}

.viewDetailsButton {
  margin-top: 8px;
}

/* Restricted content styles */
.restrictedContent {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.restrictedIcon {
  font-size: 32px;
  color: #fd7e14; /* Orange warning color */
}

.restrictedContent h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.restrictedContent p {
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  max-width: 500px;
}

.restrictedNote {
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 16px;
}

/* Enhanced account status section styles */
.accountStatusSection {
  background-color: var(--ricepaper);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  border-left: 4px solid #dc3545; /* Default red for account issues */
  color: white;
}

/* For registration status, use blue */
#registrationStatusSection {
  border-left-color: #007bff;
}

/* For email verification, use teal */
#registrationStatusSection[style*="17a2b8"] {
  background-color: rgba(23, 162, 184, 0.1);
}

.accountStatusSection h3 {
  margin-top: 0;
  font-size: 1.25rem;
  margin-bottom: 16px;
}

.statusSummary {
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.statusReason {
  font-size: 1.1rem;
  margin-bottom: 12px;
  font-weight: 500;
}

.statusActions {
  margin-top: 24px;
  display: flex;
  gap: 12px;
}

/* Make buttons more visible on dark background */
.statusActions button {
  padding: 10px 16px;
  font-weight: 500;
}
