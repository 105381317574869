/* packages/client/src/components/Navbar/Navbar.module.css */
.header {
  position: fixed;
  width: 100%;
  height: var(--navbar-height);
  background-color: rgba(var(--navbar-brown), 0.95);
  backdrop-filter: blur(10px);
  z-index: var(--navbar-z-index);
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-left: auto;
}

.mainNav,
.secondaryNav {
  display: flex;
  gap: 0.5rem;
  list-style: none;
  flex-wrap: nowrap;
}

.mainNav {
  flex-shrink: 1;
  min-width: 0;
}

.secondaryNav {
  padding-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  flex-shrink: 0;
}

.userActions {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 2rem;
  color: white;
  flex-shrink: 0;
}

.loginButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: var(--chair-orange);
  color: white;
  text-decoration: none;
  transition: background-color 0.2s;
}

.loginButton:hover {
  background-color: var(--chair-orange-dark);
}

.menuToggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.burgerMenuIcon {
  width: 24px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  width: 100%;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
}

@media (max-width: 992px) {
  .menuToggle {
    display: block;
    margin-left: auto;
    z-index: 100;
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0rem !important;
    position: fixed;
    top: var(--navbar-height);
    right: -300px; /* Start off-screen */
    width: 300px;
    height: calc(100vh - var(--navbar-height));
    background-color: rgba(var(--navbar-brown), 0.98);
    backdrop-filter: blur(10px);
    padding: 2rem;
    transition: transform 0.3s ease, right 0.3s ease;
    transform: translateX(0);
    overflow-y: auto;
    justify-content: flex-start;
  }

  .nav.active {
    transform: translateX(-300px); /* Slide out when closing */
  }

  /* Make burger menu white and more visible */
  .burgerMenuIcon .line {
    background-color: white;
    width: 24px;
    height: 2px;
    border-radius: 2px;
    transition: all 0.3s ease;
  }

  /* Animate burger menu when active */
  .menuToggle.active .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menuToggle.active .line:nth-child(2) {
    opacity: 0;
  }

  .menuToggle.active .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* Improve mobile menu item styling */
  .mainNav,
  .secondaryNav {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 0;
    border: none;
  }

  .header ul li a {
    width: 100%;
    padding: 1rem;
    font-size: 1.1rem;
    color: white;
    opacity: 0.9;
  }

  .header ul li a:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
  }

  .secondaryNav {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .userActions {
    width: 100%;
    margin-left: 0;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .loginButton {
    width: 100%;
    justify-content: center;
    padding: 0.75rem;
  }
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  font-weight: 700;
  margin-right: auto;
}

.logo > a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo .logo_image {
  height: 35px;
  transition: transform 0.3s ease;
}

.logo:hover .logo_image {
  transform: translateY(-2px);
}

.logo_text {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.header nav {
  flex-grow: 1;
  display: flex;
}

.header ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header ul li a {
  padding: 0.7rem 1rem;
  font-size: 1.1rem;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.85);
  transition: all 0.2s ease;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
}

.header ul li a:hover {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

.header ul li a.active {
  color: white;
  background: rgba(var(--chair-orange), 0.15);
  font-weight: 500;
}

.header ul li a.active::after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  background-color: var(--chair-orange);
  border-radius: 50%;
  z-index: 1;
}

.header ul li a p {
  position: relative;
}

.header ul li a:hover p::after,
.header ul li a.active p::after {
  content: none;
}

/* Mobile menu styles */
.burgerMenuIcon {
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 1rem;
}

.burgerMenuIcon .line {
  width: 100%;
  height: 2px;
  background-color: #f1ecdf;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.burgerMenuIcon.active .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.burgerMenuIcon.active .line:nth-child(2) {
  opacity: 0;
}

.burgerMenuIcon.active .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Avatar dropdown styles */
.avatarDropDownContainer {
  position: relative;
  margin-left: 0.5rem;
  z-index: calc(
    var(--navbar-z-index) + 1
  ); /* Ensure dropdown is above other elements */
}

.avatarDropDownContainer > button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarDropDownContainer button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.avatarDropDownMenu {
  background-color: rgba(var(--navbar-brown), 1);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  width: 240px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000;
}

.dropdownHeader {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownHeader h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: white;
}

.closeButton {
  background: none;
  border: none;
  color: white;
  padding: 0.5rem;
  margin-left: auto;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.closeButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.linkItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: white;
  transition: background-color 0.2s;
}

.linkItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.linkItem svg {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
}

/* Mobile styles */
@media (max-width: 575px) {
  .avatarDropDownMenu {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100%;
    height: 100vh;
    max-height: none;
    border-radius: 0;
    border: none;
    background-color: rgba(var(--navbar-brown), 1);
    z-index: var(--fullscreen-mobile-z-index);
  }

  .dropdownHeader {
    position: sticky;
    top: 0;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(var(--navbar-brown), 1);
    z-index: calc(var(--fullscreen-mobile-z-index) + 1);
  }

  .dropdownHeader h3 {
    color: white;
  }

  .linkItem {
    color: white;
    padding: 16px;
    font-size: 1.125rem;
  }

  .linkItem:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .linkItem svg {
    color: white;
    font-size: 1.5rem;
  }
}

/* Removed .notificationIcon, .badge, .dropdown, .viewAll styles */

/* Update the .dropDownMenuContainer class */
.dropDownMenuContainer {
  position: absolute;
  top: 0; /* Align to the top */
  right: -100vw; /* Start hidden off-screen to the right */
  width: 300px; /* Width of the dropdown */
  height: 100vh; /* Full height of the viewport */
  background-color: #f1f1f1;
  transition: right 0.3s ease; /* Transition for sliding effect */
  z-index: 2;
  color: #f1ecdf;
}

@media screen and (max-width: 992px) {
  .navbar {
    display: flex;
  }

  .menu {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0;
  }

  .logo_image img.logo_chair {
    height: 30px;
  }

  .menu {
    display: none;
  }

  .dropDownMenuContainer.active {
    right: 0; /* Bring the menu on-screen */
  }

  .dropDownMenu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: rgba(57, 45, 40, 1);
  }

  .dropDownMenu a {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 1em !important;
    font-weight: 700;
    font-size: 1.5em;
    padding: 1em;
  }

  .dropDownMenu a li {
    text-decoration: none;
    color: #f1ecdf;
    transition: box-shadow 0.3s ease-in-out;
  }

  a:hover li {
    box-shadow: inset 0 -3px 0 0 var(--chair-orange);
  }

  a li.active {
    box-shadow: inset 0 -2px 0 0 var(--lightOrange400);
  }
}

.message {
  margin-bottom: 10px; /* Add margin beneath the message */
  font-size: 1rem; /* Adjust font size as needed */
  color: #333; /* Set a color for the message text */
}

/* Add this new class for menu icons */
.menuIcon {
  display: inline-flex;
  margin-right: 0.5rem;
  font-size: 1.2rem;
}
