/* src/Toggle.module.css */
.toggleSwitch {
  display: inline-block;
  width: 60px;
  height: 30px;
  border-radius: 30px;
  background-color: #e0e0e0;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.on .switch {
  background-color: white;

  transform: translateX(30px);
}

.off .switch {
  background-color: #ccc;
  transform: translateX(0);
}

.toggleSwitch.on {
  background-color: #4caf50;
}

.toggleSwitch.off {
  background-color: #9e9e9e;
}
