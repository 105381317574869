.banner {
  background-color: #fff3cd;
  border-bottom: 1px solid #ffeeba;
  color: #856404;
  padding: 8px 16px;
  width: 100%;
  z-index: var(--ticker-z-index);
}

.bannerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 0.9rem;
}

.icon {
  font-size: 1.2rem;
}

.detailsButton {
  background: none;
  border: none;
  color: #856404;
  text-decoration: underline;
  margin-left: 8px;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
}

.detailsButton:hover {
  color: #533f03;
}

/* Modal styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalHeader {
  padding: 16px 20px;
  background-color: #856404;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader h3 {
  margin: 0;
  font-size: 1.2rem;
}

.closeButton {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.modalBody {
  padding: 20px;
  color: #333;
}

.statusInfo {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.infoIcon {
  font-size: 24px;
  color: #856404;
  flex-shrink: 0;
}

.statusMessage {
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 0;
}

.endDate {
  margin-bottom: 8px;
}

.accessInfo {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #495057;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.actionButton:hover {
  background-color: #e9ecef;
}

.actionButton svg {
  font-size: 20px;
  color: #0d6efd;
}

/* Responsive styles */
@media (max-width: 480px) {
  .modal {
    width: 95%;
  }

  .statusInfo {
    flex-direction: column;
    gap: 8px;
  }

  .infoIcon {
    display: none;
  }
}

.banner {
  width: 100%;
  padding: 10px 16px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.banner p {
  margin: 0;
}

.actionLink {
  font-weight: 600;
  text-decoration: underline;
  margin-left: 8px;
}

/* Registration flow banners */
.registrationBanner {
  background-color: #e3f2fd; /* Light blue */
  color: #0d47a1;
}

.verificationBanner {
  background-color: #e8f5e9; /* Light green */
  color: #1b5e20;
}

/* Account status banners */
.suspendedBanner {
  background-color: #fff3e0; /* Light orange */
  color: #e65100;
}

.bannedBanner {
  background-color: #ffebee; /* Light red */
  color: #b71c1c;
}

.deletedBanner {
  background-color: #f5f5f5; /* Light gray */
  color: #424242;
}

/* Add this to your existing styles */
.approvalBanner {
  background-color: #cce5ff;
  border-color: #b8daff;
  color: #004085;
}
