.usernameStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.fieldContainer {
  width: 100%;
  margin-bottom: 1rem;
  min-height: 90px;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 2px solid rgba(255, 255, 255, 0.3);
  height: 54px;
  width: 100%;
}

.usernameInput {
  width: 100%;
  padding: 14px 40px 14px 16px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: transparent;
  color: white;
  caret-color: var(--chair-orange);
  height: 100%;
  box-sizing: border-box;
}

.usernameInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.usernameInput:focus {
  outline: none;
}

.inputWrapper.error {
  border-color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
}

.inputWrapper.success {
  border-color: #4caf50;
  background-color: rgba(76, 175, 80, 0.1);
}

.icon {
  position: absolute;
  right: 12px;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.success {
  color: #4caf50;
}

.icon.error {
  color: #ff6b6b;
}

.helperText {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  color: rgba(255, 255, 255, 0.7);
  min-height: 20px;
  display: block;
}

.helperText.success {
  color: #4caf50;
}

.helperText.error {
  color: #ff6b6b;
}

/* Loading spinner styles */
.icon :global(.spinner) {
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.7);
  border-top-color: transparent;
}

@media (max-width: 768px) {
  .usernameStep {
    max-width: 100%;
  }

  .usernameInput {
    padding: 12px 40px 12px 14px;
    font-size: 0.95rem;
  }

  .helperText {
    font-size: 0.8rem;
  }
}
