.hero {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: var(--background-dark);
  position: relative;
  overflow: hidden;
}

.content {
  text-align: center;
  max-width: 800px;
  margin-bottom: 3rem;
  z-index: 2;
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1.5rem;
}

.subtitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
}

.dynamicText {
  min-height: 2rem;
}

.typedText {
  font-size: 1.8rem;
  color: var(--chair-orange);
  font-weight: 600;
}

.connectionAnimation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  width: 100%;
  position: relative;
}

.figure {
  width: 60px;
  height: 60px;
  opacity: 0;
  transition: all 1s ease;
}

.leftFigure {
  transform: translateX(-50px);
}

.rightFigure {
  transform: translateX(50px);
}

.figure.visible {
  opacity: 1;
  transform: translateX(0);
}

.connectingLine {
  height: 2px;
  background: var(--chair-orange);
  width: 0;
  margin: 0 10px;
  transition: width 1.5s ease 0.5s;
}

.connectingLine.visible {
  width: 100px;
}

.cta {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.primaryButton {
  padding: 0.8rem 1.5rem;
  background: var(--chair-orange);
  color: black;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primaryButton:hover {
  background: #ffd700;
  transform: translateY(-2px);
}

.secondaryButton {
  padding: 0.8rem 1.5rem;
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.secondaryButton:hover {
  border-color: var(--chair-orange);
  color: var(--chair-orange);
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .typedText {
    font-size: 1.5rem;
  }

  .cta {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }

  .primaryButton,
  .secondaryButton {
    width: 100%;
  }
}
