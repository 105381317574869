:root {
  --fullscreen-mobile-z-index: 150; /* New highest z-index for full-screen mobile views */
  --tooltip-z-index: 140;
  --dropdown-z-index: 130;
  --overlay-z-index: 120;
  --navbar-z-index: 110;
  --ticker-z-index: 100;
  --joyride-z-index: 90;
  --page-toolbar-z-index: 80;
}
