:root {
  --input-bg: var(--ricepaper);
  --input-border: var(--ricepaper-white);
  --input-text: var(--tag-default);
  --input-placeholder: rgba(249, 245, 215, 0.5);
  --input-focus-border: var(--chair-orange);
  --input-focus-shadow: rgba(237, 125, 49, 0.2);
  --input-valid: var(--green);
  --input-invalid: var(--error);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.input {
  width: 100%;
  padding: 1rem;
  border: 2px solid var(--input-border);
  border-radius: 12px;
  background: var(--input-bg);
  color: var(--input-text);
  font-size: 1rem;
  line-height: 1.5;
  resize: none;
  min-height: 150px;
  transition: all 0.2s ease;
}

.input:focus {
  outline: none;
  border-color: var(--input-focus-border);
  box-shadow: 0 0 0 4px var(--input-focus-shadow);
}

.input.valid {
  border-color: var(--input-valid);
}

.input.invalid {
  border-color: var(--input-invalid);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  font-size: 0.875rem;
}

.wordCounter {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.count {
  font-weight: 600;
  color: var(--form-text-secondary);
}

.count.valid {
  color: var(--input-valid);
}

.count.invalid {
  color: var(--input-invalid);
}

.label {
  color: var(--form-text-secondary);
}

.error {
  color: var(--input-invalid);
  font-size: 0.875rem;
}

.hint {
  color: var(--form-text-secondary);
}

.counter {
  color: var(--form-text-secondary);
}

.counter.valid {
  color: var(--input-valid);
}

.counter.invalid {
  color: var(--input-invalid);
}

.input::placeholder {
  color: var(--input-placeholder);
}

@media (min-width: 768px) {
  .input {
    font-size: 1.125rem;
    min-height: 180px;
  }
}
