.reviewContainer {
  padding: 1rem;
  color: var(--text-primary);
}

.title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.ratingSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.ratingGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.ratingGroup label {
  font-weight: 600;
  color: var(--text-primary);
}

.starRating {
  display: flex;
  gap: 0.25rem;
}

.star {
  font-size: 1.5rem;
  cursor: pointer;
  color: #ddd;
  transition: color 0.2s ease;
}

.star.filled {
  color: var(--chair-orange);
}

.star.hover {
  color: var(--chair-orange);
  filter: brightness(110%);
}

.commentSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.commentSection label {
  font-weight: 600;
  color: var(--text-primary);
}

.commentSection textarea {
  min-height: 100px;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  resize: vertical;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.error {
  color: var(--error);
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.throttleMessage {
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.85rem;
  margin-top: 1rem;
}

/* Review List Styles */
.reviewList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reviewItem {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.reviewItem:last-child {
  border-bottom: none;
}

.reviewScores {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.score {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.scoreLabel {
  font-weight: 600;
  color: var(--text-primary);
}

.reviewText {
  margin: 0.5rem 0;
  color: var(--text-primary);
}

.reviewMeta {
  display: flex;
  justify-content: space-between;
  color: var(--text-secondary);
  font-size: 0.85rem;
}

.noReviews {
  text-align: center;
  color: var(--text-secondary);
  font-style: italic;
  padding: 2rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ratingDesc {
  color: var(--text-secondary);
  font-size: 0.85rem;
  text-align: center;
  margin-top: 0.5rem;
  animation: fadeIn 0.3s ease-out;
  min-height: 2.5em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  backdrop-filter: blur(4px);
}

.dialog {
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  animation: dialogEnter 0.2s ease-out;
  z-index: 1101;
  position: relative;
}

@keyframes dialogEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--chair-orange);
}

.closeButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #333;
}

.content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formField {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.formFieldLabel {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 0.95rem;
}

.textarea {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background-color: white;
  transition: all 0.2s ease;
  color: var(--text-primary);
  width: 100%;
  min-height: 120px;
  resize: vertical;
  font-family: inherit;
  line-height: 1.4;
}

.textarea:hover:not(:disabled) {
  border-color: var(--chair-orange);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.textarea:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(var(--chair-orange-rgb), 0.1);
}

.textarea:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  opacity: 0.7;
  border-color: #ddd;
}

.ratingGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 10px;
  border: 1px solid #ccc;
  transition: all 0.2s ease;
}

.ratingGroup:hover {
  border-color: var(--chair-orange);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ratingDesc {
  color: var(--text-secondary);
  font-size: 0.85rem;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
  min-height: 2.5em;
}

.error {
  color: var(--error);
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.throttleMessage {
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.85rem;
  padding: 0.75rem;
  background-color: rgba(var(--chair-orange-rgb), 0.05);
  border-radius: 8px;
  border: 1px solid rgba(var(--chair-orange-rgb), 0.1);
}

/* Review List Styles */
.reviewList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reviewItem {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #f8f9fa;
}

.reviewScores {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.score {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.scoreLabel {
  font-weight: 600;
  color: var(--text-primary);
}

.reviewText {
  color: var(--text-primary);
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 0.75rem 0;
}

.reviewMeta {
  display: flex;
  justify-content: space-between;
  color: var(--text-secondary);
  font-size: 0.85rem;
  margin-top: 0.5rem;
}

.description {
  text-align: center;
  color: var(--text-secondary);
  font-style: italic;
  padding: 2rem;
}

@media (max-width: 640px) {
  .dialog {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  }

  .buttonGroup {
    flex-direction: column;
  }

  .reviewScores {
    grid-template-columns: 1fr;
  }
}

/* Align with ReportModal error styling */
.errorView {
  padding: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.errorHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.errorHeader h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.errorIcon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--error);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: 0 2px 8px rgba(var(--error-rgb), 0.25);
}

.errorMessage {
  color: var(--text-primary);
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  max-width: 400px;
}

/* Remove old error styles that don't match ReportModal */
.errorContent {
  display: none;
}

/* Keep existing responsive styles */
@media (max-width: 480px) {
  .errorView {
    padding: 2rem 1.5rem;
  }

  .errorHeader h3 {
    font-size: 1.25rem;
  }

  .errorIcon {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }

  .errorMessage {
    font-size: 0.95rem;
  }
}
