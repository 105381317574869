.statusBar {
  width: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.expirySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 600px;
}

.expiryInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  position: relative;
  padding-right: 2rem;
  width: 100%;
  justify-content: center;
}

.expiryInfo.normal {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.expiryInfo.warning {
  background-color: rgba(255, 166, 0, 0.15);
  color: orange;
}

.expiryInfo.urgent {
  background-color: rgba(255, 0, 0, 0.15);
  color: #ff6b6b;
}

.expiryInfo.expired {
  background-color: rgba(255, 0, 0, 0.15);
  color: #ff4444;
}

.expiryInfo svg {
  width: 16px;
  height: 16px;
}

.expiryHint {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-style: italic;
}

.infoIconWrapper {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: help;
  opacity: 0.8;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoIconWrapper:hover {
  opacity: 1;
}

.infoIcon {
  width: 16px;
  height: 16px;
}

.statusMessage {
  text-align: center;
  line-height: 1.5;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  width: 100%;
  max-width: 600px;
}

.link {
  color: var(--chair-orange);
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease;
  font-weight: 600;
}

.link:hover {
  text-decoration: underline;
}

.tooltip {
  background-color: #333;
  color: white;
  border-radius: 6px;
  padding: 12px;
  font-size: 0.875rem;
  max-width: 300px;
  z-index: 1000;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.tooltipContent {
  padding: 8px;
  max-width: 250px;
}

.tooltipContent p {
  margin-bottom: 8px;
  font-weight: 500;
  color: white;
}

.tooltipContent ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.tooltipContent li {
  margin: 4px 0;
  padding-left: 20px;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.4;
}

.tooltipContent li:before {
  content: "•";
  position: absolute;
  left: 8px;
  color: var(--chair-orange);
}

/* Copy over the relevant styles from Match.module.css and Reachout.module.css */
/* ... */

.blockedStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: rgba(var(--error-rgb), 0.1);
  color: var(--error);
  padding: 0.75rem;
  font-size: 0.9rem;
  width: 100%;
  max-width: 600px;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.blockedStatus svg {
  font-size: 1.1rem;
  flex-shrink: 0;
}
