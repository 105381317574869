.betaCalloutSection {
  padding: 6rem 2rem;
  background: linear-gradient(
    to bottom,
    var(--background-dark),
    rgba(0, 0, 0, 0.95)
  );
}

.calloutContainer {
  max-width: 800px;
  margin: 0 auto;
}

.betaCallout {
  padding: 3rem;
  background: rgba(254, 208, 48, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(254, 208, 48, 0.2);
}

.betaCallout h2 {
  font-size: 2.2rem;
  color: var(--chair-orange);
  margin-bottom: 1.5rem;
  text-align: center;
}

.betaCallout p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-size: 1.05rem;
  margin-bottom: 1.5rem;
}

.feedbackEmail {
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  text-align: center;
}

.feedbackEmail p {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.emailLink {
  color: var(--chair-orange);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

.emailLink:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.roadmap {
  margin: 2rem 0;
}

.roadmap h3 {
  font-size: 1.4rem;
  color: var(--chair-orange);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.roadmap h3:after {
  content: "🏗️";
  margin-left: 0.5rem;
}

.roadmap ul {
  list-style-type: none;
  padding: 0;
}

.roadmap li {
  color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.roadmap li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: var(--chair-orange);
}

.joinButton {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background: var(--chair-orange);
  color: #000;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.joinButton:hover {
  background: #ffd700;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(254, 208, 48, 0.3);
}

@media (max-width: 768px) {
  .betaCalloutSection {
    padding: 4rem 1.5rem;
  }

  .betaCallout {
    padding: 2rem;
  }

  .betaCallout h2 {
    font-size: 1.8rem;
  }

  .betaCallout p {
    font-size: 1rem;
  }

  .roadmap h3 {
    font-size: 1.2rem;
  }
}

.socialLinks {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.discordLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem 1.5rem;
  background: #5865f2;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.discordLink:hover {
  background: #4752c4;
  transform: translateY(-2px);
}

.otherSocials {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.socialIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: var(--chair-orange);
  transition: all 0.3s ease;
}

.socialIcon:hover {
  background: rgba(254, 208, 48, 0.2);
  transform: translateY(-2px);
}
