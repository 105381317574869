.overlay {
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  z-index: var(--overlay-z-index);
}

.modal {
  background-color: white;
  color: black;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
  z-index: 1000;
}

.header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: var(--chair-orange);
}

.content {
  margin-bottom: 24px;
  font-size: 1rem;
  line-height: 1.5;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
}

.button {
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
}

.cancelButton {
  color: white;
}

.deleteButton {
  color: white;
}
