.settingsContainer {
  padding: 24px;
  height: 100%;
  overflow: auto;
}

.pageTitle {
  margin-bottom: 24px;
  font-size: 1.8rem;
  color: var(--dark-brown);
}

.settingsSection {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.settingsSection h2 {
  margin: 0 0 16px 0;
  font-size: 1.2rem;
  color: var(--dark-brown);
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
}

.settingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #f5f5f5;
}

.settingItem:last-child {
  border-bottom: none;
}

.settingInfo {
  flex: 1;
}

.settingInfo h3 {
  margin: 0 0 4px 0;
  font-size: 1rem;
  font-weight: 500;
}

.settingInfo p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.settingControl {
  margin-left: 20px;
}

.settingControl input[type="number"] {
  width: 80px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--dark-brown);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--dark-brown);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
