.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--navbar-height));
  padding: 2rem;
  background: var(--surface-secondary);
}

.container {
  max-width: 600px;
  width: 100%;
  padding: 2.5rem;
  background: var(--surface-primary);
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 12px rgba(var(--navbar-brown), 0.1);
}

.header {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 2.25rem;
  color: var(--text-primary);
  font-weight: 600;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Warning Section */
.warningSection {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.25rem;
  background: var(--surface-warning);
  border: 1px solid var(--border-warning);
  border-radius: 12px;
  margin-bottom: 2rem;
  text-align: left;
}

.warningIcon {
  font-size: 1.5rem;
  color: var(--text-warning);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.warningTitle {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.warningText {
  color: var(--text-secondary);
}

/* Info Section */
.infoSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0;
}

.infoItem {
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--surface-secondary);
  border: 1px solid var(--border-default);
  border-radius: 12px;
  text-align: left;
}

.infoIcon {
  font-size: 1.5rem;
  color: var(--text-secondary);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.infoItem h3 {
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  color: var(--text-primary);
  font-weight: 600;
}

.infoDate {
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.reason {
  color: var(--text-secondary);
  margin-bottom: 0.75rem;
}

.type {
  font-size: 0.9rem;
  color: var(--text-tertiary);
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.typeIcon {
  font-size: 1rem;
}

.deadline {
  color: var(--chair-orange);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.note {
  font-size: 0.9rem;
  color: var(--text-tertiary);
  font-style: italic;
}

/* Status-specific styles */
.deleted {
  background: var(--surface-secondary);
  border: 1px solid var(--border-default);
}

.deleted .statusIcon,
.deleted .infoIcon {
  color: var(--text-secondary);
}

/* Actions */
.actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 2rem;
}

.reactivateButton,
.cancelButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.reactivateButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--chair-orange);
  color: var(--text-inverse);
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.reactivateButton:hover {
  background-color: var(--chair-orange-dark);
  transform: translateY(-1px);
}

.cancelButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  padding: 0.75rem 1.5rem;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  color: white;
}

.loadingContainer {
  color: white;
  text-align: center;
  padding: 2rem;
}

.registrationProgress {
  margin-bottom: 2.5rem;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

.statusGrid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.statusCard {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  padding: 0.5rem;
  background: var(--surface-secondary);
  border-radius: 12px;
  border: 1px solid var(--border-default);
  transition: all 0.2s ease;
  width: 100%;
  gap: 0.5rem;
}

/* Top left cell - Step number */
.statusHeader {
  grid-column: 1;
  grid-row: 1;
}

.stepNumber {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-primary);
  padding: 0.25rem 0.75rem;
  background: transparent;
  border: 1px solid var(--border-default);
  border-radius: 999px;
}

/* Top right cell - Label */
.statusLabel {
  grid-column: 2;
  grid-row: 1;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  /* padding-bottom: 1rem; */
  text-align: start;
  border-bottom: 1px dotted var(--border-default);
}

/* Bottom left cell - Icon */
.statusIcon {
  grid-column: 1;
  grid-row: 2;
  font-size: 1.5rem;
  color: var(--text-secondary);
  justify-self: center;
  align-self: center;
}

/* Bottom right cell - Content */
.statusContent {
  display: flex;
  align-items: center;

  flex-wrap: wrap;
}

.statusMessage {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin: 0;
  text-align: start;

  min-width: 200px;
}

.statusAction {
  align-self: flex-start;
}

.actionButton {
  min-width: 140px;
  justify-content: center;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  margin: 0.5rem;
}

/* Status-specific styles */
.complete .statusIcon {
  color: var(--success);
}

.incomplete:not(.disabled) .statusIcon {
  color: var(--warning);
}

.disabled {
  opacity: 0.6;
  background: var(--surface-disabled);
  border-color: var(--border-disabled);
}

.disabled .statusIcon {
  color: var(--text-disabled);
}

/* Grid container */
.statusGrid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Add styles for disabled state */
.statusCard.disabled {
  opacity: 0.6;
  background: var(--surface-disabled, #f5f5f5);
  border-color: var(--border-disabled, #e0e0e0);
  cursor: not-allowed;
}

.disabled .statusIcon {
  color: var(--text-disabled, #9e9e9e);
}

.disabled .statusAction {
  display: none;
}

/* Update complete/incomplete states */
.statusCard.complete {
  background: var(--surface-success-subtle);
  border-color: var(--success);
}

.statusCard.incomplete:not(.disabled) {
  background: var(--surface-warning-subtle);
  border-color: var(--warning);
}

/* Add these new CSS variables if not already defined */
:root {
  --surface-success-subtle: rgba(0, 200, 83, 0.1);
  --surface-warning-subtle: rgba(255, 171, 0, 0.1);
  --success: #00c853;
  --warning: #ffab00;
  --surface-disabled: #f5f5f5;
  --border-disabled: #e0e0e0;
  --text-disabled: #9e9e9e;
  --surface-tertiary: rgba(0, 0, 0, 0.05);
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.closeButton:hover {
  color: var(--text-primary);
}

/* Add these styles to your AccountStatus.module.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}

.overlayMode {
  background: transparent;
  min-height: auto;
  padding: 0;
}

.overlayMode .container {
  margin: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .overlayMode .container {
    border-radius: 0;
    min-height: 100vh;
    max-width: 100%;
    box-shadow: none;
  }

  .container {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.75rem;
  }
}
