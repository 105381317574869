.termsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

.termsHeader {
  font-size: 2rem;
  font-weight: bold;
  color: var(--chair-orange);
  margin-bottom: 16px;
  text-align: center;
}

.termsSubheader {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 32px;
  text-align: center;
  line-height: 1.5;
  max-width: 600px;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 24px 0;
  width: 100%;
  max-width: 600px;
}

.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background-color: #f8f8f8;
  transition: background-color 0.2s ease;
}

.checkboxWrapper:hover {
  background-color: #f0f0f0;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  accent-color: var(--chair-orange);
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 2px solid var(--chair-orange);
  background-color: white;
}

.checkbox:checked {
  background-color: var(--chair-orange);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox:hover {
  filter: brightness(0.95);
}

.checkbox:focus {
  outline: 2px solid rgba(var(--chair-orange-rgb), 0.5);
  outline-offset: 2px;
}

.checkboxLabel {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #444;
}

.checkboxLabel a {
  color: var(--chair-orange);
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

.checkboxLabel a:hover {
  text-decoration: underline;
}

.acceptButton {
  padding: 16px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  flex-grow: 1;
  max-width: 400px;
}

.acceptButton:disabled {
  background-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
}

.acceptButton:not(:disabled) {
  background-color: #4caf50;
  color: white;
}

.acceptButton:not(:disabled):hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-top: 24px;
  gap: 16px;
}

.backButton {
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 16px 24px;
  min-width: 100px;
}

.backButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.errorMessage {
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
  border: 1px solid rgba(255, 107, 107, 0.3);
  padding: 12px 16px;
  border-radius: 6px;
  margin-top: 16px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.loading {
  position: relative;
  color: transparent !important;
}

.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .termsHeader {
    font-size: 1.75rem;
  }

  .termsSubheader {
    font-size: 1rem;
  }

  .checkboxLabel {
    font-size: 1rem;
  }

  .acceptButton {
    padding: 14px;
    font-size: 1rem;
  }

  .buttonContainer {
    flex-direction: column-reverse;
  }

  .backButton {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .termsHeader {
    font-size: 1.5rem;
  }

  .checkboxWrapper {
    padding: 12px;
  }
}
