/* Main container styles */
.pageContainer {
  min-height: calc(100vh - var(--navbar-height));
  background: var(--surface-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  margin: 0 auto;
}

.content {
  background: var(--surface-primary);
  padding: 2rem;
  width: 100%;
}

/* Status section styles */
.statusSection {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.statusSection .statusIcon,
.statusSection .errorIcon,
.statusSection .infoIcon {
  font-size: 2.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.statusSection .statusIcon {
  color: var(--color-success);
}

.statusSection .errorIcon {
  color: var(--color-error);
}

.statusSection .infoIcon {
  color: var(--color-info);
}

.statusSection .statusTitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.statusSection .statusText {
  color: var(--text-secondary);
  line-height: 1.5;
}

/* Info section styles */
.infoSection {
  margin-bottom: 2rem;
}

.infoSection .infoItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: var(--surface-secondary);
  border-radius: 8px;
}

.infoSection .infoIcon {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: var(--color-info);
  flex-shrink: 0;
}

.infoSection h3 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.infoSection p {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Action buttons */
.actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actions .primaryButton {
  width: 100%;
}

.actions .secondaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* Loading state */
.loadingSpinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: var(--color-primary);
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .content {
    padding: 1.5rem;
  }

  .statusSection {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .statusSection .statusIcon,
  .statusSection .errorIcon,
  .statusSection .infoIcon {
    margin-right: 0;
    margin-bottom: 1rem;
    font-size: 3rem;
  }

  .infoSection .infoItem {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .infoSection .infoIcon {
    margin-right: 0;
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
}

.statusDetail {
  font-size: 0.85rem;
  color: var(--text-tertiary);
  margin-top: 8px;
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  border-radius: 4px;
  word-break: break-all;
}
