.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.dialog {
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  animation: dialogEnter 0.2s ease-out;
  z-index: 1001;
  position: relative;
}

@keyframes dialogEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--chair-orange);
}

.anonymousHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.anonymousIcon {
  color: var(--chair-orange);
  font-size: 1.5rem;
}

.closeButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #333;
}

.content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* User Info Section */
.userInfoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.userButton {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  background-color: white;
  color: var(--text-primary);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: default;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.userMetadata {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  width: 100%;
}

.metadataItem {
  background-color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  font-size: 0.9rem;
  color: var(--text-secondary);
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
}

.badge.valid {
  background-color: rgba(34, 197, 94, 0.1);
  color: var(--green);
}

.badge.invalid {
  background-color: rgba(239, 68, 68, 0.1);
  color: var(--red);
}

.badge.warning {
  background-color: rgba(237, 125, 49, 0.1);
  color: #b25e09;
}

.badge svg {
  width: 16px;
  height: 16px;
}

/* Score Section */
.scoreSection {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.scoreSection h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 1rem;
}

.scores {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
}

.scoreItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;

  label {
    color: #666;
    font-size: 0.9rem;
  }
}

.scoreHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.scoreDescription {
  margin-top: 4px;
  font-size: 0.85rem;
  color: #666;
  font-style: italic;
  line-height: 1.4;
  padding: 0 4px;
}

.scoreValue {
  display: flex;
  align-items: baseline;
  gap: 4px;
  font-weight: 500;
  color: var(--chair-orange);
}

.maxScore {
  font-size: 0.8rem;
  color: #999;
}

.noReviews .scoreValue {
  opacity: 0.5;
}

.noReviewsMessage {
  margin-top: 16px;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

.scores.noReviews .scoreItem {
  opacity: 0.7;
  background-color: #f8f9fa;
}

.statusSection {
  margin-bottom: 1.5rem;
}

.statusSection h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 0.75rem;
}

.statusItem {
  margin-bottom: 0.5rem;
}

.statusBadge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  background: #f8f9fa;
}

.statusBadge svg {
  font-size: 1.2rem;
}

.statusBadge.valid {
  color: var(--success);
}

.statusBadge.invalid {
  color: var(--error);
}

.statusBadge.warning {
  color: #b25e09;
}

@media (max-width: 640px) {
  .dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }

  .content {
    padding: 1rem;
  }
}
