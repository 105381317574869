@layer variables {
  :root {
    /* Base color palette */
    --tag-default: #f9f5d7;
    --tag-suggested: #f9f5d7;
    --tag-selected: #f6e05e;
    --navbar-brown: 57, 45, 40;
    --dark-brown: #2b2321;
    --bg-brown: #433632;
    --brown: #3e312c;
    --chair-orange: #ed7d31;
    --chair-orange-rgb: 237, 125, 49;
    --chair-orange-dark: #d65f13;
    --green: #4caf50;
    --error: #ef5350;
    --graphite: #373737;
    --brick: #af4b48;
    --ricepaper: rgba(0, 0, 0, 0.1);
    --ricepaper-white: rgba(255, 255, 255, 0.1);

    /* Additional colors based on the existing palette */
    --light-brown: #5a4a42;
    --pale-orange: #f4a460;
    --muted-green: #8fbc8f;
    --soft-red: #cd5c5c;
    --dark-graphite: #2e2e2e;
    --light-brick: #c26b68;
    --deep-orange: #d35400;
    --warm-yellow: #f4d03f;
    --warm-yellow-rgb: 244, 208, 63;
    --cool-blue: #5dade2;
    --rich-purple: #8e44ad;

    /* Call-related colors */
    --call-success: var(--muted-green);
    --call-success-dark: #689f38;
    --call-success-bg: rgba(143, 188, 143, 0.1);
    --call-error: var(--soft-red);
    --call-error-dark: #c0392b;
    --call-error-bg: rgba(205, 92, 92, 0.1);
    --call-status-bg: rgba(43, 35, 33, 0.9);
    --call-text-primary: #ffffff;
    --call-text-secondary: rgba(255, 255, 255, 0.7);
    --call-button-hover: rgba(0, 0, 0, 0.05);
    --call-duration-bg: rgba(0, 0, 0, 0.2);

    /* Status-related colors */
    --status-active: var(--green);
    --status-active-rgb: 76, 175, 80;
    --status-suspended: var(--warm-yellow);
    --status-suspended-rgb: 244, 208, 63;
    --status-banned: var(--soft-red);
    --status-banned-rgb: 205, 92, 92;
    --status-deleted: var(--graphite);
    --status-deleted-rgb: 55, 55, 55;

    /* Text colors */
    --text-primary: var(--dark-brown);
    --text-secondary: var(--light-brown);
    --text-tertiary: var(--graphite);
    --text-warning: var(--warm-yellow);
    --text-danger: var(--soft-red);
    --text-success: var(--muted-green);

    /* Surface colors */
    --surface-background: var(--white);
    --surface-background-alt: var(--ricepaper);
    --surface-warning: rgba(var(--warm-yellow-rgb), 0.1);
    --surface-danger: rgba(var(--soft-red), 0.1);
    --surface-success: rgba(var(--status-active-rgb), 0.1);

    /* Border colors */
    --border-warning: var(--warm-yellow);
    --border-danger: var(--soft-red);
    --border-success: var(--green);
    --border-default: var(--ricepaper-white);

    /* Additional semantic colors for new components */
    --surface-primary: #ffffff;
    --surface-secondary: var(--ricepaper);
    --surface-tertiary: rgba(0, 0, 0, 0.15);
    --surface-warning: rgba(var(--warm-yellow-rgb), 0.1);
    --surface-danger: rgba(var(--soft-red), 0.1);
    --surface-success: rgba(var(--muted-green), 0.1);

    /* Text colors */
    --text-primary: var(--dark-brown);
    --text-secondary: var(--light-brown);
    --text-tertiary: var(--graphite);
    --text-warning: var(--warm-yellow);
    --text-danger: var(--soft-red);
    --text-success: var(--muted-green);
    --text-inverse: #ffffff;

    /* Status colors */
    --status-active: var(--green);
    --status-active-rgb: 76, 175, 80;
    --status-suspended: var(--warm-yellow);
    --status-suspended-rgb: 244, 208, 63;
    --status-banned: var(--soft-red);
    --status-banned-rgb: 205, 92, 92;
    --status-deleted: var(--graphite);
    --status-deleted-rgb: 55, 55, 55;

    /* Border colors */
    --border-warning: var(--warm-yellow);
    --border-danger: var(--soft-red);
    --border-success: var(--green);
    --border-default: var(--ricepaper-white);
  }
}

@layer theme {
  :root {
    /* Semantic color tokens */
    --text-primary: var(--dark-brown);
    --text-secondary: var(--light-brown);
    --surface-primary: #ffffff;
    /* ... other theme variables ... */
  }
}
