.container {
  min-height: 100vh;
  width: 100%;
  /* background-color: #ffffff; */
}

.hero {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.content {
  text-align: center;
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 2rem;
}

.subtitleContainer {
  font-size: 1.5rem;
  color: white;
}

.dynamicText {
  height: 1.5em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typedText {
  color: var(--chair-orange);
}

.illustration {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
}

.figure {
  width: 120px;
  height: auto;
  opacity: 0;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  filter: var(white, brightness(0.2));
}

.leftFigure {
  transform: translateX(-100vw);
}

.rightFigure {
  transform: translateX(100vw);
}

.connectingLine {
  flex: 1;
  height: 2px;
  background-color: var(--chair-orange);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.figure.visible {
  opacity: 1;
  transform: translateX(0);
}

.connectingLine.visible {
  transform: scaleX(1);
}

.cta {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.primaryButton {
  padding: 1rem 2rem;
  background-color: var(--chair-orange);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.secondaryButton {
  padding: 1rem 2rem;
  background-color: transparent;
  color: var(--chair-orange);
  border: 2px solid var(--chair-orange);
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primaryButton:hover {
  background-color: rgba(var(--chair-orange-rgb), 0.8);
}

.secondaryButton:hover {
  background-color: rgba(var(--chair-orange-rgb), 0.1);
}

@media (max-width: 768px) {
  .hero {
    padding: 2rem 1rem;
    gap: 2rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitleContainer {
    font-size: 1.2rem;
  }

  .figure {
    width: 80px;
  }

  .cta {
    flex-direction: column;
    width: 100%;
  }

  .primaryButton,
  .secondaryButton {
    width: 100%;
  }
}

.features {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  background-color: var(--background-dark);
}

.featureCards {
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 0 auto;
}

.featureCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.featureCard:hover {
  transform: translateY(-5px);
}

.featureCard h3 {
  color: var(--chair-orange);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.featureCard p {
  color: white;
  font-size: 1.1rem;
  line-height: 1.6;
}

@media (max-width: 968px) {
  .featureCards {
    grid-template-columns: 1fr;
    max-width: 600px;
  }

  .featureCard {
    text-align: center;
  }
}

/* Section Header Styles */
.sectionHeader {
  text-align: center;
  margin-bottom: 4rem;
}

.sectionTitle {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.sectionSubtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

/* FAQ Styles - Make sure these are present */
.faq {
  padding: 6rem 2rem;
  background-color: var(--background-dark);
}

.faqContainer {
  max-width: 800px;
  margin: 0 auto;
}

.faqItem {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.5rem;
}

.faqQuestion {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.faqQuestion h3 {
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.faqIcon {
  color: var(--chair-orange);
  font-size: 1.5rem;
  font-weight: 300;
  transition: transform 0.3s ease;
}

.faqAnswer {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 0 1.5rem;
}

.faqAnswer p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 0;
  padding-bottom: 1.5rem;
}

.faqAnswer.active {
  max-height: 300px; /* Adjust based on content */
}

.faqItem:hover {
  background: rgba(255, 255, 255, 0.08);
}

/* When active, rotate the + icon to make an x */
.faqItem .faqIcon {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.faqItem .faqAnswer.active ~ .faqQuestion .faqIcon {
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .faq {
    padding: 4rem 1rem;
  }

  .faqQuestion h3 {
    font-size: 1rem;
  }

  .sectionTitle {
    font-size: 2rem;
  }

  .sectionSubtitle {
    font-size: 1rem;
    padding: 0 1rem;
  }
}

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* Optional: Add a transition for the section changes */
.container > section {
  transition: opacity 0.5s ease;
}

.iconContainer {
  /* width: 120px; */
  height: 120px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featureIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.faqCategory {
  margin-bottom: 3rem;
}

.faqCategoryTitle {
  color: var(--chair-orange);
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.faqQuestion h4 {
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.story {
  padding: 6rem 2rem;
  background: linear-gradient(
    to bottom,
    var(--background-dark),
    rgba(0, 0, 0, 0.95)
  );
}

.storyContent {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

.stats {
  padding: 4rem 2rem;
  background: rgba(255, 255, 255, 0.02);
}

.statsContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  padding: 2rem;
}

.statItem {
  text-align: center;
  min-width: 180px;
  cursor: default;
  padding: 1.5rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.statItem:hover {
  transform: translateY(-4px);
  background: rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.statNumber {
  display: block;
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--chair-orange);
  margin-bottom: 0.75rem;
  line-height: 1;
  background: linear-gradient(45deg, var(--chair-orange), #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(254, 208, 48, 0.2);
}

.statLabel {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.betaNote {
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 3rem;
  font-size: 1rem;
  font-style: italic;
}

.betaCallout {
  padding: 6rem 2rem;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.95),
    var(--background-dark)
  );
}

.betaCallout h2 {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.betaCallout p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.roadmap {
  max-width: 600px;
  margin: 3rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.roadmap h3 {
  color: var(--chair-orange);
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
}

.roadmap ul {
  list-style: none;
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.roadmap li {
  margin: 0;
  font-size: 1.1rem;
}

/* Add hover effect for the button */
.betaCallout .primaryButton {
  margin-top: 2rem;
  font-size: 1.1rem;
  padding: 1rem 2.5rem;
}

@media (max-width: 768px) {
  .betaCallout {
    padding: 4rem 1rem;
  }

  .betaCallout h2 {
    font-size: 2rem;
  }

  .betaCallout p {
    font-size: 1.1rem;
    padding: 0 1rem;
  }

  .roadmap {
    margin: 2rem auto;
    padding: 1.5rem;
  }

  .roadmap h3 {
    font-size: 1.2rem;
  }

  .roadmap li {
    font-size: 1rem;
  }
}

.feedbackForm {
  max-width: 600px;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.feedbackForm form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.feedbackInput {
  width: 100%;
  padding: 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.feedbackTextarea {
  width: 100%;
  padding: 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  min-height: 140px;
  resize: vertical;
  transition: all 0.3s ease;
  font-family: inherit;
  line-height: 1.5;
}

.feedbackInput::placeholder,
.feedbackTextarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.feedbackInput:focus,
.feedbackTextarea:focus {
  outline: none;
  border-color: var(--chair-orange);
  background: rgba(255, 255, 255, 0.08);
}

.feedbackForm .primaryButton {
  align-self: flex-end;
  min-width: 150px;
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .feedbackForm {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  .formGroup {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .feedbackForm .primaryButton {
    align-self: stretch;
    width: 100%;
  }
}

.feedbackEmail {
  margin: 2rem auto;
  padding: 2rem;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.feedbackEmail p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.emailLink {
  color: var(--chair-orange);
  font-size: 1.2rem;
  text-decoration: none;
  transition: opacity 0.3s ease;
  font-weight: 500;
}

.emailLink:hover {
  opacity: 0.8;
}

/* Update existing media query */
@media (max-width: 768px) {
  .feedbackEmail {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  .emailLink {
    font-size: 1.1rem;
  }

  /* ... existing mobile styles ... */
}

/* How It Works Section */
.howItWorks {
  padding: 6rem 2rem;
  background: linear-gradient(
    to bottom,
    var(--background-dark),
    rgba(0, 0, 0, 0.95)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.stepsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.step {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.step:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.registerStep,
.trustStep {
  grid-column: span 2;
}

.stepNumber {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 40px;
  height: 40px;
  background: var(--chair-orange);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
}

.step h3 {
  color: var(--chair-orange);
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.step p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  font-size: 1rem;
  flex-grow: 1;
}

/* Step Stats */
.stepStats {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px dotted rgba(255, 255, 255, 0.1);
  flex-wrap: wrap;
  gap: 1rem;
}

.stepStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  min-width: 80px;
}

.stepStatNumber {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--chair-orange);
  margin-bottom: 0.25rem;
  background: linear-gradient(45deg, var(--chair-orange), #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stepStatLabel {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Responsive layout using media queries */
@media (max-width: 1200px) {
  .stepsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .howItWorks {
    padding: 4rem 1rem;
  }

  .stepsGrid {
    grid-template-columns: 1fr;
    max-width: 500px;
  }

  .step {
    padding: 1.5rem;
  }
}

.trustSection {
  max-width: 800px;
  margin: 4rem auto 0;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.trustSection h3 {
  color: var(--chair-orange);
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.trustSection p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  font-size: 1.1rem;
}

/* Responsive layout using media queries */
@media (max-width: 1200px) {
  .stepsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .howItWorks {
    padding: 4rem 1rem;
  }

  .stepsContainer {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .step {
    padding: 1.5rem;
  }

  .trustSection {
    margin: 3rem 1rem 0;
    padding: 1.5rem;
  }

  .stepStatNumber {
    font-size: 1.5rem;
  }

  .stepStatLabel {
    font-size: 0.7rem;
  }
}
