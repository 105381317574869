.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(57, 45, 40, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.modal {
  background: var(--dark-brown);
  border: 1px solid var(--ricepaper-white);
  border-radius: 16px;
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
}

.spinner {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.spinnerInner {
  width: 48px;
  height: 48px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: var(--chair-orange);
  border-radius: 50%;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--tag-default);
  margin: 0;
}

.description {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.stats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 0.875rem;
  color: var(--tag-default);
  opacity: 0.9;
}

.statItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.statIcon {
  opacity: 0.8;
}

.statDivider {
  opacity: 0.5;
}

/* Base button styles */
.button {
  padding: 0.875rem 1.25rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Primary button style (like the back button) */
.primaryButton {
  composes: button;
  background: var(--chair-orange);
  color: var(--tag-default);
}

.primaryButton:hover {
  background: var(--chair-orange-dark);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.primaryButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Extended options container */
.extendedOptions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

/* All extended option buttons */
.retryButton,
.browseButton,
.therapistButton,
.cancelButton {
  composes: primaryButton;
}

/* Disabled button */
.therapistButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: var(--chair-orange);
  transform: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .modal {
    padding: 1.5rem;
  }

  .spinner {
    width: 48px;
    height: 48px;
  }

  .spinnerInner {
    width: 36px;
    height: 36px;
  }

  .title {
    font-size: 1.25rem;
  }

  .button {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }
}
