.badgeSection {
  margin: 24px 0;
  padding: 16px;
  background-color: var(--ricepaper);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.badgeSection h3 {
  margin-bottom: 16px;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badgeGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .badgeGrid {
    gap: 12px;
  }
}

.badgeCount {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 0.8rem;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 8px;
}

.showMoreButton {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  display: block;
  margin: 0 auto;
}

.showMoreButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.loadingBadges,
.badgeError,
.noBadges {
  padding: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
}

.badgeError {
  color: #ff6b6b;
}

@media (min-width: 1024px) {
  .badgeSection {
    order: 3;
  }
}
