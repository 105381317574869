/* Overlay styles */
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  backdrop-filter: blur(4px);
  animation: fadeIn 0.2s ease-out;
}

/* Modal container */
.floatingModal {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 0;
  position: relative;
  animation: slideIn 0.3s ease-out;
}

/* Modal header */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #eaeaea;
  background-color: #f8f9fa;
  border-radius: 8px 8px 0 0;
}

.modalHeader h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

/* Close button */
.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.closeButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}

.closeButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Resource identifier */
.resourceIdentifier {
  padding: 8px 24px;
  background-color: #f0f0f0;
  color: #666;
  font-size: 0.85rem;
  font-family: monospace;
}

/* Resource details */
.resourceDetails {
  padding: 16px 24px;
  border-bottom: 1px solid #eaeaea;
  background-color: #fafafa;
}

.detailRow {
  display: flex;
  margin-bottom: 8px;
}

.detailRow:last-child {
  margin-bottom: 0;
}

.detailLabel {
  font-weight: 600;
  width: 120px;
  color: #555;
}

.detailValue {
  flex: 1;
}

/* Status indicators */
.status-active {
  color: #28a745;
  font-weight: 600;
}

.status-suspended {
  color: #fd7e14;
  font-weight: 600;
}

.status-banned {
  color: #dc3545;
  font-weight: 600;
}

.status-deleted {
  color: #6c757d;
  font-weight: 600;
}

.status-pending {
  color: #17a2b8;
  font-weight: 600;
}

.status-resolved {
  color: #28a745;
  font-weight: 600;
}

.status-dismissed {
  color: #6c757d;
  font-weight: 600;
}

/* Modal content */
.modalContent {
  padding: 24px;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup:last-child {
  margin-bottom: 0;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.formGroup input[type="number"],
.formGroup select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 8px;
}

.formGroup textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
}

.warningText {
  margin-top: 12px;
  padding: 12px;
  background-color: #fff3cd;
  border-left: 4px solid #ffc107;
  color: #856404;
  border-radius: 0 4px 4px 0;
}

/* Modal actions */
.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .floatingModal {
    width: 95%;
    max-height: 95vh;
  }

  .detailRow {
    flex-direction: column;
    margin-bottom: 12px;
  }

  .detailLabel {
    width: 100%;
    margin-bottom: 4px;
  }
}

/* Add these styles to your existing ActionModals.module.css */

.confirmInput {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 8px;
}

.confirmInput:focus {
  border-color: #dc3545;
  outline: none;
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.25);
}

/* Enhanced warning text for hard delete */
.warningText.danger {
  background-color: #ffebee;
  border-left: 4px solid #f44336;
  color: #b71c1c;
}
