.footer {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 2rem 1rem;
  margin-top: auto; /* Pushes footer to bottom */
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

/* Social Media Section */
.social {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.socialLink {
  color: white;
  opacity: 0.8;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialLink:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.socialLink svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Navigation Links */
.links ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 0;
  margin: 0;
  list-style: none;
}

.links li {
  position: relative;
  padding: 0 1rem;
}

/* Add separator between links */
.links li:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1rem;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.links a {
  color: white;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.links a:hover {
  opacity: 1;
  color: var(--chair-orange);
}

/* Copyright Section */
.copyright {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 1rem;
  }

  .footerContent {
    gap: 1.5rem;
  }

  .social {
    gap: 1.25rem;
  }

  .links ul {
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .links li {
    padding: 0;
  }

  /* Remove separators on mobile */
  .links li:not(:last-child)::after {
    display: none;
  }

  .links a {
    font-size: 1rem; /* Slightly larger for better touch targets */
    padding: 0.5rem;
    display: block;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .footer {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
