.inputContainer {
  position: relative;
  display: grid;
  border: 2px solid #ccc;
  border-radius: 20px; /* More rounded corners */
  padding: 0.5rem;
  grid-template-columns: auto 1fr auto;
  transition: all 0.2s ease-in-out;
}

.inputContainer:hover {
  border-color: var(--chair-orange); /* Highlight border when focused */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

.icon {
  height: 100%;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  line-height: 1rem;
  color: #ccc;
}

.input {
  border: none;
  font-size: 1rem;
  transition: border 0.2s ease-in-out;
  -webkit-appearance: none;
  outline: none;
  background-color: transparent;
  color: white;
  width: 100%;
  height: 100%; /* Ensure the input takes the full height of the container */
}

.clear-btn {
  background: none;
  color: #777;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1rem;

  height: 100%; /* Ensure the button takes the full height of the container */
}

.clear-btn:focus,
.clear-btn:hover {
  color: white;
}

.focused {
  border-color: var(--chair-orange); /* Highlight border when focused */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
