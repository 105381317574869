/* Base container */
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgb(var(--navbar-brown)),
    rgba(var(--navbar-brown), 0.7)
  );
  overflow-y: auto;
  padding: 2rem;
  color: white;
}

.containerMobile {
  padding: 1rem;
}

/* Content wrapper */
.contentWrapper {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

/* Search container */
.searchContainer {
  position: relative;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  min-height: auto;
  margin-bottom: 0.75rem;
}

.mobile.searchContainer {
  padding: 1rem 0;
}

.extraSmall.searchContainer {
  padding: 0.5rem 0;
}

/* Search wrapper */
.searchWrapper {
  position: relative;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.mobile .searchWrapper {
  margin-bottom: 0.5rem;
  padding: 0;
}

/* Search input */
.searchInput {
  width: 100%;
  min-height: 60px;
  padding: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: white;
  transition: all 0.3s ease;
  resize: none;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Add hover state with chair-orange border */
.searchInput:hover {
  border-color: var(--chair-orange);
}

/* Add focus state with deeper chair-orange border */
.searchInput:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 4px 12px rgba(var(--chair-orange-rgb), 0.2);
}

.mobile .searchInput {
  font-size: 1.2rem;
  padding: 1.25rem;
  min-height: 50px;
  border-radius: 20px;
  /* Start with a single line height on mobile */
  height: 50px;
}

.extraSmall .searchInput {
  font-size: 1rem;
  padding: 1rem;
  min-height: 45px;
  height: 45px;
}

.mobile .searchInput::placeholder {
  font-size: 1.2rem;
}

.extraSmall .searchInput::placeholder {
  font-size: 1rem;
}

/* Clear search button */
.clearSearchButton {
  margin: 0.5rem 0 1.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--chair-orange);
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.clearButtonMobile {
  margin: 0.25rem 0 0.75rem;
  padding: 0.5rem 1.25rem;
  font-size: 0.9rem;
}

.clearButtonExtraSmall {
  margin: 0.15rem 0 0.5rem;
  padding: 0.4rem 1rem;
  font-size: 0.85rem;
}

/* Search results */
.searchResults {
  width: 100%;

  margin-top: 0.25rem;
}

.searchResults.mobile {
  margin-top: 0.15rem;
}

.searchResults.desktop {
  max-width: 1200px;
  margin: 0.5rem auto 0;
}

/* Filter toggle */
.filterToggle {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 0.5rem 0 0.75rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 24px;
}

.mobile .filterToggle {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.75rem 0 1rem;
  padding: 0.5rem;
}

.extraSmall .filterToggle {
  padding: 0.25rem;
  margin: 0.25rem 0 0.5rem;
}

/* Filter buttons */
.filterButton {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 20px;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile .filterButton {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.extraSmall .filterButton {
  padding: 0.35rem 0.65rem;
  font-size: 0.8rem;
}

/* Search title */
.searchTitle {
  font-size: 2.5rem;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 100%;
}

.mobile .searchTitle {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.extraSmall .searchTitle {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

/* Results section */
.resultsSection {
  margin-bottom: 2rem;
}

.mobile .resultsSection {
  margin-bottom: 1.5rem;
}

.extraSmall .resultsSection {
  margin-bottom: 1rem;
}

/* Results grid */
.resultsGrid {
  display: grid;
  grid-template-columns: 1fr !important;
  gap: 0.35rem;
  margin-bottom: 0.35rem;
}

/* Simpler approach for item separation */
.resultsGrid > div:not(:last-child) {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.mobile .resultsGrid > div:not(:last-child) {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.desktop .resultsGrid {
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important; */
}

/* Section headings */
.searchResults h2 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.mobile .searchResults h2 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.extraSmall .searchResults h2 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.resultsSection h3 {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  border-left: 3px solid var(--chair-orange);
}

.mobile .resultsSection h3 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

/* State-based styles */
.state-idle {
  justify-content: center;
  padding: 2rem 0;
}

.mobile.state-idle {
  min-height: 60vh;
  padding: 1rem 0;
}

.desktop.state-idle {
  min-height: 70vh;
}

.state-options {
  justify-content: flex-start;
  padding-top: 5vh;
}

.mobile.state-options {
  padding-top: 2vh;
}

.state-searching .searchTitle {
  display: none;
}

.state-searching .searchWrapper {
  max-width: 800px;
  margin: auto 0;
}

.placeholderExamples {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.placeholderExamples span {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
}

.placeholderExamples em {
  font-style: italic;
  margin-left: 1rem;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 650px;
  margin: 1rem auto 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.optionButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.optionButton:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.optionIcon {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.optionDescription {
  display: block;
  font-size: 0.9rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.5rem;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

.resultsSection + .resultsSection {
  margin-top: 2rem;
}

.filterButton.active {
  background: var(--chair-orange);
  color: white;
}

.latestSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 800px;
}

.latestExperiences,
.latestStories {
  width: 100%;
  color: white;
}

.latestExperiences h2,
.latestStories h2 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.contentGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin: 1rem 0;
}

.storyCard {
  background: var(--card-bg);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.storyTitle {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.readButton {
  background: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.viewAllLink {
  display: block;
  text-align: right;
  color: var(--chair-orange);
  text-decoration: none;
  margin-top: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.viewAllLink:hover {
  color: var(--chair-orange-dark);
}

@media (max-width: 1024px) {
  .latestSection {
    grid-template-columns: 1fr;
    max-width: 800px;
  }
}

.initialMode {
  margin-top: 0;
  transition: all 0.3s ease;
}

.centeredWithOptions {
  margin-bottom: 2rem;
}

.hasOptionsDesktop,
.hasOptionsMobile {
  justify-content: flex-start;
  min-height: auto;
  padding-top: 5vh;
}

.searchTitle {
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
}

.hasOptionsDesktop .searchTitle,
.hasOptionsMobile .searchTitle {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.initialMode .searchWrapper {
  margin-left: auto;
  margin-right: auto;
}

.searchContainer.desktopLayout {
  min-height: 60vh;
  padding: 3rem 0;
}

.searchContainer.mobileLayout {
  min-height: 50vh;
  padding: 1.5rem 0;
}

.searchContainer {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hasOptionsDesktop {
  padding-top: 5vh;
}

.hasOptionsMobile {
  padding-top: 2vh;
}

.state-options.desktop .optionsContainer {
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}

.state-options .searchTitle {
  font-size: 2rem;
  margin-bottom: 1rem;
}
