.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  color: white;
}

.notFoundTitle {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.notFoundText {
  font-size: 1.5rem;
}
