.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal {
  background: var(--background-primary);
  border-radius: 16px;
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.24);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
}

.icon {
  font-size: 3rem;
  line-height: 1;
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.message {
  color: var(--text-secondary);
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.metadata {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: var(--text-tertiary);
}

.actions {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.button {
  flex: 1;
  padding: 0.875rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}

.button:hover {
  opacity: 0.9;
}

.primaryButton {
  background: var(--primary-color);
  color: white;
}

.secondaryButton {
  background: var(--background-secondary);
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .modal {
    padding: 1.5rem;
  }

  .icon {
    font-size: 2.5rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .actions {
    flex-direction: column-reverse;
  }
}
