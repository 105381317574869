.technology {
  padding: 6rem 2rem;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.95),
    var(--background-dark)
  );
}

.sectionHeader {
  text-align: center;
  margin-bottom: 4rem;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
}

.sectionSubtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

.technologyCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.technologyCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2.5rem 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.technologyCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.iconContainer {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.technologyIcon {
  width: 40px;
  height: 40px;
}

.technologyCard h3 {
  color: var(--chair-orange);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.technologyCard p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  font-size: 1.1rem;
}

@media (max-width: 1200px) {
  .technologyCards {
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .technology {
    padding: 4rem 1rem;
  }

  .technologyCards {
    grid-template-columns: 1fr;
    max-width: 500px;
  }

  .sectionTitle {
    font-size: 2rem;
  }

  .sectionSubtitle {
    font-size: 1.1rem;
  }

  .technologyCard {
    padding: 2rem 1.5rem;
  }

  .iconContainer {
    width: 70px;
    height: 70px;
  }

  .technologyIcon {
    width: 35px;
    height: 35px;
  }
}
