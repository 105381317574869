.faqSection {
  padding: 6rem 2rem;
  background: linear-gradient(
    to bottom,
    var(--background-dark),
    rgba(0, 0, 0, 0.95)
  );
}

.sectionHeader {
  text-align: center;
  margin-bottom: 4rem;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
}

.sectionSubtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

.faqContainer {
  max-width: 800px;
  margin: 0 auto;
}

.faqItem {
  margin-bottom: 1.5rem;
  border-radius: 12px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.faqQuestion {
  width: 100%;
  text-align: left;
  padding: 1.5rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.faqQuestion:hover,
.faqQuestion.open {
  background: rgba(255, 255, 255, 0.08);
}

.faqQuestion:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--chair-orange);
}

.arrow {
  font-size: 1.5rem;
  color: var(--chair-orange);
  transition: transform 0.3s ease;
}

.faqAnswer {
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.faqAnswerContent {
  padding: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  font-size: 1rem;
}

.moreQuestions {
  text-align: center;
  margin-top: 3rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.moreQuestions p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
}

.supportLink {
  display: inline-block;
  color: var(--chair-orange);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.3s ease;
  padding: 0.5rem 1rem;
  border: 1px solid var(--chair-orange);
  border-radius: 6px;
}

.supportLink:hover {
  opacity: 0.8;
  background: rgba(254, 208, 48, 0.1);
}

@media (max-width: 768px) {
  .faqSection {
    padding: 4rem 1rem;
  }

  .sectionTitle {
    font-size: 2rem;
  }

  .sectionSubtitle {
    font-size: 1.1rem;
  }

  .faqQuestion {
    padding: 1.25rem;
    font-size: 1rem;
  }

  .faqAnswerContent {
    padding: 1.25rem;
    font-size: 0.95rem;
  }

  .moreQuestions {
    padding: 1.5rem;
    margin-top: 2rem;
  }
}
