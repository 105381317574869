:root {
  /* Colors */
  --story-text-primary: rgba(255, 255, 255, 0.95);
  --story-text-secondary: rgba(255, 255, 255, 0.85);
  --story-text-tertiary: rgba(255, 255, 255, 0.65);

  /* Backgrounds */
  --story-bg-overlay: rgba(255, 255, 255, 0.05);
  --story-bg-overlay-hover: rgba(255, 255, 255, 0.08);

  /* Accents */
  --story-accent-primary: var(--chair-orange);
  --story-accent-muted: rgba(var(--chair-orange-rgb), 0.8);

  /* Borders */
  --story-border-light: rgba(255, 255, 255, 0.1);
  --story-border-medium: rgba(255, 255, 255, 0.15);

  /* Code */
  --story-code-bg: rgba(0, 0, 0, 0.3);
  --story-code-border: rgba(255, 255, 255, 0.1);
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 600px;
  margin: 0 auto;
  color: white;
  padding: 1rem;
  padding-bottom: 5rem;
}

.topRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
}

.title {
  font-size: 2rem;
  margin: 1rem 0;
  font-weight: 700;
}

.metadata {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.author {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.anonymousIcon {
  color: var(--chair-orange);
  font-size: 1rem;
}

.date {
  font-size: 0.9rem;
  color: var(--light-gray);
}

.tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.tagLabel {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  background-color: var(--chair-orange);
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
}

.actionsSection {
  padding: 1rem 0;
  margin: 1rem 0;
  border-top: 1px dotted var(--divider);
  border-bottom: 1px dotted var(--divider);
}

.actionButtons {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: none;
  padding: 0.5rem;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
}

.actionButton:hover {
  color: var(--chair-orange);
}

.actionButton.active {
  color: var(--chair-orange);
}

.actionIcon {
  font-size: 1.2rem;
}

.actionCount {
  font-size: 0.9rem;

  margin-left: 0.25rem;
}

.actionLabel {
  font-size: 0.9rem;
  font-weight: 500;
}

/* Remove old hug section styles */
.hugSection,
.hugButton,
.hugCount {
  display: none;
}

/* Update existing action styles */
.actions {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

/* Styling for the tag similar to .selectedTag */
.tag {
  display: flex;
  align-items: center;
  border: 0.05em solid #777;
  background-color: var(
    --tag-selected,
    #ddd
  ); /* Fallback color if --tag-selected is not defined */
  padding: 5px 10px;
  border-radius: 20px;
  gap: 0.5em;
  cursor: pointer;
  color: black;
}

/* Add to your existing styles */
.reportOption {
  color: var(--error) !important;
  font-weight: 500;
}

.reportOption:hover {
  background-color: var(--error) !important;
  color: white !important;
}

.body {
  line-height: 1.8;
  font-size: 1.1rem;
  color: var(--story-text-primary);
  padding: 1rem 0;

  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2rem 0 1rem;
    font-weight: 600;
    line-height: 1.3;
    color: var(--story-text-primary);
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  /* Paragraphs and spacing */
  p {
    margin: 1.5rem 0;
    color: var(--story-text-secondary);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Lists */
  ul,
  ol {
    margin: 1.5rem 0;
    padding-left: 2rem;
    color: var(--story-text-secondary);

    li {
      margin: 0.5rem 0;
    }
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;

      ul {
        list-style-type: square;
      }
    }
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;

      ol {
        list-style-type: lower-roman;
      }
    }
  }

  /* Blockquotes */
  blockquote {
    margin: 2rem 0;
    padding: 1rem 2rem;
    border-left: 4px solid var(--story-accent-primary);
    background: var(--story-bg-overlay);
    font-style: italic;
    color: var(--story-text-tertiary);

    p {
      margin: 0.5rem 0;
      color: var(--story-text-tertiary);
    }
  }

  /* Links */
  a {
    color: var(--story-accent-primary);
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
      color: var(--story-accent-muted);
      text-decoration: underline;
    }
  }

  /* Emphasis */
  em {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }

  /* Code blocks */
  pre,
  code {
    font-family: monospace;
    background: var(--story-code-bg);
    border: 1px solid var(--story-code-border);
    border-radius: 4px;
  }

  pre {
    padding: 1rem;
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  code {
    padding: 0.2rem 0.4rem;
    font-size: 0.9em;
  }

  /* Horizontal rule */
  hr {
    margin: 2rem 0;
    border: none;
    border-top: 1px solid var(--story-border-medium);
  }

  /* Tables */
  table {
    width: 100%;
    margin: 1.5rem 0;
    border-collapse: collapse;

    th,
    td {
      padding: 0.75rem;
      border: 1px solid var(--story-border-light);
      text-align: left;
    }

    th {
      background: var(--story-bg-overlay);
      font-weight: 600;
      color: var(--story-text-primary);
    }

    td {
      color: var(--story-text-secondary);
    }

    tr:nth-child(even) {
      background: var(--story-bg-overlay);
    }
  }

  /* Images */
  img {
    max-width: 100%;
    height: auto;
    margin: 1.5rem 0;
    border-radius: 4px;
    border: 1px solid var(--story-border-light);
  }

  /* Figure with caption */
  figure {
    margin: 2rem 0;

    img {
      margin: 0;
    }

    figcaption {
      margin-top: 0.5rem;
      font-size: 0.9rem;
      color: var(--story-text-tertiary);
      text-align: center;
    }
  }

  /* Definition lists */
  dl {
    margin: 1.5rem 0;
    color: var(--story-text-secondary);

    dt {
      font-weight: 600;
      margin-top: 1rem;
      color: var(--story-text-primary);
    }

    dd {
      margin-left: 2rem;
      color: var(--story-text-secondary);
    }
  }

  /* Selection */
  ::selection {
    background: var(--story-accent-primary);
    color: white;
  }
}
