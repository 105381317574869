.dashboard {
  position: relative;
  display: flex;
  background-color: #f5f5f5;
  min-height: calc(100vh - var(--navbar-height));
  overflow: hidden;
}

.sidebar {
  height: auto;
  min-height: calc(100vh - var(--navbar-height));
  background-color: var(--dark-brown);
  transition: width 0.3s ease;
  overflow: hidden;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.sidebarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarHeader h3 {
  color: white;
  margin: 0;
  font-size: 1.2rem;
}

.toggleButton {
  background: transparent;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
}

.navMenu {
  list-style-type: none;
  padding: 0;
  margin: 16px 0;
}

.navMenu li {
  margin: 0;
  padding: 0;
}

.navMenu li button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: left;
}

.navMenu li button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navMenu li.active button {
  background-color: rgba(255, 255, 255, 0.2);
  border-left: 3px solid white;
}

.navMenu li button svg {
  font-size: 1.2rem;
  margin-right: 12px;
}

.contentArea {
  flex: 1;
  overflow: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--navbar-height));
}
