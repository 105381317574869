:root {
  --tag-bg: var(--chair-orange);
  --tag-text: var(--tag-default);
  --tag-remove: rgba(255, 255, 255, 0.8);
  --tag-remove-hover: #ffffff;
  --dropdown-bg: var(--bg-brown);
  --dropdown-border: var(--ricepaper-white);
  --dropdown-shadow: rgba(0, 0, 0, 0.3);
  --option-hover: var(--ricepaper);
  --loading-text: var(--form-text-secondary);
}

.container {
  position: relative;
  width: 100%;
}

.selectedTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-bottom: 0.75rem;
}

.optionBadge {
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: var(--tag-bg);
  color: var(--tag-text);
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
  gap: 0.5em;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  border: none;
}

.optionBadge:hover {
  filter: brightness(90%);
  transform: translateY(-1px);
}

.removeBtn {
  font-size: 1.25em;
  color: var(--tag-remove);
  transition: color 0.2s;
}

.optionBadge:hover .removeBtn {
  color: var(--tag-remove-hover);
}

.inputContainer {
  position: relative;
}

.input {
  width: 100%;
  padding: 1rem;
  border: 2px solid var(--input-border);
  border-radius: 12px;
  background: var(--input-bg);
  color: var(--input-text);
  font-size: 1rem;
  transition: all 0.2s ease;
}

.input:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.input:focus {
  outline: none;
  border-color: var(--input-focus-border);
  box-shadow: 0 0 0 4px var(--input-focus-shadow);
}

.dropdown {
  background: var(--dropdown-bg);
  border: 1px solid var(--dropdown-border);
  border-radius: 12px;
  box-shadow: 0 8px 16px var(--dropdown-shadow);
  z-index: 1000;
}

.dropdownScroller {
  max-height: 240px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--ricepaper-white) transparent;
}

.dropdownScroller::-webkit-scrollbar {
  width: 6px;
}

.dropdownScroller::-webkit-scrollbar-track {
  background: transparent;
}

.dropdownScroller::-webkit-scrollbar-thumb {
  background-color: var(--ricepaper-white);
  border-radius: 3px;
}

.option {
  width: 100%;
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  text-align: left;
  color: var(--tag-text);
  cursor: pointer;
  transition: background-color 0.2s;
}

.option.highlighted,
.option:hover {
  background: var(--option-hover);
}

.optionHeader {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.optionName {
  font-weight: 500;
}

.optionDescription {
  font-size: 0.875rem;
  color: var(--form-text-secondary);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.loading,
.noMore,
.noResults {
  padding: 1rem;
  text-align: center;
  color: var(--loading-text);
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .optionBadge {
    font-size: 1rem;
  }

  .input {
    font-size: 1.125rem;
  }

  .option {
    padding: 0.875rem 1rem;
  }
}
