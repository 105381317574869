.helpContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  color: white;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.header h1 {
  font-size: 2.5rem;
  color: white;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 600px;
  margin: 0 auto;
}

.helpContent {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.helpSection {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 16px;
  padding: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.sectionIcon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  height: 56px;
  background: linear-gradient(135deg, #2a2a2a 0%, #1a1a1a 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.helpSection h2 {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: white;
}

.helpSection p {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
  line-height: 1.6;
}

/* Resource Grid Styles */
.resourceGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.resourceCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.resourceCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.resourceCard h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: white;
}

.resourceCard p {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 1.5rem;
}

.resourceLink {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.resourceLink:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

/* Rest of the CSS remains the same */
.discordLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  background: #5865f2;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  margin: 0 auto;
  max-width: 300px;
  box-shadow: 0 4px 12px rgba(88, 101, 242, 0.3);
}

.discordLink:hover {
  background: #4752c4;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(88, 101, 242, 0.4);
}

.contactOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.contactOption {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.08);
  transition: all 0.3s ease;
}

.contactOption:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.contactOption h3 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: white;
}

.contactIcon {
  color: rgba(255, 255, 255, 0.9);
}

.contactOption a {
  display: block;
  color: #64b5f6;
  font-weight: 600;
  margin-bottom: 0.8rem;
  text-decoration: none;
  transition: color 0.2s ease;
}

.contactOption a:hover {
  color: #90caf9;
  text-decoration: underline;
}

.contactDescription {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.5rem;
  text-align: left !important;
}

/* Search styles */
.searchContainer {
  margin: 1.5rem 0 2.5rem;
}

.searchInputWrapper {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}

.searchInput {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.searchInput:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
}

.searchInput::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.clearSearch {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.clearSearch:hover {
  color: rgba(255, 255, 255, 0.8);
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.faqItem {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.faqItem:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.faqItem h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: white;
  font-weight: 600;
}

.faqItem p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  text-align: left;
  margin-bottom: 0;
}

.noResults {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.noResults p {
  margin-bottom: 0;
}

.noResults a {
  color: #64b5f6;
  text-decoration: none;
  font-weight: 600;
}

.noResults a:hover {
  text-decoration: underline;
}

.linkButton {
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  margin: 1rem auto 0;
  text-align: center;
}

.linkButton:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .helpContainer {
    padding: 1.5rem;
  }

  .header h1 {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .helpSection {
    padding: 2rem 1.5rem;
  }

  .contactOptions {
    grid-template-columns: 1fr;
  }

  .helpSection h2 {
    font-size: 1.5rem;
  }

  .helpSection p {
    font-size: 1rem;
  }

  .discordLink,
  .linkButton,
  .resourceLink {
    width: 100%;
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .faqItem h4 {
    font-size: 1.1rem;
  }
}
