.overlay {
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  z-index: var(--overlay-z-index);
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 90%; /* Take up 90% of the screen width */
  max-width: 600px; /* Limit the maximum width for larger screens */
  height: auto; /* Allow height to adjust based on content */
  margin: 20px; /* Add margin for spacing on desktop */
  position: relative;
}

/* Mobile styles applied via JS with useMediaQuery */
.mobileOverlay {
  align-items: stretch;
  justify-content: stretch;
}

.mobileModal {
  width: 100%;
  height: 100%;
  max-width: none;
  margin: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
}

.mobileModal .closeButton {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Set a fixed height for the loading area */
  color: #555; /* Change the text color for better visibility */
  font-size: 1.2rem; /* Increase font size for loading message */
}

.error {
  color: red; /* Set error text color to red */
  font-weight: bold; /* Make error text bold */
  margin-top: 10px; /* Add margin for spacing */
}

.closeButton {
  position: absolute;
  right: 15px;
  top: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 5px 10px;
  line-height: 1;
}

.closeButton:hover {
  color: #333;
}

/* End Generation Here */
