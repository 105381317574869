.comboboxContainer {
  position: relative;
  width: 100%;
}

.comboboxInput {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  border: 2px solid;
  border-radius: 8px;
  transition: all 0.2s ease;
  padding: 0;
}

.comboboxInput input {
  flex: 1;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 1rem;
  padding: 14px 40px 14px 16px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.clearButton {
  position: absolute;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  transition: color 0.2s ease;
  font-size: 1.25rem;
}

.indicator {
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.dropdown {
  overflow-y: auto;
  border-radius: 8px;
  z-index: 1000;
  padding: 4px 0;
  list-style: none;
}

.option {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.noResults {
  padding: 12px 16px;
  text-align: center;
  font-style: italic;
}

/* Scrollbar styling for the dropdown */
.dropdown::-webkit-scrollbar {
  width: 8px;
}

.dropdown::-webkit-scrollbar-track {
  border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

/* Dark theme (default) */
.dark .comboboxInput {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.dark .comboboxInput:focus-within {
  border-color: var(--chair-orange);
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .comboboxInput input {
  color: white;
  caret-color: var(--chair-orange);
}

.dark .comboboxInput input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark .clearButton {
  color: rgba(255, 255, 255, 0.6);
}

.dark .clearButton:hover {
  color: white;
}

.dark .indicator {
  color: rgba(255, 255, 255, 0.6);
}

.dropdown-dark {
  background-color: #2a2a2a;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.dropdown-dark .option {
  color: white;
}

.dropdown-dark .option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-dark .option.selected {
  background-color: rgba(255, 165, 0, 0.2);
  color: var(--chair-orange);
}

.dropdown-dark .noResults {
  color: rgba(255, 255, 255, 0.5);
}

.dropdown-dark::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.dropdown-dark::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-dark::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Light theme */
.light .comboboxInput {
  border-color: rgba(0, 0, 0, 0.2);
  background-color: white;
}

.light .comboboxInput:focus-within {
  border-color: var(--chair-orange);
  background-color: white;
  box-shadow: 0 0 0 2px rgba(255, 107, 53, 0.1);
}

.light .comboboxInput:hover:not(:focus-within) {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.3);
}

.light .comboboxInput input {
  color: #333;
  caret-color: var(--chair-orange);
}

.light .comboboxInput input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.light .clearButton {
  color: rgba(0, 0, 0, 0.4);
}

.light .clearButton:hover {
  color: rgba(0, 0, 0, 0.7);
}

.light .indicator {
  color: rgba(0, 0, 0, 0.4);
}

.dropdown-light {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.dropdown-light .option {
  color: #333;
}

.dropdown-light .option:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dropdown-light .option.selected {
  background-color: rgba(255, 107, 53, 0.1);
  color: var(--chair-orange);
}

.dropdown-light .noResults {
  color: rgba(0, 0, 0, 0.5);
}

.dropdown-light::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.dropdown-light::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.dropdown-light::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Transparent theme */
.transparent .comboboxInput {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
}

.transparent .comboboxInput:focus-within {
  border-color: var(--chair-orange);
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent .comboboxInput input {
  color: white;
  caret-color: var(--chair-orange);
}

.transparent .comboboxInput input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.transparent .clearButton {
  color: rgba(255, 255, 255, 0.6);
}

.transparent .clearButton:hover {
  color: white;
}

.transparent .indicator {
  color: rgba(255, 255, 255, 0.6);
}

.dropdown-transparent {
  background-color: rgba(30, 30, 30, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.dropdown-transparent .option {
  color: white;
}

.dropdown-transparent .option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-transparent .option.selected {
  background-color: rgba(255, 165, 0, 0.15);
  color: var(--chair-orange);
}

.dropdown-transparent .noResults {
  color: rgba(255, 255, 255, 0.5);
}

.dropdown-transparent::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.dropdown-transparent::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-transparent::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
