.sideMenu {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  width: 250px;
  background-color: var(--surface-primary);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 3;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sideMenu.sideMenuVisible {
  transform: translateX(0);
}

.sideMenuSection {
  padding: 1rem;
  border-bottom: 1px solid var(--surface-secondary);
  background-color: var(--surface-primary);
}

.sectionTitle {
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.75rem;
  color: black;
}

/* User Info Section Styles */
.flexColCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
}

.userButton {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  background-color: white;
  color: var(--text-primary);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.userButton:hover {
  background-color: #f8f8f8;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.userMetadata {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  width: 100%;
}

.metadataItem {
  background-color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  font-size: 0.9rem;
  color: var(--text-secondary);
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
}

.badge.valid {
  background-color: rgba(34, 197, 94, 0.1);
  color: var(--green);
}

.badge.invalid {
  background-color: rgba(239, 68, 68, 0.1);
  color: var(--red);
}

.badge svg {
  width: 16px;
  height: 16px;
}

.locationBadge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* Connection History Styles */
.connectionGroup {
  margin-bottom: 1rem;
}

.connectionTypeTitle {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
}

.connectionItem {
  background: white;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.connectionLabel {
  position: absolute;
  top: -8px;
  right: 8px;
  font-size: 0.7rem;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: var(--chair-orange);
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.reachoutDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.connectionInfo {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-top: 8px;
}

.resourceLink {
  color: var(--chair-orange);
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.message {
  font-size: 0.95rem;
  color: var(--text-primary);
  font-style: italic;
  line-height: 1.4;
}

.timestamp {
  font-size: 0.8rem;
  color: var(--text-secondary);
  margin-top: 8px;
  display: block;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

.tag {
  font-size: 0.75rem;
  padding: 2px 8px;
  background-color: rgba(var(--chair-orange-rgb), 0.1);
  color: var(--chair-orange);
  border-radius: 12px;
}

/* Match Details Styles */
.matchDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.storiesContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.storySection {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
  border-radius: 4px;
}

.storyLabel {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-secondary);
  margin-bottom: 0.25rem;
}

.storyText {
  font-size: 0.9rem;
  color: black;
  white-space: pre-wrap;
}

/* Reachout Details Styles */
.reachoutMessage {
  font-size: 0.9rem;
  color: var(--text-secondary);
  font-style: italic;
}

/* Shared Styles */
.connectionDate {
  font-size: 0.8rem;
  color: var(--text-secondary);
  text-align: right;
}

/* Acceptance Panel Styles */
.acceptancePanel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
}

.acceptanceStatuses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.acceptStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 8px;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.acceptStatus.pending {
  background-color: rgba(237, 125, 49, 0.05);
  color: var(--chair-orange);
}

.acceptStatus.accepted {
  background-color: rgba(34, 197, 94, 0.05);
  color: var(--green);
}

.acceptStatus svg {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.acceptanceInfo {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.acceptanceTitle {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

.acceptanceBenefits {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.acceptanceBenefits li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.acceptanceBenefits li svg {
  width: 16px;
  height: 16px;
  color: var(--green);
}

.acceptButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-weight: 600;
  background-color: var(--status-active);
  color: var(--surface-primary);
  border: none;
}

.acceptButton:not(:disabled):hover {
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--call-success-dark);
}

.acceptButton:disabled {
  background-color: var(--surface-secondary);
  color: var(--text-secondary);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.acceptButtonContent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.error {
  color: var(--red);
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(239, 68, 68, 0.1);
  border-radius: 4px;
}

/* Mobile Styles */
.mobileBackButton {
  display: none;
}

@media (max-width: 768px) {
  .sideMenu {
    width: 100%;
    height: 100%;
  }

  .mobileBackButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    color: var(--chair-orange);
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .mobileBackButton svg {
    width: 20px;
    height: 20px;
  }

  .chatContentContracted {
    margin-right: 0;
  }
}

/* Resource Card Styles */
.resourceCard {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.resourceHeader {
  margin-bottom: 0.75rem;
}

.resourceType {
  font-size: 0.85rem;
  color: var(--text-secondary);
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.resourceContent {
  margin-bottom: 1rem;
}

.resourceLabel {
  font-size: 0.95rem;
  line-height: 1.4;
  color: var(--text-primary);
}

.resourceLink {
  color: var(--chair-orange);
  text-decoration: none;
  font-size: 0.95rem;
  line-height: 1.4;
}

.resourceLink:hover {
  text-decoration: underline;
}

.resourceTags {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.75rem;
}

.tagsLabel {
  font-size: 0.85rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
  display: block;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  font-size: 0.85rem;
  background-color: rgba(var(--chair-orange-rgb), 0.1);
  color: var(--chair-orange);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
}

.reachoutMessage {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.messageLabel {
  font-size: 0.85rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.message {
  font-size: 0.95rem;
  color: var(--text-primary);
  line-height: 1.4;
  margin-bottom: 0.5rem;
  font-style: italic;
}

.timestamp {
  font-size: 0.85rem;
  color: var(--text-secondary);
  display: block;
  text-align: right;
}

/* Update report button to match accept button style */
.reportButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-weight: 600;
  background-color: var(--call-error);
  color: var(--surface-primary);
  border: none;
}

.reportButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--call-error-dark);
}

/* Update the report container to add some spacing */
.reportContainer {
  margin-top: auto; /* Push to bottom */
  border-top: 1px solid var(--surface-secondary);
}

.matchDetailsCard {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.storiesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.storySection {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 0.75rem;
  border-radius: 6px;
}

.storyLabel {
  font-size: 0.85rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.storyText {
  font-size: 0.95rem;
  color: var(--text-primary);
  line-height: 1.4;
  white-space: pre-wrap;
}

.matchMetadata {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.similarityScore {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--chair-orange);
  text-align: center;
}

.sharedTags {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tagsLabel {
  font-size: 0.85rem;
  color: var(--text-secondary);
  font-weight: 600;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  background-color: rgba(var(--chair-orange-rgb), 0.1);
  color: var(--chair-orange);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.85rem;
}

.matchTimestamp {
  font-size: 0.85rem;
  color: var(--text-secondary);
  text-align: center;
}

/* ConnectionsPanel Styles */
.connectionsPanel {
  padding: 1rem;
}

.connectionGroup {
  margin-bottom: 1.5rem;
}

.actionContainer {
  margin-top: auto; /* Push to bottom */
  border-top: 1px solid var(--surface-secondary);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.actionButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.actionButton svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Remove these styles since they're now handled by Button component */
.reviewButton,
.reportButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-weight: 600;
}

/* Remove hover effects since they're handled by Button component */
.reviewButton:hover,
.reportButton:hover {
  transform: none;
  box-shadow: none;
}

/* Review button specific styles */
.reviewButton {
  background-color: var(--chair-orange);
  color: white;
}

.reviewButton:hover {
  background-color: var(--chair-orange-dark);
}

/* Report button specific styles */
.reportButton {
  background-color: var(--call-error);
  color: var(--surface-primary);
}

.reportButton:hover {
  background-color: var(--call-error-dark);
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.acceptanceContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-top: 1px solid var(--surface-secondary);
}

.acceptError {
  color: var(--error);
  font-size: 0.85rem;
  text-align: center;
}

.actionButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.actionButton svg {
  width: 1.25rem;
  height: 1.25rem;
}

.actionContainer {
  margin-top: auto; /* Push report to bottom */
  border-top: 1px solid var(--surface-secondary);
  padding: 1rem;
}

/* Remove color-specific button styles since they're handled by Button component */

/* Add this to your existing CSS */
.blockedBadge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgba(var(--error-rgb), 0.1);
  color: var(--error);
  padding: 0.5rem;
  border-radius: 6px;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.blockedBadge svg {
  font-size: 1.1rem;
}

/* Add this to your existing CSS for the action buttons */
.actionButton.unblockButton {
  background-color: var(--primary);
  color: white;
}

.actionButton.unblockButton:hover {
  background-color: var(--primary-dark);
}
