.card {
  background-color: transparent;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-items: start;
  transition: background-color 0.2s ease-in-out;
}

.card:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.card.selected {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.participantName {
  font-weight: bold;
  color: #ffffff;
}

.relativeTime {
  font-size: 0.8em;
  color: #999;
}

.participantInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.directionArrow {
  color: var(--chair-orange);
  font-size: 1.2rem;
}

/* Status-specific styles */
.pending {
  border-left: 5px solid var(--pending-color, #ffa500);
}

.completed {
  border-left: 5px solid var(--completed-color, #4caf50);
}

.expired {
  border-left: 5px solid var(--expired-color, #9e9e9e);
}

.canceled {
  border-left: 5px solid var(--canceled-color, #f44336);
}

/* Tag styles */
.sharedTags {
  font-size: 0.8em;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}

.tag {
  display: flex;
  align-items: center;
  background-color: var(--chair-orange);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  gap: 0.5em;
  cursor: help;
  outline: none;
  transition: transform 0.2s ease;
  border: none;
}
