.container {
  display: flex;
  flex-direction: column; /* Changed to column */
  justify-content: center;
  align-items: center;
  min-height: 100%;
  max-width: 600px;
  margin: 0 auto;
  color: white;
  padding: 1rem;
  padding-bottom: 5rem;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background-color: #fff0f0;
  border: 1px solid #ffcccb;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}
