.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(57, 45, 40, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.modal {
  background: var(--dark-brown);
  border: 1px solid var(--ricepaper-white);
  border-radius: 16px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--tag-default);
  margin: 0;
  text-align: center;
}

.timer {
  position: relative;
  width: 48px;
  height: 48px;
  margin: 0 auto;
}

.timerCircle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.timeLeft {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--tag-default);
}

.matchInfo {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.story {
  background: var(--ricepaper-white);
  padding: 1rem;
  border-radius: 8px;
}

.story h3 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--tag-default);
  margin: 0 0 0.5rem 0;
}

.story p {
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  font-size: 0.9375rem;
}

.sharedTags {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sharedTags h3 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--tag-default);
  margin: 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  padding: 0.25rem 0.75rem;
  background: var(--chair-orange);
  color: var(--tag-default);
  border-radius: 16px;
  font-size: 0.875rem;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.demographic {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  justify-content: center;
}

.scores {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  text-align: center;
  background: var(--ricepaper-white);
  padding: 1rem;
  border-radius: 8px;
}

.scoreItem {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.scoreLabel {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
}

.scoreValue {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--tag-default);
}

.scoreMax {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 0.25rem;
}

.actions {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.button {
  padding: 0.875rem 1.25rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  flex: 1;
}

.acceptButton {
  composes: button;
  background: var(--chair-orange);
  color: var(--tag-default);
}

.acceptButton:hover {
  background: var(--chair-orange-dark);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.rejectButton {
  composes: button;
  background: var(--ricepaper-white);
  color: var(--tag-default);
}

.rejectButton:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.spinnerInner {
  width: 48px;
  height: 48px;
  border: 3px solid var(--ricepaper-white);
  border-top-color: var(--chair-orange);
  border-radius: 50%;
}

.acceptanceStatus {
  background: var(--chair-orange);
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  animation: pulse 2s infinite ease-in-out;
}

.acceptedBadge {
  display: inline-block;
  background: var(--tag-default);
  color: var(--chair-orange);
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.acceptanceMessage {
  color: var(--tag-default);
  font-size: 0.9375rem;
  margin: 0;
}

.actionsAccepted {
  composes: actions;
  flex-direction: column;
}

.acceptedButton {
  composes: button;
  background: var(--chair-orange-dark);
  color: var(--tag-default);
  opacity: 0.8;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.acceptedButton:hover {
  transform: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.cancelButton {
  composes: button;
  background: var(--ricepaper-white);
  color: var(--tag-default);
  opacity: 0.9;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .modal {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .scores {
    gap: 0.75rem;
  }

  .actions {
    flex-direction: column;
  }

  .button {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }
}

.searchStats {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  font-size: 0.875rem;
  color: var(--tag-default);
  opacity: 0.8;
}

.statItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.statIcon {
  opacity: 0.8;
}
