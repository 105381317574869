.reportDetailContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.reportDetailHeader {
  background-color: white;
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--dark-brown);
  cursor: pointer;
  font-size: 1rem;
  padding: 8px 0;
}

.reportStatus {
  display: flex;
  align-items: center;
}

.reportDetailContent {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reportOverview {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.reportIcon {
  color: var(--dark-brown);
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportInfo {
  flex: 1;
}

.reportInfo h2 {
  margin: 0 0 8px 0;
  color: var(--dark-brown);
}

.reportMeta {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  color: #666;
  font-size: 0.9rem;
}

.actionButtons {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.reportSection {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.reportSection h3 {
  margin: 0 0 16px 0;
  color: var(--dark-brown);
  font-size: 1.2rem;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.reportDetails {
  color: #333;
  line-height: 1.6;
}

.snapshotContainer {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  border-left: 3px solid var(--dark-brown);
}

.snapshotContent h4 {
  margin: 0 0 12px 0;
  color: var(--dark-brown);
}

.contentText {
  margin: 0 0 16px 0;
  color: #333;
  line-height: 1.6;
  white-space: pre-line;
}

.contentMeta {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  color: #666;
  font-size: 0.9rem;
}

.peopleInvolved {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.personCard {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
}

.personCard h4 {
  margin: 0 0 12px 0;
  color: var(--dark-brown);
}

.personInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.personAvatar {
  background-color: #eee;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personName {
  margin: 0;
  font-weight: 500;
  color: #333;
}

.personEmail {
  margin: 4px 0 0 0;
  color: #666;
  font-size: 0.9rem;
}

.statusHistory {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.statusItem {
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
}

.statusInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.statusDate {
  color: #666;
  font-size: 0.9rem;
}

.statusNote {
  margin: 0;
  color: #333;
  font-size: 0.9rem;
}

.resolution {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
}

.resolutionInfo {
  margin-bottom: 8px;
}

.resolutionAction {
  font-weight: 500;
  color: #333;
}

.resolutionNotes {
  margin: 0;
  color: #333;
  font-size: 0.9rem;
  white-space: pre-line;
}

.messagesList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.messageItem {
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #eee;
}

.messageSender {
  font-weight: 500;
  color: var(--dark-brown);
  margin-bottom: 4px;
}

.messageContent {
  color: #333;
  margin-bottom: 8px;
  white-space: pre-line;
}

.messageTime {
  color: #666;
  font-size: 0.8rem;
  text-align: right;
}

.loadingContainer,
.errorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
}

.errorContainer {
  gap: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modalContent h3 {
  margin: 0 0 16px 0;
  color: var(--dark-brown);
}

.formGroup {
  margin-bottom: 16px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.formGroup select,
.formGroup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.formGroup textarea {
  min-height: 100px;
  resize: vertical;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

/* Status styles */
.status-pending {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-investigating {
  background-color: #2196f3;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-resolved {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-dismissed {
  background-color: #9e9e9e;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-active {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-suspended {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-banned {
  background-color: #f44336;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-deleted {
  background-color: #9e9e9e;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}
