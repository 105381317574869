/* packages/client/src/components/PageWrapper/PageWrapper.module.css */
.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  /* The padding-top will be set dynamically via inline style */
  transition: padding-top 0.3s ease; /* Smooth transition for padding changes */
}
