.container {
  display: grid;
  gap: 1rem;
  max-height: 100%;
}

.container > .header {
  display: grid;
  justify-content: center;
  color: white;
  gap: 0.5rem;
  margin: 1rem;
  justify-items: center;
}
.container > .header h1 {
  font-size: 30px;
}

.controls {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  width: 90%;
  margin: 0 auto;
}

.inputContainer {
  position: relative;
  display: grid;
  border: 2px solid #ccc;
  padding: 0.5rem;
  border-radius: 5px;
  grid-template-columns: auto 1fr auto;
}

.icon {
  height: 100%;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #ccc;
}

.input {
  border: none;
  font-size: 1rem;
  transition: border 0.2s ease-in-out;
  -webkit-appearance: none;
  outline: none;
  background-color: transparent;
  color: white;
}

.clear-btn {
  background: none;
  color: #777;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.5rem;
}

.clear-btn:focus,
.clear-btn:hover {
  color: white;
}

.focused {
  border-color: #007bff; /* Highlight border when focused */
}

.sort {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.5rem;
  border: 2px solid #ccc;
  border-radius: 20px;
}

.sortButton {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  padding: 0.5rem; /* Padding inside the buttons */
  border-radius: 20px;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: all 0.3s; /* Smooth background color transition */
  background-color: transparent;
  border: none;
}

.sortButton:hover {
  background-color: var(--chair-orange);
  transform: translateY(-1px);
}

.sortButton.active,
.sortButton:active {
  background-color: var(--chair-orange);
}

/* Add a small margin between buttons */
.sortButton:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .controls {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, 1fr);
  }
}

.loading {
  color: white;
  width: 90%;
  margin: 0 auto;
}

.results {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 90%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .results {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .results {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card {
  display: grid;
  gap: 0.5rem;
  align-items: start;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--basic-boxshadow);
  padding: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 100%;
}

.card:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.card .header span {
  text-wrap: nowrap;
  overflow-wrap: break-word;
}

.card .header span.tag {
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: var(--chair-orange);
  padding: 5px 10px;
  border-radius: 20px;
  gap: 0.5em;
  outline: none;
  color: white;
}

.card .header span.score {
  font-size: 14px; /* Font size for the score */
  color: #4caf50; /* Green color for positive scores */
  background-color: rgba(76, 175, 80, 0.1); /* Light green background */
  padding: 2px 6px; /* Padding around the score */
  border-radius: 12px; /* Rounded corners */
  font-weight: bold; /* Bold text for emphasis */
  display: inline-block; /* Ensure it behaves like a block element */
}

.card .header .matches {
  background-color: #eee; /* Light grey background */
  color: #333; /* Darker text color for contrast */
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75em; /* Smaller font size */
  margin-left: 10px; /* Space between name and hits */
}

/* https://stackoverflow.com/questions/6222616/with-css-use-for-overflowed-block-of-multi-lines */
.card .description {
  overflow: hidden;
  display: -webkit-box;
  max-height: 4.8rem; /* Adjust the height to fit the design */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* Show only 3 lines */
  -webkit-box-orient: vertical;
  white-space: normal; /* Allow wrapping of text */
}

.card .description p {
  font-size: 0.875em;
  color: #666;
}

.card .createdAt {
  align-self: flex-end;
}

.card .createdAt p {
  font-size: 0.75em; /* Smaller font size for the date */
  color: #999; /* Muted color */
  margin-top: 0.5rem; /* Space above the date */
}

.card .metadata {
  display: grid;
  gap: 0.5rem; /* Space between items */
  margin-top: 1rem; /* Space above the meta info section */
  color: #666; /* Text color for metadata */
  font-size: 0.875em; /* Smaller font size */
}

.card .metadata p {
  margin: 0; /* Remove default margin */
  background-color: #f9f9f9; /* Light background for better readability */
  padding: 0.5rem; /* Padding for the paragraphs */
  border-radius: 5px; /* Rounded corners */
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.pagination .btn {
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination .btn:hover {
  background-color: #e0e0e0;
  color: var(--chair-orange);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pagination .btn:disabled {
  background-color: #dcdcdc;
  cursor: not-allowed;
}

.btn.active {
  background-color: var(--chair-orange); /* Use your primary color here */
  color: white;
  font-weight: bold;
}
