:root {
  --border: 1px solid rgba(221, 221, 221, 0.5); /* Smooth right border */
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.noChatsMessage {
  display: grid;
  place-items: center;
  text-align: center;
  color: #888;
  height: 100%;
  max-width: 90vw;
  margin: auto;
}

.list {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: flex-start;
}

.status {
  display: grid;
  place-items: center;
  color: white;
  padding: 2rem 0;
}

/* chatCard styles */
.chatCard {
  background-color: transparent;
  color: white;
  border-bottom: var(--border);
  padding: 10px;
  cursor: pointer;
  display: grid;
  gap: 0.5rem;
  justify-items: start;
}
.chatCard {
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.participantName {
  font-weight: bold;
}

.lastMessage {
  color: white;
  font-size: 0.9em;
}

.unread {
  font-weight: bold;
}

.systemMessage {
  font-style: italic;
  color: #ccc;
}

.reachOutMessage {
  font-weight: bold; /* Bold text */
}

.sharedTags {
  font-size: 0.8em;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}

.relativeTime {
  font-size: 0.8em;
  color: #999;
  margin-left: 10px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: var(--chair-orange);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  gap: 0.5em;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s ease;
  border: none;
}

.chatRequestCard {
  /* background-color: rgba(255, 165, 0, 0.1); Light orange background */
  border-left: 5px solid orange; /* Orange border to indicate request */
}

.requestIdentifier {
  font-size: 0.8em;
  color: orange;
  font-weight: bold;
}

.chatCard.selected {
  background-color: rgba(0, 0, 0, 0.2); /* Changed to rgba for transparency */
  color: white;
}

.chatRequestCard.selected {
  background-color: rgba(0, 0, 0, 0.2); /* Changed to rgba for transparency */
  color: white;
}
