.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.container h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  font-size: 2rem;
  font-weight: 600;
}

.optionsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.optionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  background: rgba(var(--navbar-brown), 0.3);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  color: white;
}

.optionCard:hover {
  transform: translateY(-2px);
  background: rgba(var(--navbar-brown), 0.4);
  border-color: var(--chair-orange);
}

.icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--chair-orange);
}

.optionCard h2 {
  margin-bottom: 0.75rem;

  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

.optionCard p {
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 280px;
}

.recentContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 900px;
  margin: 0 auto;
}

.contentSection {
  background: rgba(var(--navbar-brown), 0.3);
  padding: 1.5rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
}

.sectionIcon {
  color: var(--chair-orange);
  font-size: 1.25rem;
}

.contentSection h3 {
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
}

.contentList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.emptyMessage {
  color: var(--text-secondary);
  text-align: center;
  font-style: italic;
  padding: 1.5rem 0;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .optionsContainer {
    grid-template-columns: 1fr;
  }

  .recentContent {
    grid-template-columns: 1fr;
  }

  .optionCard {
    padding: 2rem;
  }

  .container h1 {
    font-size: 1.75rem;
  }

  .optionCard h2 {
    font-size: 1.25rem;
  }
}
