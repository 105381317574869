.contactContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.contactContent {
  display: flex;
  gap: 3rem;
  margin-top: 2rem;
}

.contactInfo {
  flex: 1;
}

.contactInfo h2 {
  margin-bottom: 1rem;
  color: var(--chair-orange);
  font-size: 2rem;
  font-weight: 600;
}

.contactInfo p {
  margin-bottom: 2rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
}

.contactMethods {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.contactMethod {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.contactMethod:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.contactMethod h3 {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: var(--chair-orange);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contactMethod h3 svg {
  font-size: 1.2rem;
}

.contactMethod p {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
}

.contactForm {
  flex: 1.5;
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.formGroup input:hover,
.formGroup textarea:hover {
  border-color: rgba(255, 255, 255, 0.3);
}

.formGroup input:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(var(--chair-orange-rgb), 0.2);
}

.submitButton {
  background-color: var(--chair-orange);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submitButton:hover {
  background-color: var(--chair-orange-dark);
  transform: translateY(-1px);
}

.submitButton:active {
  transform: translateY(1px);
}

.submitButton:disabled {
  background-color: rgba(var(--chair-orange-rgb), 0.5);
  cursor: not-allowed;
  transform: none;
}

.statusMessage {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.success {
  background-color: rgba(39, 174, 96, 0.1);
  border: 1px solid rgba(39, 174, 96, 0.3);
  color: #2ecc71;
}

.error {
  background-color: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.3);
  color: #e74c3c;
}

@media (max-width: 768px) {
  .contactContent {
    flex-direction: column;
  }

  .contactForm {
    padding: 1.5rem;
  }

  .contactInfo h2 {
    font-size: 1.75rem;
  }
}
