/* packages/client/src/pages/Register/StepReview.module.css */
.container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 16px 0;
  position: relative;
}

.section:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
}

.section p {
  text-align: center;
}

.sectionTitle {
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
}

.bold {
  font-weight: 600;
}

.subtext {
  font-style: italic;
  font-size: 0.875em;
  color: #a19393;
}

.data {
  color: var(--chair-orange);
  font-weight: bold;
  padding: 0 4px;
}

.lifeExperienceList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.experienceWrapper {
  width: 100%;

  overflow: hidden;
}

.overlay {
  position: relative;
  pointer-events: none;
  opacity: 0.6;
}

.statusOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  z-index: 10;
}

.successIcon {
  color: var(--chair-orange);
  animation: fadeIn 0.4s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.tagStoriesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experienceHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.75rem;
  gap: 12px;
}

.experience {
  position: relative;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  margin: 10px 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-out;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.tag {
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 20px;
  background-color: var(--chair-orange);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.date {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.story {
  margin: 10px 0;
  margin-left: 0.5rem;
  color: var(--graphite);
  font-size: 0.95rem;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.emptyState {
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
  text-align: center;
  margin: 24px 0;
  padding: 16px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.cardWrapper {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.lifeExperienceList > div {
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.infoRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
  line-height: 1.6;
}

.experiencesSection {
  margin-top: 8px;
  width: 100%;
}
