/* packages/client/src/pages/Lobby/Lobby.module.css */
:root {
  --header-height: 2.5rem;
  --back-button-bg: var(--chair-orange);
  --back-button-hover: var(--chair-orange-dark);
  --back-button-text: var(--tag-default);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backButton {
  position: relative;
  margin-bottom: 2rem;
  padding: 0.875rem 1.25rem;
  background: var(--back-button-bg);
  border: none;
  border-radius: 8px;
  color: var(--back-button-text);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: flex-start;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.backButton:hover {
  background: var(--back-button-hover);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.backButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 2rem 1rem;
  }

  .backButton {
    margin-bottom: 1.5rem;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }
}

.lobby {
  position: relative;
  overflow-y: hidden !important;
  height: 100%;
}

.statsBar {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 2rem;
  padding: 0.75rem 1.5rem;
  background: var(--dark-brown);
  border: 1px solid var(--ricepaper-white);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.statItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--tag-default);
}

.statValue {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--chair-orange);
}

.statLabel {
  font-size: 0.875rem;
  opacity: 0.9;
}

.statDivider {
  color: var(--tag-default);
  opacity: 0.5;
}

@media (max-width: 768px) {
  .statsBar {
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }

  .statValue {
    font-size: 1.125rem;
  }

  .statLabel {
    font-size: 0.75rem;
  }
}
