/* packages/client/src/components/Tooltip/Tooltip.module.css */
.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px 12px;
  width: max-content;
  max-width: 250px;
  pointer-events: none;
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.tooltipContent {
  font-size: 0.85rem;
  line-height: 1.4;
}

.tooltipContent strong {
  display: block;
  margin-bottom: 4px;
}

.tooltipContent p {
  margin: 4px 0;
}

/* Placement variations */
.top {
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
}

.bottom {
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
}

.left {
  right: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
}

.right {
  left: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
}

.tooltipContainer * {
  color: var(--chair-orange) !important;
}

.tooltipIcon {
  cursor: pointer;
  color: var(--chair-orange);
  font-size: 1.5em; /* Adjust the size as needed */
}

.tooltipHeader {
  font-size: 1.2em; /* Adjust size as needed */
  font-weight: bold; /* Make the header bold */
  margin-bottom: 10px; /* Spacing between header and body */
  color: var(--chair-orange);
}

.tooltipBox {
  background-color: white;
  padding: 20px;
  display: flex; /* Enable flex layout */
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align items to the start (left) */
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
}

.tooltipBox p {
  color: black;
}

.tooltipBox .btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.tooltipBox .btnContainer .closeButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: filter 0.1s ease; /* Smooth transition for the filter */
}

.tooltipBox .btnContainer .closeButton:hover {
  /* background-color: #45a049; */
  filter: brightness(90%);
}

.tooltipBody > * {
  font-size: 1em; /* Regular font size for the body */
  color: #666; /* Lighter color for the body text */
  line-height: 1.5; /* Spacing between lines */
  text-align: left; /* Align text to the left */
}

.tooltipBody p {
  font-size: 1em; /* Regular font size for the body */
  color: #666; /* Lighter color for the body text */
  line-height: 1.5; /* Spacing between lines */
  text-align: left; /* Align text to the left */
}

.tooltipBody .header {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.tooltipBody strong {
  font-weight: bold;
}

.tooltipBody ul {
  list-style-position: inside;
}
.tooltipBody li {
  margin-bottom: 0.5em;
}

@media (max-width: 768px) {
  .tooltipBox {
    max-width: 90%;
  }
}

.tooltipTrigger {
  display: inline-flex;
  align-items: center;
  cursor: help;
}

/* Style overrides for dark tooltips */
.tooltip.dark {
  background-color: #222;
  color: white;
}

/* Ensure tooltip content is properly styled */
.tooltip p {
  margin: 0 0 8px 0;
  font-weight: 500;
}

.tooltip ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.tooltip li {
  margin: 4px 0;
  padding-left: 20px;
  position: relative;
}

.tooltip li:before {
  content: "•";
  position: absolute;
  left: 8px;
}
