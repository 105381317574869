.date {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Updated to 1 row 2 cols */
  gap: 0.5rem;
  color: black;
}

p.heading {
  color: var(--chair-orange);
  font-weight: 600;
  margin-top: 10px; /* Added margin */
}

p.heading span {
  color: var(--chair-orange);
  font-weight: 600;
}

.dateSelector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}

.dateField {
  width: 100%;
}

/* Adjust the SimpleCombobox styles to match the theme */
.monthSelector,
.yearSelector {
  width: 100%;
}

/* Override SimpleCombobox styles if needed for light theme */
.monthSelector :global(.comboboxInput),
.yearSelector :global(.comboboxInput) {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.2);
}

.monthSelector :global(.comboboxInput input),
.yearSelector :global(.comboboxInput input) {
  color: #333;
}

.monthSelector :global(.comboboxInput input::placeholder),
.yearSelector :global(.comboboxInput input::placeholder) {
  color: rgba(0, 0, 0, 0.5);
}

.monthSelector :global(.clearButton),
.yearSelector :global(.clearButton),
.monthSelector :global(.indicator),
.yearSelector :global(.indicator) {
  color: rgba(0, 0, 0, 0.5);
}

.monthSelector :global(.clearButton:hover),
.yearSelector :global(.clearButton:hover) {
  color: rgba(0, 0, 0, 0.8);
}

/* Adjust dropdown styles for light theme */
.monthSelector :global(.dropdown),
.yearSelector :global(.dropdown) {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.monthSelector :global(.option),
.yearSelector :global(.option) {
  color: #333;
}

.monthSelector :global(.option:hover),
.yearSelector :global(.option:hover) {
  background-color: rgba(0, 0, 0, 0.05);
}

.monthSelector :global(.option.selected),
.yearSelector :global(.option.selected) {
  background-color: rgba(255, 107, 53, 0.1);
  color: var(--chair-orange, #ff6b35);
}

.monthSelector :global(.noResults),
.yearSelector :global(.noResults) {
  color: rgba(0, 0, 0, 0.5);
}

/* Scrollbar styling for light theme */
.monthSelector :global(.dropdown::-webkit-scrollbar-track),
.yearSelector :global(.dropdown::-webkit-scrollbar-track) {
  background: rgba(0, 0, 0, 0.05);
}

.monthSelector :global(.dropdown::-webkit-scrollbar-thumb),
.yearSelector :global(.dropdown::-webkit-scrollbar-thumb) {
  background-color: rgba(0, 0, 0, 0.2);
}

.monthSelector :global(.dropdown::-webkit-scrollbar-thumb:hover),
.yearSelector :global(.dropdown::-webkit-scrollbar-thumb:hover) {
  background-color: rgba(0, 0, 0, 0.3);
}
