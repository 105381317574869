/* packages/client/src/pages/Lobby/FloatingTooltip/FloatingTooltip.module.css */

.tooltip {
  background-color: var(--chair-orange);
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  z-index: var(--tooltip-z-index);
  position: relative;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  /* width: 0; */
  /* height: 0; */
  /* border-style: solid; */
  /* border-width: 5px 5px 0 5px; */
  /* border-color: var(--chair-orange) transparent transparent transparent; */
  /* position: absolute; */
  /* top: 100%; */
  /* left: 50%;

  transform: translateX(-50%); */
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^="top"] > .arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .arrow {
  left: -4px;
}
