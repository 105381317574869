.overlay {
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  z-index: var(--overlay-z-index);
}

.dialog {
  background-color: white;
  color: black;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
}
