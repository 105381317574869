.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
  z-index: var(--overlay-z-index);
}

.modal {
  background-color: white;
  border-radius: 20px;
  padding: 2rem;
  max-width: 90%;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal h2 {
  color: var(--chair-orange);
  margin-bottom: 1rem;
}

.modal p {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.browserIcons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1.5rem 0;
  color: #666;
}

.actions {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
}

.copyButton,
.openButton {
  flex: 1;
  padding: 0.75rem;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.copyButton {
  background-color: #f0f0f0;
  color: #333;
}

.openButton {
  background-color: var(--chair-orange);
  color: white;
}

.copyButton:hover,
.openButton:hover {
  filter: brightness(90%);
}

.closeButton {
  width: 100%;
  padding: 0.75rem;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  margin-top: 1rem;
}

.closeButton:hover {
  color: #333;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  backdrop-filter: blur(5px);
}

.modalContent {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  width: 85%;
  max-width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modalTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.modalMessage {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;
  color: #555;
}

.openButton {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
}

.openButton:hover,
.openButton:focus {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.openButton:active {
  transform: translateY(0);
}

/* Add a pulsing animation to draw attention */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(66, 133, 244, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
  }
}

.openButton {
  animation: pulse 2s infinite;
}

.facebookNote {
  font-weight: bold;
  color: #1877f2;
  margin-bottom: 8px;
}

.instructionList {
  text-align: left;
  margin: 0 auto 16px;
  padding-left: 24px;
  color: #555;
  line-height: 1.6;
}

.instructionList li {
  margin-bottom: 8px;
}

.copyButton {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s;
}

.copyButton:hover {
  background-color: #e0e0e0;
}

.buttonSpacer {
  height: 12px;
}

.copyFeedback {
  margin-top: 8px;
  color: #4caf50;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Add these styles */
.facebookInstructions {
  margin-bottom: 16px;
  text-align: left;
}

.instructionTitle {
  font-weight: bold;
  color: #1877f2;
  margin-bottom: 8px;
  margin-top: 16px;
}

.instructionList {
  text-align: left;
  margin: 0 0 16px;
  padding-left: 24px;
  color: #555;
  line-height: 1.6;
}

.instructionList li {
  margin-bottom: 8px;
}
