/* packages/client/src/components/ToastNotif/ToastNotif.module.css */
.toastContainer {
  display: flex;
  flex-direction: column;
  z-index: 1100;
  gap: 0.5rem;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.toast.info {
  background-color: #007bff;
  color: white;
}

.toast.success {
  background-color: #28a745;
  color: white;
}

.toast.orange {
  background-color: var(--chair-orange);
  color: white;
}

.toast.error {
  background-color: #dc3545;
  color: white;
}

.closeButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

@media (max-width: 768px) {
  .toastContainer {
    left: 20px;
    right: 20px;
  }
}
