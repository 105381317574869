/* packages/client/src/pages/Register/Register.module.css */
.registerForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-height: 100vh;
  padding: 32px;
  position: relative;
  margin: 0 auto;
  color: white;
}

.stepIndicator {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
}

.stepIndicator span {
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}

.progressBar {
  height: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background-color: var(--chair-orange);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.stepContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 16px 0;
  width: 100%;
}

/* This ensures all step content has consistent width */
.stepContainer > div {
  width: 100%;
  margin: 0 auto;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.backButton,
.nextButton {
  min-width: 120px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.backButton {
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.backButton:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.nextButton {
  background-color: var(--chair-orange);
  color: white;
  border: none;
  margin-left: auto;
}

.nextButton:hover:not(:disabled) {
  background-color: #e06c00;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.nextButton:disabled,
.backButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Header styles for step titles */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.header h1 {
  font-size: 28px;
  font-weight: 700;
  color: white;
  text-align: center;
}

/* Input styles */
.input-group {
  margin-bottom: 20px;
  width: 100%;
}

.input-group .label-tooltip {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.input-group label {
  display: block;
  margin: 0.5rem 0;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.input-group .input,
.input-group .select {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.2s ease-in-out;
  -webkit-appearance: none;
  height: 48px;
}

.input-group .input:focus,
.input-group .select:focus {
  outline: none;
  border-color: var(--chair-orange);
  background-color: rgba(255, 255, 255, 0.15);
}

.input-group .input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.errorText {
  color: #ffcc00;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.input-group .errorMessage {
  color: #ffcc00;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Tooltip styles */
.with-tooltip {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.subheader {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 10px;
  color: rgba(255, 255, 255, 0.8);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .registerForm {
    padding: 24px 16px;
  }

  .buttonContainer {
    margin-top: 24px;
  }

  .backButton,
  .nextButton {
    min-width: 100px;
    padding: 10px 16px;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .registerForm {
    padding: 16px 12px;
  }

  .stepIndicator span {
    font-size: 14px;
  }

  .header h1 {
    font-size: 24px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  width: 600px;
  max-width: 90vw;
  max-height: 75%;
  position: relative;
}

.step {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  min-height: 30px;
  max-height: 30px;
}

.body {
  position: relative;
  max-height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.btn-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  min-height: 50px;
}

.wrapper {
  position: relative;
  height: calc(100% - 30px);
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
}

.header > * {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 10px;
}

.header * {
  font-weight: bold;
}

.spinner {
  animation: spin 1s linear infinite;
  margin-left: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.submissionStatus {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: default;
}

.successPopup,
.errorPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50; /* Green for success */
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
}

.errorPopup {
  background-color: orange;
}

.registrationConfirmationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.registrationConfirmation {
  display: flex;
  align-items: baseline;
  gap: 0.75rem;
  color: orange;

  & > p {
    font-size: 1.25rem;
  }

  & > p > a {
    color: orange;
    font-weight: 600;
  }
}

.registrationConfirmation > p > a {
  text-decoration: underline;
}

.confirmationModal {
  width: 100%;
  padding: 1.5rem;
}

.modalButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  z-index: var(--overlay-z-index);
  backdrop-filter: blur(4px);
  position: fixed;
  inset: 0;
}

.termsDialog {
  background-color: white;
  color: black;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px;
  z-index: 1000;
}

.termsHeader {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--chair-orange);
  margin-bottom: 16px;
  text-align: center;
}

.termsSubheader {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 32px;
  text-align: center;
  line-height: 1.5;
}

.termsSubheader strong {
  color: var(--chair-orange);
  font-weight: 600;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 24px 0;
}

.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  background-color: #f8f8f8;
  transition: background-color 0.2s ease;
}

.checkboxWrapper:hover {
  background-color: #f0f0f0;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  accent-color: var(--chair-orange);
  cursor: pointer;
  position: relative;
  background-color: var(--chair-orange);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 2px solid var(--chair-orange);
}

.checkbox:checked {
  background-color: var(--chair-orange);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox:hover {
  filter: brightness(0.9);
}

.checkbox:focus {
  outline: 2px solid rgba(var(--chair-orange-rgb), 0.5);
  outline-offset: 2px;
}

.checkboxLabel {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #444;
}

.checkboxLabel a {
  color: var(--chair-orange);
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

.checkboxLabel a:hover {
  text-decoration: underline;
}

.acceptButton {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 16px;
}

.acceptButton:disabled {
  background-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
}

.acceptButton:not(:disabled) {
  background-color: #4caf50;
  color: white;
}

.acceptButton:not(:disabled):hover {
  background-color: #45a049;
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .termsDialog {
    width: 95%;
    padding: 24px;
  }

  .termsHeader {
    font-size: 1.5rem;
  }

  .checkboxLabel {
    font-size: 1rem;
  }
}
