/* packages/client/src/pages/Chat/ChatMessage.module.css */

.wrapper {
}

.message {
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0;
}

.message .body {
  padding: 8px 12px;
  border-radius: 16px;
  cursor: default;
  display: flex;
  gap: 0.5rem;
}

.message .timestamp {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.15rem;
  text-align: center;
}

.message.currentUser {
  align-self: flex-end; /* Align right for current user */
}

.message.currentUser .body {
  background-color: var(--chair-orange); /* Facebook Messenger Blue */
  color: white; /* White text for better readability */
  margin-left: auto; /* Ensure left margin is auto for alignment */
  display: flex;
  align-items: center;
}

.message.currentUser .timestamp {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.message.otherUser {
}

.message.otherUser .body {
  align-self: flex-start; /* Align left for other users */
  background-color: #f0f0f0; /* Light grey background */
  color: black;
}

.message.otherUser .timestamp {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
}

.message.system {
  max-width: 80%;
  margin: 0.5rem auto;
}

.message.system .body {
  background-color: var(--dark-brown);
  color: white;
  border-radius: 12px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.message.system.reachout .body {
  background-color: rgba(0, 0, 0, 50%);
  color: white;
  font-size: 0.8rem;
  flex-direction: column;
}

.message.system.reachout .timestamp {
  display: flex;
  justify-content: center;
}

.message.system.reachout .btn {
  background-color: red;
}

.message.reachout {
  max-width: 100%;
  text-align: center;
  align-self: center;
}

.message.reachout .body {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 8px;
}

.message.reachout .btn {
  background-color: red;
}

.message.reachout .reachoutHeader {
  font-weight: 500;
  color: var(--light-gray);
  margin-bottom: 4px;
}

.message.reachout .resourceLink {
  color: var(--chair-orange);
  text-decoration: none;
  font-weight: 600;
}

.message.reachout .resourceLink:hover {
  text-decoration: underline;
}

.message.reachout .reachoutMessage {
  color: white;
  font-size: 1rem;
  line-height: 1.4;
  white-space: pre-wrap;
  word-break: break-word;
}

.message.reachout .reachoutStory {
  font-style: italic;
  color: gray;

  transition: color 0.3s ease; /* Smooth color transition */
}

.message.reachout .reachoutStory.clickable {
  cursor: pointer; /* Indicate that it's clickable */
}

.message.reachout .reachoutStory.clickable:hover {
  color: #007bff; /* Change to your desired link color on hover */
}

.message.reachout .connectionAdded {
  .resourceText {
    margin-top: 0.5rem;
    color: lightgrey; /* Changed to light grey */
  }
}

.message.reachout .link {
  cursor: pointer;
  color: var(--chair-orange);
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: bold;
}

.message.reachout .link:hover {
  text-decoration: underline; /* Underline on hover for emphasis */
}

.message.reachout .matchCreated {
  background-color: var(--chair-orange-light);
  padding: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0;
  text-align: center;
}

.message.reachout .matchCreated .pseudonym {
  color: var(--chair-orange);
  font-weight: bold;
}

.message.reachout .matchCreated .stories {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
}

.message.reachout .matchCreated .story {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 4px;
}

.message.reachout .matchCreated .story .label {
  font-weight: bold;
  font-style: normal;
  margin-right: 0.5rem;
}

.message.reachout .matchCreated .sharedTags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.message.reachout .matchCreated .tag {
  background-color: var(--chair-orange);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.9rem;
}

.message.reachout .matchAccepted {
  background-color: var(--chair-orange-light);
  padding: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0;
  text-align: center;
}

.message.reachout .matchAccepted .pseudonym {
  color: var(--chair-orange);
  font-weight: bold;
}

.message.reachout .matchAccepted .stories {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
}

.message.reachout .matchAccepted .story {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 4px;
}

.message.reachout .matchAccepted .story .label {
  font-weight: bold;
  font-style: normal;
  margin-right: 0.5rem;
}

.message.reachout .matchAccepted .sharedTags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.message.reachout .matchAccepted .tag {
  background-color: var(--chair-orange);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.9rem;
}

.message.reachout .matchMetadata {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  font-size: 0.9em;
  color: var(--text-secondary);
}

.message.reachout .similarity {
  display: flex;
  align-items: center;
}

.message.reachout .sharedTagCount {
  display: flex;
  align-items: center;
}

.message.reachout .pending {
  opacity: 0.7;
}

.message.reachout .statusIndicator {
  display: inline-block;
  margin-left: 4px;
  width: 12px;
  height: 12px;
  /* Add your pending indicator styles */
}

.message.reachout .messageText {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

.message.reachout .text {
  /* Ensure text wraps properly */
  word-break: break-word;
}

.message.reachout .statusContainer {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  height: 100%;
}

.message.reachout .pendingCheck {
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  opacity: 0.7;
  animation: pulse 1.5s infinite;
}

.message.reachout .deliveredContainer {
  display: inline-flex;
  align-items: center;
  gap: 0px;
}

.message.reachout .deliveredCheck {
  color: white;
  font-size: 10px;
  opacity: 0.7;
}

.message.reachout .deliveredCheck:last-child {
  margin-left: -4px; /* Overlap the checkmarks slightly */
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

.message.reachout .errorMessage {
  color: var(--error-color, #ff6b6b);
  font-style: italic;
  font-size: 0.9em;
}

.message.reachout .statusIndicator {
  display: flex;
  align-items: center;
}

.message.reachout .pendingDot {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

/* System Message Styling */
.systemMessage {
  text-align: center;
  padding: 1rem;
  background-color: var(--dark-brown);
  color: white;
  border-radius: 12px;
  margin: 1rem auto;
  max-width: 80%;
}

/* Common system message components */
.title {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: white;
  line-height: 1.4;
}

.metadata {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.resourceSection {
  background-color: rgba(255, 255, 255, 0.08);
  padding: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.resourceType {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
  text-transform: capitalize;
}

.resourceLabel {
  color: white;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.messageSection {
  color: rgba(255, 255, 255, 0.9);
  padding: 8px 12px;
  border-radius: 16px;
  line-height: 1.4;
  max-width: 80%;
  margin: 0.5rem auto;
}

.messageSection.currentUser {
  background-color: var(--chair-orange);
  color: white;
  margin-left: auto;
}

.messageSection.otherUser {
  background-color: #f0f0f0;
  color: black;
  margin-right: auto;
}

.infoList {
  list-style: none;
  padding: 0;
  margin: 0.5rem 0;
}

.infoList li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0;
}

.infoList li::before {
  content: "•";
  color: var(--chair-orange);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin-top: 0.25rem;
  justify-content: center;
}

.tag {
  background-color: var(--chair-orange);
  color: white;
  padding: 0.2rem 0.6rem;
  border-radius: 12px;
  font-size: 0.85rem;
}

.pseudonym {
  color: var(--chair-orange);
  font-weight: 600;
}

.chat .sideMenu {
  position: absolute;
  height: 100%;
  right: 0;
  width: 150px;
}

/* sharedTags */

.sharedTags {
  display: inline-block; /* Treat the container as an inline element */
}

.tag {
  padding: 10px 15px;
  background-color: var(--chair-orange);
  border-radius: 20px;
  cursor: default;
  margin: 5px;
  display: inline-block;
  color: white;
  font-weight: 600;

  text-wrap: nowrap;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* reachout */

.reachoutContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  text-align: center;
}

.reachoutHeader {
  font-weight: 500;
  color: var(--light-gray);
  margin-bottom: 4px;
}

.resourceLink {
  color: var(--chair-orange);
  text-decoration: none;
  font-weight: 600;
}

.resourceLink:hover {
  text-decoration: underline;
}

.reachoutBody {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 8px;
}

.reachoutStory {
  font-style: italic;
  color: gray;

  transition: color 0.3s ease; /* Smooth color transition */
}

.reachoutStory.clickable {
  cursor: pointer; /* Indicate that it's clickable */
}

.reachoutStory.clickable:hover {
  color: #007bff; /* Change to your desired link color on hover */
}

.connectionAdded {
  .resourceText {
    margin-top: 0.5rem;
    color: lightgrey; /* Changed to light grey */
  }
}

.link {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.link:hover {
  text-decoration-style: solid;
}

.matchCreated {
  background-color: var(--chair-orange-light);
  padding: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0;
  text-align: center;
}

.matchCreated .pseudonym {
  color: var(--chair-orange);
  font-weight: bold;
}

.matchCreated .stories {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
}

.matchCreated .story {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 4px;
}

.matchCreated .story .label {
  font-weight: bold;
  font-style: normal;
  margin-right: 0.5rem;
}

.matchCreated .sharedTags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.matchCreated .tag {
  background-color: var(--chair-orange);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.9rem;
}

.matchAccepted {
  background-color: var(--chair-orange-light);
  padding: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0;
  text-align: center;
}

.matchAccepted .pseudonym {
  color: var(--chair-orange);
  font-weight: bold;
}

.matchAccepted .stories {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
}

.matchAccepted .story {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 4px;
}

.matchAccepted .story .label {
  font-weight: bold;
  font-style: normal;
  margin-right: 0.5rem;
}

.matchAccepted .sharedTags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.matchAccepted .tag {
  background-color: var(--chair-orange);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.9rem;
}

.matchMetadata {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  font-size: 0.9em;
  color: var(--text-secondary);
}

.similarity {
  display: flex;
  align-items: center;
}

.sharedTagCount {
  display: flex;
  align-items: center;
}

.pending {
  opacity: 0.7;
}

.statusIndicator {
  display: inline-block;
  margin-left: 4px;
  width: 12px;
  height: 12px;
  /* Add your pending indicator styles */
}

.messageText {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

.text {
  /* Ensure text wraps properly */
  word-break: break-word;
}

.statusContainer {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  height: 100%;
}

.pendingCheck {
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  opacity: 0.7;
  animation: pulse 1.5s infinite;
}

.deliveredContainer {
  display: inline-flex;
  align-items: center;
  gap: 0px;
}

.deliveredCheck {
  color: white;
  font-size: 10px;
  opacity: 0.7;
}

.deliveredCheck:last-child {
  margin-left: -4px; /* Overlap the checkmarks slightly */
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

.errorMessage {
  color: var(--error-color, #ff6b6b);
  font-style: italic;
  font-size: 0.9em;
}

.statusIndicator {
  display: flex;
  align-items: center;
}

.pendingDot {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

/* Base System Message */
.systemMessage {
  text-align: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  border-radius: 12px;
  margin: 1rem auto;
  max-width: 80%;
  font-size: 0.95rem;
}

/* Content Sections */
.resourceInfo,
.stories {
  margin-top: 0.75rem;
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.resourceInfo .resourceType,
.stories .story .label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.25rem;
}

.resourceInfo .resourceLabel,
.stories .story {
  color: white;
}

/* Interactive Elements */
.pseudonym,
.link {
  color: var(--chair-orange);
  font-weight: 600;
  cursor: pointer;
}

.link {
  text-decoration: underline;
}

/* Tags */
.sharedTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
  justify-content: center;
}

.tag {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.85rem;
}

.storySection {
  margin-bottom: 0.75rem;
}

.storyLabel {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.storyText {
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 6px;
  font-style: italic;
  line-height: 1.4;
}

.similarityText {
  color: var(--chair-orange);
  font-weight: 600;
  text-align: center;
  font-size: 1.1rem;
}

.chatLink {
  text-align: center;
  margin-top: 0.5rem;
}

.link {
  color: var(--chair-orange);
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  font-weight: 500;
}

.link:hover {
  text-decoration-style: solid;
}

.failedContainer {
  display: inline-flex;
  align-items: center;
  cursor: pointer; /* Makes it clear this is interactive */
}

.failedIcon {
  color: #ff4d4f; /* Red color for error */
  font-size: 12px;
  animation: fadeInOut 2s infinite; /* Subtle animation to draw attention */
}

@keyframes fadeInOut {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
