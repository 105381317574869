.input {
  padding: 10px; /* Padding for spacing */
  display: flex; /* Using flexbox for alignment */
  align-items: stretch; /* Vertically center the elements */
  background-color: rgba(0, 0, 0, 0.2);
}

.input > * {
  font-size: 16px;
}

.input textarea {
  flex-grow: 1;
  padding: 10px; /* Padding for comfortable typing space */
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px; /* Slightly rounded corners */
  margin-right: 10px; /* Space between input and send button */
  resize: none;
  min-height: 50px;
  max-height: 150px;
  color: white;
  transition: all 0.1s ease-in-out;
}

.input textarea:focus {
  outline: none;
  border-color: var(
    --chair-orange
  ); /* Highlight color when textarea is focused */
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.25); /* Optional: Add a subtle shadow on focus */
}

.input button {
  padding: 8px 15px; /* Padding for the button */
  background-color: var(--chair-orange);
  color: white; /* White text on the button */
  border: none; /* No border for the button */

  cursor: pointer; /* Cursor change to indicate it's clickable */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.input button:hover {
  background-color: var(--chair-orange);
}
