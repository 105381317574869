.content {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  position: relative;

  .heading {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--chair-orange);
  }

  .description {
    font-size: 1em;
    color: #666;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }

  .textArea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 1rem;
    resize: vertical;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
}
