/* packages/client/src/components/InputWrapper/InputWrapper.module.css */
.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.inputWrapper input {
  flex-grow: 1;
  /* Other input styles */
}

.inputWrapper input:focus {
  outline: none;
  border-color: #4caf50; /* Highlight color when textarea is focused */
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.25); /* Optional: Add a subtle shadow on focus */
}

.inputWrapper.error input:focus {
  border-color: orange; /* Highlight color when textarea is focused */
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.25);
}

.icon {
  position: absolute;
  color: #4caf50;
  right: 10px; /* Adjust as needed */
}

.error .icon {
  color: orange;
}

.helperText {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  color: #4caf50;
  margin: 5px 0;
}

.error.helperText {
  color: orange;
}
