.errorMessage {
  display: flex;
  align-items: center;
  background-color: #fff0f0;
  border: 1px solid #ffcccb;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.errorIcon {
  font-size: 2rem;
  color: #ff4d4f;
  margin-right: 1rem;
}

.errorContent {
  flex: 1;
}

.errorTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff4d4f;
  margin: 0 0 0.5rem 0;
}

.errorText {
  font-size: 1rem;
  color: #333;
  margin: 0;
}
