.body {
  color: #444;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
}

.formContainer.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.formField {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formFieldLabel {
  font-weight: bold;
  color: #444;
}

.formField select,
.formField textarea {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background-color: white;
  transition: all 0.2s ease;
  color: var(--text-primary);
  width: 100%;
}

.formField select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1.25rem;
  padding-right: 2.5rem;
}

.formField select option {
  padding: 0.75rem;
  font-size: 16px;
  color: var(--text-primary);
}

.formField select option:first-child {
  color: var(--text-secondary);
}

.formField select:hover:not(:disabled),
.formField textarea:hover:not(:disabled) {
  border-color: var(--chair-orange);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.formField select:focus,
.formField textarea:focus {
  outline: none;
  border-color: var(--chair-orange);
  box-shadow: 0 0 0 2px rgba(var(--chair-orange-rgb), 0.1);
}

.formField select:disabled,
.formField textarea:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  opacity: 0.7;
  border-color: #ddd;
}

.formField textarea {
  min-height: 120px;
  resize: vertical;
  font-family: inherit;
  line-height: 1.4;
}

.formFieldLabel {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 0.95rem;
  margin-bottom: 0.25rem;
}

.required {
  color: var(--error);
  font-size: 0.85rem;
  font-weight: normal;
  margin-left: 0.25rem;
}

.description {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.4;
  background-color: rgba(var(--chair-orange-rgb), 0.05);
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(var(--chair-orange-rgb), 0.1);
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  border-radius: 10px;
}

.formField.error select,
.formField.error textarea {
  border-color: var(--error);
  background-color: rgba(var(--error-rgb), 0.02);
}

.formField.error .formFieldLabel {
  color: var(--error);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.formField {
  animation: fadeIn 0.2s ease-out;
}

@media (max-width: 640px) {
  .formField select,
  .formField textarea {
    font-size: 16px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.dialog {
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  animation: dialogEnter 0.2s ease-out;
  z-index: 1001;
  position: relative;
}

@keyframes dialogEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--chair-orange);
}

.closeButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #333;
}

.content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.successView,
.errorView {
  padding: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.successHeader,
.errorHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.successHeader h3,
.errorHeader h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--success);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: 0 2px 8px rgba(var(--success-rgb), 0.25);
}

.errorIcon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--error);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: 0 2px 8px rgba(var(--error-rgb), 0.25);
}

.successMessage,
.errorMessage {
  color: var(--text-secondary);
  line-height: 1.6;
  max-width: 400px;
  margin: 0 auto;
  font-size: 1rem;
}

.extraInfo {
  display: block;
  margin-top: 1rem;
  font-weight: 500;
  color: var(--text-primary);
  background: rgba(var(--success-rgb), 0.1);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.95rem;
}

.actionButton {
  min-width: 120px;
  margin-top: 0.5rem;
}

.blockButton {
  width: 100%;
  margin: 0.5rem 0;
  background-color: var(--error-light);
  color: var(--error);
  border: 1px solid var(--error);
  transition: all 0.2s ease;
}

.blockButton:hover {
  background-color: var(--error);
  color: white;
}

/* Animation for view transitions */
.successView,
.errorView {
  animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .successView,
  .errorView {
    padding: 2rem 1.5rem;
  }

  .successHeader h3,
  .errorHeader h3 {
    font-size: 1.25rem;
  }

  .checkmark,
  .errorIcon {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }
}
