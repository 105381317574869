.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialogContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

@media (max-width: 575px) {
  .dialogContent {
    max-width: 90vw;
  }
}

.dialogContent > * {
  width: 100%;
}

.dialogContent p {
  color: black;
}

.dialogContent .btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.dialogContent .btnContainer .close-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: filter 0.1s ease;
}

.dialogContent .btnContainer .close-btn:hover {
  filter: brightness(90%);
}
