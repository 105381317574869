.fab {
  position: fixed;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--chair-orange);
  box-shadow: var(--basic-boxshadow);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--overlay-z-index);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Position variants */
.bottom-left {
  bottom: 20px;
  left: 20px;
}

.bottom-right {
  bottom: 20px;
  right: 20px;
}

.bottom-center {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.top-left {
  top: 20px;
  left: 20px;
}

.top-right {
  top: 20px;
  right: 20px;
}

.top-center {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Hover states need to account for transform in centered positions */
.bottom-center:hover,
.top-center:hover {
  transform: translateX(-50%) scale(1.1);
}

/* Regular hover for corner positions */
.fab:not(.bottom-center):not(.top-center):hover {
  transform: scale(1.1);
  box-shadow: var(--hover-boxshadow);
}

.fab:active {
  transform: scale(0.95);
  transition: all 0.1s ease;
}

/* Special active states for centered positions */
.bottom-center:active,
.top-center:active {
  transform: translateX(-50%) scale(0.95);
}

.fab.completed {
  background-color: rgba(255, 126, 0, 0.7);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 10px rgba(255, 126, 0, 0.3);
}

.fab.completed:hover {
  background-color: rgba(255, 126, 0, 0.85);
  box-shadow: 0 4px 15px rgba(255, 126, 0, 0.4);
}

/* Special hover for centered completed buttons */
.bottom-center.completed:hover,
.top-center.completed:hover {
  transform: translateX(-50%) scale(1.1) rotate(10deg);
}

/* Regular hover for corner completed buttons */
.fab.completed:not(.bottom-center):not(.top-center):hover {
  transform: scale(1.1) rotate(10deg);
}

.fab.completed:active {
  transform: scale(0.95);
  box-shadow: 0 2px 8px rgba(255, 126, 0, 0.3);
}

/* Special active states for centered completed positions */
.bottom-center.completed:active,
.top-center.completed:active {
  transform: translateX(-50%) scale(0.95);
}

.icon {
  font-size: 28px;
  transition: all 0.3s ease;
}

.completed .icon {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .fab {
    width: 48px;
    height: 48px;
  }

  .icon {
    font-size: 24px;
  }

  /* Adjust positions for mobile */
  .bottom-left,
  .top-left {
    left: 15px;
  }

  .bottom-right,
  .top-right {
    right: 15px;
  }

  .bottom-left,
  .bottom-right,
  .bottom-center {
    bottom: 15px;
  }

  .top-left,
  .top-right,
  .top-center {
    top: 15px;
  }
}
