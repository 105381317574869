.connectionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  position: relative;
  margin: 3rem 0;
}

.personLeft,
.personRight {
  width: 60px;
  height: 60px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personSvg {
  filter: drop-shadow(0 0 8px rgba(254, 208, 48, 0.3));
}

.connectionCanvas {
  flex: 1;
  height: 100%;
  max-width: 300px;
  z-index: 1;
}

@media (max-width: 768px) {
  .connectionContainer {
    height: 100px;
  }

  .personLeft,
  .personRight {
    width: 50px;
    height: 50px;
  }

  .personSvg {
    width: 50px;
    height: 50px;
  }

  .connectionCanvas {
    max-width: 200px;
  }
}
