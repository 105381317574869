.badge {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin: 8px;
  border-radius: 12px;
  background-color: rgba(60, 50, 45, 0.9);
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.badgeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.badgeIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.badgeIcon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.badgeEmoji {
  font-size: 36px;
  line-height: 1;
}

.badgeName {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Size variations */
.small {
  width: 100px;
  height: 100px;
}

.small .badgeEmoji {
  font-size: 28px;
}

.small .badgeName {
  font-size: 12px;
}

.medium {
  width: 120px;
  height: 120px;
}

.large {
  width: 150px;
  height: 150px;
}

.large .badgeEmoji {
  font-size: 42px;
}

.large .badgeName {
  font-size: 16px;
}

.earned {
  background-color: rgba(60, 50, 45, 0.9);
}

.locked {
  opacity: 0.6;
  filter: grayscale(70%);
}

.lockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.lockedMessage {
  color: #aaa;
  font-style: italic;
  margin-top: 4px;
  font-size: 0.85em;
}

/* Hover effects */
.badge:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

/* Badge grid layout */
.badgeGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin-top: 16px;

  /* For mobile, make it horizontally scrollable */
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 12px;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scroll-behavior: smooth;
  }
}

/* Badge section styling */
.badgeSection {
  margin: 24px 0;
  padding: 16px;
  background-color: rgba(50, 40, 35, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.badgeSection h3 {
  margin-bottom: 16px;
  font-size: 1.2rem;
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Badge category accordion */
.badgeCategory {
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.badgeCategory h4 {
  margin-bottom: 12px;
  font-size: 1rem;
  color: #555;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.badgeCategory h4:hover {
  color: #333;
}

.badgeCategory h4::after {
  content: "▼";
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.badgeCategory.collapsed h4::after {
  transform: rotate(-90deg);
}

.badgeCategory.collapsed .badgeGrid {
  display: none;
}

/* Badge count indicator */
.badgeCount {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 0.8rem;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 8px;
}

/* Scroll indicators for mobile */
.scrollIndicator {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background-color: rgba(60, 50, 45, 0.8);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.scrollLeft {
  left: 8px;
}

.scrollRight {
  right: 8px;
}

@media (max-width: 768px) {
  .badgeSection {
    position: relative;
    padding: 16px 8px;
  }

  .scrollIndicator {
    display: flex;
  }

  .badge {
    flex-shrink: 0;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .badge {
    width: 100px;
    height: 100px;
  }

  .badgeEmoji {
    font-size: 28px;
  }

  .badgeName {
    font-size: 12px;
  }
}

.tooltipContent {
  padding: 12px;
  max-width: 250px;
}

.tooltipContent strong {
  display: block;
  margin-bottom: 6px;
  font-size: 1rem;
  color: #333;
}

.tooltipContent p {
  margin: 6px 0;
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
}

/* Mobile badge section */
.mobileBadgeSection {
  padding: 12px;
}

/* Badge tabs for mobile */
.badgeTabs {
  display: flex;
  overflow-x: auto;
  margin-bottom: 16px;
  padding-bottom: 8px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
}

.badgeTabs::-webkit-scrollbar {
  display: none;
}

.badgeTab {
  padding: 6px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  margin-right: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.badgeTab .badgeCount {
  margin-left: 6px;
  font-size: 0.75rem;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 6px;
  border-radius: 10px;
}

.activeBadgeTab {
  background-color: rgba(255, 255, 255, 0.25);
  color: white;
}

/* Badge grid container */
.badgeGridContainer {
  position: relative;
  overflow: hidden;
  padding: 0 24px;
}

/* Loading and error states */
.loadingBadges,
.badgeError,
.noBadges {
  padding: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
}

.badgeError {
  color: #ff6b6b;
}
