.wrapper {
  min-height: 100%;
  padding: 2rem 1rem;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.mainTitle {
  color: var(--chair-orange);
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.lastUpdated {
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  font-style: italic;
}

.content {
  color: #333;
  line-height: 1.8;
}

.content h2 {
  color: var(--chair-orange);
  font-size: 1.75rem;
  font-weight: 600;
  margin: 2.5rem 0 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid rgba(236, 125, 49, 0.1);
}

.content h3 {
  color: #444;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
}

.content p {
  margin-bottom: 1.2rem;
}

.content ul,
.content ol {
  margin: 1rem 0 1.5rem 1.5rem;
}

.content li {
  margin-bottom: 0.8rem;
  position: relative;
}

.content a {
  color: var(--chair-orange);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s ease;
}

.content a:hover {
  border-bottom-color: var(--chair-orange);
}

.content blockquote {
  margin: 1.5rem 0;
  padding: 1rem 1.5rem;
  background-color: rgba(236, 125, 49, 0.05);
  border-left: 4px solid var(--chair-orange);
  border-radius: 0 8px 8px 0;
}

.content hr {
  margin: 2.5rem 0;
  border: none;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(236, 125, 49, 0.3),
    transparent
  );
}

.loading {
  text-align: center;
  color: #666;
  padding: 2rem;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .wrapper {
    padding: 1rem;
  }

  .container {
    padding: 1.5rem;
  }

  .mainTitle {
    font-size: 2rem;
  }

  .content h2 {
    font-size: 1.5rem;
  }

  .content h3 {
    font-size: 1.25rem;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .wrapper {
    background-color: #1a1a1a;
  }

  .container {
    background-color: #242424;
  }

  .content {
    color: #e0e0e0;
  }

  .lastUpdated {
    color: #999;
  }

  .content h3 {
    color: #e0e0e0;
  }

  .content blockquote {
    background-color: rgba(236, 125, 49, 0.1);
  }
}
