/* UserManagement.module.css */

.userManagementContainer {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.tableWrapper {
  flex: 1;
  overflow: auto;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: white;
}

.usersTable {
  width: 100%;
  border-collapse: collapse;
}

.usersTable th,
.usersTable td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.usersTable th {
  background-color: #f9f9f9;
  font-weight: 600;
  color: #555;
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
  padding: 12px 16px;
}

.sortableHeader {
  cursor: pointer;
  position: relative;
  padding-right: 24px !important;
}

.sortIcon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.userRow {
  background-color: #f9f9f9;
  cursor: pointer;
}

.userRow:hover {
  background-color: #f0f0f0;
}

.userDataCell {
  cursor: pointer;
}

.userDataCell:hover {
  color: var(--dark-brown);
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.adminActions {
  background-color: #f4f4f4;
  padding: 10px;
  position: absolute;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.adminActions button {
  padding: 8px;
  text-align: left;
  width: 100%;
}

.adminActions button:hover {
  text-decoration: underline;
  color: #ed7d31;
}

.actionReason input {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.tooltipContainer {
  position: relative;
  cursor: help;
}

.tooltip {
  display: none;
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 5px;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

.tooltipContainer:hover .tooltip {
  display: block;
}

.tooltip div {
  margin: 5px 0;
}

.tooltip strong {
  text-transform: capitalize;
}

/* Add these styles to match ReportManagement header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: var(--dark-brown);
}

.stats {
  display: flex;
  gap: 16px;
}

.statItem {
  background-color: #f9f9f9;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  min-width: 100px;
}

.statValue {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--dark-brown);
}

.statLabel {
  font-size: 0.85rem;
  color: #666;
}

.filters {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.searchForm {
  width: 100%;
}

.searchInput {
  display: flex;
  width: 100%;
  position: relative;
}

.searchInput input {
  flex: 1;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.searchInput button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.filterControls {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}

.filterGroup {
  display: flex;
  align-items: center;
}

.filterGroup label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #555;
}

.filterGroup select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  min-width: 150px;
}

.clearButton {
  margin-left: auto;
}

/* Add these styles for the user detail view */

.userDetailContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.userDetailHeader {
  background-color: white;
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: var(--dark-brown);
  font-weight: 500;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.backButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.userDetailContent {
  flex: 1;
  overflow: auto;
  padding: 24px;
}

.userDetailProfile {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.userAvatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.userInfo {
  flex: 1;
}

.userInfo h2 {
  margin: 0 0 4px 0;
  font-size: 1.5rem;
  color: var(--dark-brown);
}

.userInfo p {
  margin: 0 0 12px 0;
  color: #666;
}

.userMeta {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}

.userRole {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  color: #666;
}

.userJoined {
  font-size: 0.85rem;
  color: #666;
}

.userDetailTabs {
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  background-color: white;
  border-radius: 8px 8px 0 0;
  padding: 0 16px;
}

.userDetailTabs button {
  padding: 12px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.userDetailTabs button:hover {
  color: var(--dark-brown);
}

.userDetailTabs button.activeTab {
  color: var(--dark-brown);
}

.userDetailTabs button.activeTab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--dark-brown);
}

.userDetailTabContent {
  background-color: white;
  border-radius: 0 0 8px 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.userDetailSection {
  margin-bottom: 24px;
}

.userDetailSection h3 {
  margin: 0 0 16px 0;
  font-size: 1.1rem;
  color: var(--dark-brown);
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.detailGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detailLabel {
  font-size: 0.85rem;
  color: #666;
}

.detailValue {
  font-weight: 500;
}

.activitySummary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.activityItem {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
}

.activityCount {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  color: var(--dark-brown);
  margin-bottom: 4px;
}

.activityLabel {
  font-size: 0.9rem;
  color: #666;
}

.emptyState {
  background-color: #f9f9f9;
  padding: 24px;
  text-align: center;
  border-radius: 8px;
  color: #666;
}

/* Add these styles for error handling */

.errorState {
  background-color: #fff8f8;
  border: 1px solid #ffdddd;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  color: #d32f2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.errorState h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #d32f2f;
}

.errorState p {
  margin: 0;
  color: #666;
}

.errorState button {
  margin-top: 8px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.errorState button:hover {
  background-color: #e0e0e0;
}

/* Add these styles for subscription status */
.status-premium {
  background-color: #8a2be2;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-free {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

/* Add these styles for subscription badges */
.subscription-premium {
  background-color: #8a2be2;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.subscription-free {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.tooltipList {
  margin: 8px 0 0 0;
  padding-left: 16px;
  text-align: left;
}

.tooltipList li {
  margin-bottom: 4px;
}

/* Add loading and error states to match ReportManagement */
.loadingContainer,
.errorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
}

.errorContainer {
  gap: 16px;
}

/* Add pagination styles to match ReportManagement */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

.noResults {
  text-align: center;
  padding: 32px;
  color: #666;
}

/* Update action cell styling */
.actionCell {
  width: 80px;
  text-align: center;
}

/* Update modal styles to match ReportManagement */
.modalContent {
  margin-top: 16px;
}

.formGroup {
  margin-bottom: 16px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.formGroup select,
.formGroup textarea,
.formGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.formGroup textarea {
  min-height: 100px;
  resize: vertical;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}
