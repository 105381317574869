.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--navbar-height));
  padding: 2rem;
  background: var(--surface-secondary);
}

.container {
  max-width: 600px;
  width: 100%;
  padding: 2.5rem;
  background: var(--surface-primary);
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 12px rgba(var(--navbar-brown), 0.1);
}

.header {
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
}

.title {
  font-size: 2.25rem;
  color: var(--text-primary);
  font-weight: 600;
}

.content {
  width: 100%;
}

.warningSection {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.25rem;
  background: var(--surface-warning);
  border: 1px solid var(--border-warning);
  border-radius: 12px;
  margin-bottom: 2rem;
  text-align: left;
}

.warningIcon {
  font-size: 1.5rem;
  color: var(--text-warning);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.warningTitle {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.warningText {
  color: var(--text-secondary);
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.infoItem {
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--surface-secondary);
  border: 1px solid var(--border-default);
  border-radius: 12px;
  text-align: left;
}

.infoIcon {
  font-size: 1.5rem;
  color: var(--text-secondary);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.infoItem h3 {
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  color: var(--text-primary);
  font-weight: 600;
}

.infoDate {
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.reason {
  margin: 0.5rem 0;
  color: var(--text-secondary);
}

.type {
  font-size: 0.9rem;
  color: var(--text-tertiary);
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.typeIcon {
  font-size: 1rem;
}

.deadline {
  color: var(--chair-orange);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.note {
  font-size: 0.9rem;
  color: var(--text-tertiary);
  font-style: italic;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2.5rem;
}

.reactivateButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--chair-orange);
  color: var(--text-inverse);
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.reactivateButton:hover {
  background-color: var(--chair-orange-dark);
  transform: translateY(-1px);
}

.cancelButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  padding: 0.75rem 1.5rem;
  transition: all 0.2s ease;
}

.cancelButton:hover {
  color: white;
}

.loadingContainer {
  color: white;
  text-align: center;
  padding: 2rem;
}
