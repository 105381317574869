.notificationContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.notificationButton {
  /* position: relative; */
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transition: background-color 0.2s, transform 0.2s;
}

.notificationButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.bellIcon {
  font-size: 1.25rem;
  color: white;
}

.badge {
  /* position: absolute; */
  top: 0;
  right: 0;
  background-color: var(--error);
  color: white;
  border-radius: 50%;
  min-width: 18px;
  height: 18px;
  padding: 0 4px;
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--navbar-brown);
}

.dropdown {
  background-color: rgba(var(--navbar-brown), 1);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000;
}

.dropdownHeader {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownHeader h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: white;
}

.unreadCount {
  font-size: 0.875rem;
  color: white;
  background-color: rgba(var(--error-rgb), 0.3);
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: 500;
}

.scrollableContent {
  flex-grow: 1;
  overflow-y: auto;
  padding: 8px;
  min-height: 100px;
  max-height: 400px;
}

.scrollableContent::-webkit-scrollbar {
  width: 6px;
}

.scrollableContent::-webkit-scrollbar-track {
  background: transparent;
}

.scrollableContent::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.notificationItemWrapper {
  margin-bottom: 8px;
  border-radius: 12px;
  transition: transform 0.2s, background-color 0.2s;
}

.notificationItemWrapper:hover {
  transform: translateX(4px);
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdownFooter {
  padding: 12px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.viewAll {
  display: block;
  text-align: center;
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.viewAll:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Mobile styles */
@media (max-width: 575px) {
  .dropdown {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100%;
    height: 100vh;
    max-height: none;
    max-width: none;
    margin: 0;
    border-radius: 0;
    border: none;
    display: flex;
    flex-direction: column;
    background-color: rgba(var(--navbar-brown), 1);
    color: white;
    z-index: var(--fullscreen-mobile-z-index);
  }

  .dropdownHeader {
    position: sticky;
    top: 0;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: rgba(var(--navbar-brown), 1);
    z-index: calc(var(--fullscreen-mobile-z-index) + 1);
  }

  .dropdownHeader h3 {
    color: white;
  }

  .headerContent {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .closeButton {
    background: none;
    border: none;
    color: white;
    padding: 0.5rem;
    margin-left: auto;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
  }

  .closeButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .scrollableContent {
    max-height: none;
    flex-grow: 1;
  }
}
