/* packages/client/src/components/DatePickerInput/DatePickerInput.module.css */

.wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
}

.wrapper > div {
  width: 100%;
}

/* Base input styling */
.input {
  width: 100%;
  padding: 14px 40px 14px 16px;
  height: 54px;
  border: 2px solid;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.input:focus {
  outline: none;
}

/* Calendar icon positioning */
.wrapper :global(.react-datepicker__calendar-icon) {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

/* Dark theme (default) */
.dark .input {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.dark .input:focus {
  border-color: var(--chair-orange);
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .input:hover:not(:focus) {
  background-color: rgba(255, 255, 255, 0.12);
}

.dark :global(.react-datepicker__calendar-icon) {
  color: rgba(255, 255, 255, 0.6);
}

/* Light theme */
.light .input {
  border-color: rgba(0, 0, 0, 0.2);
  background-color: white;
  color: #333;
}

.light .input:focus {
  border-color: var(--chair-orange);
  background-color: white;
  box-shadow: 0 0 0 2px rgba(255, 107, 53, 0.1);
}

.light .input:hover:not(:focus) {
  border-color: rgba(0, 0, 0, 0.3);
}

.light :global(.react-datepicker__calendar-icon) {
  color: rgba(0, 0, 0, 0.4);
}

/* Transparent theme */
.transparent .input {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
}

.transparent .input:focus {
  border-color: var(--chair-orange);
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent .input:hover:not(:focus) {
  background-color: rgba(255, 255, 255, 0.08);
}

.transparent :global(.react-datepicker__calendar-icon) {
  color: rgba(255, 255, 255, 0.6);
}

/* Rest of the existing styles */
:global(.react-datepicker__header__dropdown) {
  margin-top: 0.5rem;
}

/* Dropdown styling */
:global(.react-datepicker__month-dropdown),
:global(.react-datepicker__year-dropdown) {
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

:global(.react-datepicker__month-dropdown-container),
:global(.react-datepicker__year-dropdown-container) {
  margin: 0 4px;
}

:global(.react-datepicker__month-option),
:global(.react-datepicker__year-option) {
  padding: 8px 12px;
  transition: background-color 0.2s;
}

:global(.react-datepicker__month-option:hover),
:global(.react-datepicker__year-option:hover) {
  background-color: #f3f4f6;
}

/* Calendar styling */
:global(.react-datepicker) {
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  font-family: inherit;
}

:global(.react-datepicker__header) {
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  padding: 12px 0;
}

:global(.react-datepicker__day) {
  border-radius: 4px;
  transition: background-color 0.2s;
}

:global(.react-datepicker__day:hover) {
  background-color: #f3f4f6;
}

:global(.react-datepicker__day--selected) {
  background-color: var(--chair-orange) !important;
  color: white;
}

/* Improve focus states for keyboard navigation */
:global(.react-datepicker__day--keyboard-selected) {
  background-color: rgba(255, 126, 0, 0.7) !important;
  color: white;
}

/* Add transitions for smoother interactions */
:global(.react-datepicker__month-select),
:global(.react-datepicker__year-select) {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  transition: all 0.2s;
}

/* Add mobile-friendly touch targets */
@media (max-width: 768px) {
  :global(.react-datepicker__day) {
    margin: 0.2rem;
    padding: 0.5rem;
    line-height: 1;
  }

  :global(.react-datepicker__month-select),
  :global(.react-datepicker__year-select) {
    padding: 8px 12px;
  }
}
