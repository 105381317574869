.wrapper {
  width: 100vw;
  height: var(--talk-ticker-height);
  background-color: var(--dark-brown);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: var(--ticker-z-index);
  display: grid;
  grid-template-columns: auto 1fr;
}

.serverStatus {
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  margin: 0 1rem;
  gap: 0.25rem;
}

.statusLight {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: var(--error); /* Default to red */
}

.statusLight.online {
  background-color: var(--green);
}

.tape {
  display: flex;
  align-items: stretch;
  gap: 3em;
  animation: ticker 30s linear infinite;
  height: 100%;
  white-space: nowrap; /* Prevent content from wrapping */
  overflow: visible; /* Hide the overflow content */
}

.tape p {
  color: white;
  display: flex;
  align-items: center;
}

.activeUsers {
  animation: highlightChange 1s ease;
}

.topicString {
  animation: topicStringAnimation 1s;
  overflow: visible;
}

@keyframes topicStringAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes highlightChange {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    color: var(--chair-orange);
  }
  100% {
    transform: scale(1);
  }
}

.highlight {
  animation: highlightChange 1s ease;
}

@media screen and (max-width: 768px) {
  .wrapper {
    top: var(--navbar-height);
  }
}
