.reachOutButton {
  border: none;
  border-radius: 12px; /* More border radius */
  padding: 0.375rem 0.75rem;
  font-size: 16px; /* Set font size to 16px */
  color: white; /* White text for contrast */
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Spacing between icon and text */

  /* Default state (Not Reached Out) */
  background-color: var(--cool-blue); /* Use your defined cool blue */

  min-height: 40px;
  font-size: 16px;

  &:hover {
    background-color: #3a8ccf; /* Slightly darker shade for hover */
    border-color: #3a8ccf; /* Match border color on hover */
  }
}

.reachedOut {
  background-color: #6c757d; /* Grey color for the button when reached out */
  border-color: #5a6268; /* Darker grey for the border */

  &:hover {
    background-color: #5a6268; /* Darker grey on hover */
    border-color: #4e555b; /* Even darker border on hover */
  }
}

.icon {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem; /* Ensure consistent spacing */
}

.reachOutIcon {
  width: 1.5em; /* Adjust the size as needed */
  height: 1.5em; /* Ensure it scales proportionally */
  fill: white;
}
