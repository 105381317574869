.publicSearch {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 3rem 2rem;
  background: rgba(0, 0, 0, 0.2);

  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.searchContainer h3 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.searchInputGroup {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.searchInput {
  flex: 1;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  transition: all 0.3s ease;
  width: 100%;
}

.searchInput:focus {
  outline: none;
  border-color: var(--chair-orange);
  background: rgba(255, 255, 255, 0.08);
}

.searchInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.searchLoading {
  margin: 2rem auto;
  color: white;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  text-align: center;
}

.noResults {
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  max-width: 800px;
}

.noResults p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.noResultsSuggestion {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  margin-bottom: 2rem;
}

.searchResults {
  margin-top: 2rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.searchResults h4 {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.resultsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 1rem;
}

/* Simpler approach for item separation */
.resultsList > div:not(:last-child) {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.resultsList::-webkit-scrollbar {
  width: 8px;
}

.resultsList::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.resultsList::-webkit-scrollbar-thumb {
  background: var(--chair-orange);
  border-radius: 4px;
}

.primaryButton {
  padding: 1rem 2rem;
  background-color: var(--chair-orange);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primaryButton:hover {
  background-color: rgba(var(--chair-orange-rgb), 0.8);
}

@media (max-width: 768px) {
  .publicSearch {
    padding: 2rem 1rem;
  }

  .searchInputGroup {
    flex-direction: column;
    gap: 0.5rem;
  }

  .searchResults {
    padding: 1rem;
  }
}
