.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: black;
}

.helper {
  font-size: 0.9rem;
  color: #666;
  margin-top: 8px;
}

.helper a {
  color: var(--chair-orange);
  text-decoration: none;
}

.helper a:hover {
  text-decoration: underline;
}

.tag-editor {
  margin-top: 12px;
  margin-bottom: 12px;
}

.actionMenu {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 4px;
  animation: shake 0.4s ease-in-out;
}

.errorInput {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.1) !important;
  animation: shake 0.4s ease-in-out;
}

.story-editor.error {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.25) !important;
  animation: shake 0.4s ease-in-out;
}

/* Error animation */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}

/* Error tooltip */
.errorTooltip,
.errorTooltip::before {
  display: none;
}

/* Section with error */
.section.hasError {
  padding: 16px;
  border-radius: 8px;
  background: rgba(220, 53, 69, 0.05);
  border: 1px solid rgba(220, 53, 69, 0.1);
  margin: 8px 0;
  animation: errorPulse 2s ease-in-out;
}

/* Helper text for error state */
.errorHelper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 8px;
  padding: 8px;
  border-radius: 4px;
  background: rgba(220, 53, 69, 0.05);
}

.errorHelper svg {
  flex-shrink: 0;
}

/* Improve focus states for accessibility */
.story-editor:focus-within.errorInput,
.tagInput:focus-within.errorInput,
.dateSelector:focus-within.errorInput {
  border-color: #dc3545;
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.25);
  outline: none;
}

.heading {
  color: var(--chair-orange);
  font-weight: 600;
  margin: 10px 0;
  display: block;
}

.heading span {
  color: var(--chair-orange);
  font-weight: 600;
}

.loading {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0;
  color: #555;
  font-size: 1rem;
}

.toggler {
  display: flex;
  flex-wrap: wrap;
}

.intentSection {
  margin: 20px 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.headingWithTooltip {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.helper {
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
  margin-bottom: 16px;
  line-height: 1.4;
}

.intentGrid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
}

.intentOption {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  cursor: pointer;
}

.intentOption:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.intentCheckbox {
  display: flex;
  align-items: center;
  gap: 12px;
}

.intentOption input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: #fff;
  border: 2px solid var(--chair-orange);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.intentOption input:checked ~ .checkmark {
  background-color: var(--chair-orange);
  border-color: var(--chair-orange);
}

.intentOption input:checked ~ .checkmark:after {
  display: block;
}

.intentLabel {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.intentDescription {
  margin: 0;
  padding-left: 32px;
  font-size: 0.875rem;
  color: #666;
  line-height: 1.4;
}

/* Disabled state */
.intentOption input:disabled ~ .checkmark {
  background-color: #e9ecef;
  border-color: #adb5bd;
  cursor: not-allowed;
}

.intentOption input:disabled ~ .intentLabel {
  color: #868e96;
}

.intentOption input:disabled ~ .intentDescription {
  color: #adb5bd;
}

/* Focus state */
.intentOption input:focus-visible ~ .checkmark {
  outline: 2px solid var(--chair-orange);
  outline-offset: 2px;
}

@media (max-width: 480px) {
  .intentOption {
    padding: 12px;
  }

  .intentDescription {
    padding-left: 32px;
    font-size: 0.8125rem;
  }
}

.tagSection {
  margin-top: 24px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.toggleSection {
  margin-top: 24px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.toggleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  gap: 1.5rem;

  @media (max-width: 430px) {
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
}

.toggleLabel {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
  margin-right: auto;
}

.toggleHint {
  margin: 0;
  font-size: 0.875rem;
  color: #666;
}

.toggleRow :global(.InfoTooltip) {
  margin: 0 12px;
}

.dateSelector {
  margin-top: 12px;
}

/* Add new tag input styles */
.tagContainer {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px 12px;
  min-height: 45px;
  display: flex;
  align-items: center;
  cursor: text;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  margin: 16px 0;
  transition: all 0.2s ease;
}

.tagContainer.focused {
  border-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.selectedList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
  align-items: center;
  padding: 4px 0;
}

.tagBadge {
  display: inline-flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.95em;
  gap: 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  transition: all 0.2s ease;
}

/* ... copy the rest of the tag-related styles from StoryForm.module.css ... */

/* Animation for error sections */
@keyframes errorPulse {
  0% {
    background: rgba(220, 53, 69, 0.1);
  }
  50% {
    background: rgba(220, 53, 69, 0.05);
  }
  100% {
    background: rgba(220, 53, 69, 0.1);
  }
}
